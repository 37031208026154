import React, {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import Flex from '../../../lib/components/Flex';
import { AgendaContext } from '../Agenda.context';
import MenuTabs from '../../../lib/components/MenuTabs';
import ExtraTheoreticalFlashcard from './_components/ExtraTheoreticalFlashcard';
import ExtraTheoreticalMain from './_components/ExtraTheoreticalMain';
import ExtraTheoreticalMindmap from './_components/ExtraTheoreticalMindmap';
import ExtraTheoreticalText from './_components/ExtraTheoreticalText';
import ExtraTheoreticalVideo from './_components/ExtraTheoreticalVideo';
import { ActivityTheoreticalExtra } from '../../../api/extra-activities/extra-activities';
import { useExtraTheoreticalStudy } from '../../../api/extra-activities/extra-theoretical-study';
import { ExtraHeader } from './_components/ExtraHeader';
import { FinishLessonButton } from '../_components/FinishLessonButton';
import ExtraTheoreticalQuestion from './_components/ExtraTheoreticalQuestion';
import { forumWrapper } from '../theoretical-study/AgendaTheoreticalStudy';

const ExtraTheoreticalStudyContainer: FC = () => {
  const { extras, extrasLoading } = useContext(AgendaContext);

  const id = getActivityIndex();

  const activity: ActivityTheoreticalExtra = useMemo(() => {
    const qs = extras.activityTheoreticalExtra;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index];
    }

    if (qs && qs.length > 0) {
      return qs[0];
    }

    return {
      _id: '',
      classification: [],
      contents: {
        videos: [],
        texts: [],
        mindmaps: [],
        flashcards: [],
        questions: [],
      },
      lessonId: '',
      finished: false,
    };
  }, [extras.activityTheoreticalExtra, id]);

  if (extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Conteúdo teórico [Extra]"
        redirectLink="/lista-de-temas"
      />
      <ExtraTheoreticalStudy data={activity} />
    </Box>
  );
};

interface ExtraTheoreticalStudyProps {
  data: ActivityTheoreticalExtra;
}

const ExtraTheoreticalStudy: FC<ExtraTheoreticalStudyProps> = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const menuItems = useMemo(() => {
    return ({
      questao: Boolean(data.contents.questions && data.contents.questions.length > 0),
      video: Boolean(data.contents.videos && data.contents.videos.length > 0),
      apostila: Boolean(data.contents.texts && data.contents.texts.length > 0),
      'mapa-mental': Boolean(data.contents.mindmaps && data.contents.mindmaps.length > 0),
      flashcard: Boolean(data.contents.flashcards && data.contents.flashcards.length > 0),
    });
  }, [data]);

  const { onFinishExtraTheoreticalStudyExtraActivity } = useExtraTheoreticalStudy();
  const { refetchExtras } = useContext(AgendaContext);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await onFinishExtraTheoreticalStudyExtraActivity({
        classification: data.classification,
        id: data._id,
      });
      await refetchExtras();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [data._id, data.classification, onFinishExtraTheoreticalStudyExtraActivity, refetchExtras]);

  return (
    <>
      <Flex align="center" justify="space-between">
        <Text>{data.classification[1]}</Text>
        <FinishLessonButton
          unfinishedText="Concluir tema"
          finishedText="Tema concluído"
          isFinished={data.finished}
          modalTitle="Concluir tema"
          modalSubtitle="Deseja concluir esse tema? Essa ação é irreversível,
          mas você ainda poderá adicioná-lo como atividade extra quando quiser."
          onConfirm={handleFinish}
        />
      </Flex>
      <MenuTabs
        menuItems={menuItems}
        path="/atividades-extras/estudo-teorico"
      />
      <Switch>
        <Route
          exact
          path="/atividades-extras/estudo-teorico"
          render={() => <ExtraTheoreticalMain menuItems={menuItems} context="estudo-teorico" />}
        />
        <Route
          path="/atividades-extras/estudo-teorico/questao"
          render={() => (
            <ExtraTheoreticalQuestion
              id={data._id}
              ids={data.contents.questions}
            />
          )}
        />
        <Route path="/atividades-extras/estudo-teorico/video">
          <ExtraTheoreticalVideo
            ids={data.contents.videos}
            onFinish={handleFinish}
            loading={loading}
            finished={data.finished}
          />
        </Route>
        <Route path="/atividades-extras/estudo-teorico/apostila">
          {forumWrapper(
            <ExtraTheoreticalText
              ids={data.contents.texts}
              onFinish={handleFinish}
              loading={loading}
              finished={data.finished}
            />, data.lessonId, data.classification.join('|'),
          )}
        </Route>
        <Route path="/atividades-extras/estudo-teorico/mapa-mental">
          {forumWrapper(
            <ExtraTheoreticalMindmap
              ids={data.contents.mindmaps}
              onFinish={handleFinish}
              loading={loading}
              finished={data.finished}
            />, data.lessonId, data.classification.join('|'),
          )}
        </Route>
        <Route path="/atividades-extras/estudo-teorico/flashcard">
          <ExtraTheoreticalFlashcard
            id={data._id}
            ids={data.contents.flashcards}
          />
        </Route>
      </Switch>
    </>
  );
};

export default ExtraTheoreticalStudyContainer;
