import { useColorModeValue, useTheme } from '@chakra-ui/react';
import { useCallback } from 'react';

export const colorPalette = 'aristo';

export const useDefaultStyles = () => {
  const homePage = '/agenda';

  const getLogo = useCallback((
    name = 'logo-loading',
    type = 'png',
  ) => {
    return `/custom/aristo/${name}.${type}`;
  }, []);

  return (
    {
      logo: getLogo,
      homePage,
      modal: {
        header: {
          bgColor: useColorModeValue(`${colorPalette}.900`, `${colorPalette}.800`),
          color: `${colorPalette}.200`,
        },
        body: {
          bgColor: useColorModeValue('background.light', 'background.dark'),
        },
        footer: {
          bgColor: useColorModeValue('background.light', 'background.dark'),
        },
      },
      colorPalette,
      cardBorder: {
        boxShadow: `2px 2px 2px 2px ${useColorModeValue('lightgray', 'transparent')}`,
        borderRadius: 12,
        borderWidth: '2px',
        borderColor: useColorModeValue('gray.400', `${colorPalette}.600`),
        transition: 'all 0.4s',
      },
      hueRotate: () => {
        const hueMap = {
          teal: -50,
          aristo: 0,
          purple: 60,
          pink: 90,
          red: 150,
          orange: 180,
          yellow: 200,
          green: 240,
          bbi: 190,
          cbi: 150,
        };
        if (colorPalette in hueMap) {
          return (`hue-rotate(${hueMap[colorPalette as keyof typeof hueMap]}deg)`);
        } return 'none';
      },
      colors: {
        primary: {
          keep: `${colorPalette}.900`,
          goALittleLighter: useColorModeValue(`${colorPalette}.900`, `${colorPalette}.800`),
          goLighter: useColorModeValue(`${colorPalette}.900`, `${colorPalette}.700`),
          goMoreLighter: useColorModeValue(`${colorPalette}.900`, `${colorPalette}.600`),
          goFullLighter: useColorModeValue(`${colorPalette}.900`, `${colorPalette}.50`),
        },
        secondary: {
          goDarker: useColorModeValue(`${colorPalette}.500`, `${colorPalette}.700`),
          goALittleDarker: useColorModeValue(`${colorPalette}.500`, `${colorPalette}.600`),
          keep: `${colorPalette}.500`,
          goLighter: useColorModeValue(`${colorPalette}.500`, `${colorPalette}.300`),
          goALittleLighter: useColorModeValue(`${colorPalette}.500`, `${colorPalette}.400`),
          goFullLighter: useColorModeValue(`${colorPalette}.500`, `${colorPalette}.50`),
        },
        intermediary: {
          keep: `${colorPalette}.700`,
          goLighter: useColorModeValue(`${colorPalette}.700`, `${colorPalette}.500`),
        },
        lightSecondary: {
          goAlmostFullDarker: useColorModeValue(`${colorPalette}.300`, `${colorPalette}.800`),
          goMuchDarker: useColorModeValue(`${colorPalette}.300`, `${colorPalette}.700`),
          goDarker: useColorModeValue(`${colorPalette}.300`, `${colorPalette}.600`),
          keep: `${colorPalette}.300`,
        },
        lighterSecondary: {
          goAlmostFullDarker: useColorModeValue(`${colorPalette}.200`, `${colorPalette}.800`),
          goMuchDarker: useColorModeValue(`${colorPalette}.200`, `${colorPalette}.700`),
          goDarker: useColorModeValue(`${colorPalette}.200`, `${colorPalette}.300`),
          keep: `${colorPalette}.200`,
          goLighter: useColorModeValue(`${colorPalette}.200`, `${colorPalette}.100`),
        },
        alpha: {
          50: useColorModeValue('blackAlpha.50', 'whiteAlpha.50'),
          100: useColorModeValue('blackAlpha.100', 'whiteAlpha.100'),
          200: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
          400: useColorModeValue('blackAlpha.400', 'whiteAlpha.400'),
        },
        lighter: {
          keep: `${colorPalette}.50`,
          goALittleDarker: useColorModeValue(`${colorPalette}.50`, `${colorPalette}.100`),
          goDarker: useColorModeValue(`${colorPalette}.50`, `${colorPalette}.200`),
          goMuchDarker: useColorModeValue(`${colorPalette}.50`, `${colorPalette}.700`),
          goAlmostFullDarker: useColorModeValue(`${colorPalette}.50`, `${colorPalette}.800`),
          goFullDarker: useColorModeValue(`${colorPalette}.50`, `${colorPalette}.900`),
          goBackgroundDark: useColorModeValue(`${colorPalette}.50`, 'background.dark'),
          goAlpha: {
            100: useColorModeValue(`${colorPalette}.50`, 'whiteAlpha.100'),
            200: useColorModeValue(`${colorPalette}.50`, 'whiteAlpha.200'),
          },
          fromWhite: useColorModeValue('white', `${colorPalette}.50`),
        },
        lightGray: {
          goAlmostFullDarker: useColorModeValue('gray.100', 'gray.800'),
          goDarker: useColorModeValue('gray.100', 'gray.200'),
          keep: 'gray.100',
          goLighter: useColorModeValue('gray.100', 'gray.50'),
        },
        darkGray: {
          goDarker: useColorModeValue('gray.400', 'gray.600'),
          keep: 'gray.400',
          goLighter: useColorModeValue('gray.400', 'gray.200'),
          goPallete700: useColorModeValue('gray.400', `${colorPalette}.700`),
          goPallete800: useColorModeValue('gray.400', `${colorPalette}.800`),
        },
        darkerGray: {
          keep: 'gray.600',
          goLighter: 'gray.400',
        },
        white: {
          goTransparent: useColorModeValue('white', 'transparent'),
          goPallete50: useColorModeValue('white', `${colorPalette}.50`),
          keep: 'white',
          goAlmostFullDarker: useColorModeValue('white', `${colorPalette}.800`),
          goGray: {
            500: useColorModeValue('white', 'gray.500'),
            600: useColorModeValue('white', 'gray.600'),
            700: useColorModeValue('white', 'gray.700'),
          },
          goInvert: useColorModeValue('white', 'black'),
        },
        black: {
          goInvert: useColorModeValue('black', 'white'),
        },
        green: {
          goDarker: useColorModeValue('green.400', 'green.600'),
          keep: 'green.400',
          goLighter: useColorModeValue('green.400', 'green.200'),
          goFullLighter: useColorModeValue('green.400', 'green.50'),
          lighter: useColorModeValue('green.50', 'green.800'),
        },
        red: {
          goDarker: useColorModeValue('red.400', 'red.600'),
          keep: 'red.400',
          goLighter: useColorModeValue('red.400', 'red.200'),
        },
        orange: {
          keep: 'orange.500',
        },
        yellow: {
          goDarker: useColorModeValue('yellow.400', 'yellow.600'),
          keep: 'yellow.400',
          goLighter: useColorModeValue('yellow.400', 'yellow.200'),
        },
        background: useColorModeValue('background.light', 'background.dark'),
        prizes: {
          gold: 'gold.500',
          silver: 'silver.600',
          bronze: 'bronze.500',
        },
        areas: {
          GO: 'pink',
          CM: 'aristo',
          CIR: 'purple',
          SC: 'yellow',
          PED: 'green',
          EXT: 'red',
        },
        deck: {
          shadow: useColorModeValue('blackAlpha.400', 'whiteAlpha.200'),
        },
      },
      selectBorder: {
        className: 'select-border',
        borderRadius: 20,
        mt: 3,
        py: 2,
        px: 2,
        ml: 'auto',
        align: 'center',
        borderWidth: '1px',
        borderColor: 'light.100',
        fontSize: 'sm',
        maxW: 250,
        transition: 'border 0.3s',
        _hover: {
          borderColor: 'dark.400',
        },
        color: 'dark.600',
      },
    }
  );
};

export function useRGBColor() {
  const theme = useTheme();
  const toRGB = (color?: string) => {
    if (color) {
      const splitted = color.split('.');
      if (splitted.length === 1) {
        return theme.colors[splitted[0]];
      }
      if (splitted.length === 2) {
        return theme.colors[splitted[0]][Number(splitted[1])];
      }
    }
    return '#00000000';
  };
  return { toRGB };
}

export function getComplementaryColor(color: string) {
  const colorPart = color.slice(1);
  const ind = parseInt(colorPart, 16);
  // eslint-disable-next-line no-bitwise
  let iter = ((1 << 4 * colorPart.length) - 1 - ind).toString(16);
  while (iter.length < colorPart.length) {
    iter = `0${iter}`;
  }
  return `#${iter}`;
}
