import {
  Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader,
  DrawerOverlay, Flex, HStack, Text, Textarea, VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ForumContext } from '../../../../contexts/Forum.context';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { useDevice } from '../../../../hooks/useDevice';
import { PrimaryButton } from '../../../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../../../lib/components/SecondaryButton';
import { SPLIT_MARKER } from '../Forum';
import { CommentDataType } from '../types/forum.types';
import { ComparativeCard } from './ComparativeMarkdownCard.component';

type EditCommentDrawerType = {
  answerTargetComment?: CommentDataType;
  commentBeingCreated: string;
  loadingSendComment: boolean;
  handleSendCommentClick: () => Promise<void>;
  setCommentBeingCreated: React.Dispatch<React.SetStateAction<string>>;
}

export const EditCommentDrawer: FC<EditCommentDrawerType> = ({
  answerTargetComment,
  commentBeingCreated,
  loadingSendComment,
  handleSendCommentClick,
  setCommentBeingCreated,
}: EditCommentDrawerType) => {
  const { colors } = useDefaultStyles();
  const device = useDevice();
  const { forumDisclosure } = useContext(ForumContext);
  const { isOpen, onClose } = forumDisclosure;
  const [isKnowMoreActive, setIsKnowMoreActive] = useState(false);
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={device === 'mobile' ? 'full' : 'md'}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={colors.background}>
        <DrawerHeader>
          <HStack justify="space-between">
            <Text>
              Editor de comentário
            </Text>
            <Button variant="unstyled" onClick={onClose}>
              <FontAwesomeIcon icon="times" />
            </Button>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          {
            isKnowMoreActive ? (
              <VStack spacing={4}>
                <Text>
                  Utilize os códigos da esquerda para obter os resultados da direita.
                </Text>
                <ComparativeCard
                  title="Negrito"
                  leftBox="**Negrito**"
                  rightBox={<ReactMarkdown>**Negrito**</ReactMarkdown>}
                />
                <ComparativeCard
                  title="Itálico"
                  leftBox="*Itálico*"
                  rightBox={<ReactMarkdown>*Itálico*</ReactMarkdown>}
                />
                <ComparativeCard
                  title="Lista ordenada"
                  leftBox={(
                    <Box>
                      <Text>1. Item 1</Text>
                      <Text>2. Item 2</Text>
                    </Box>
                  )}
                />
                <ComparativeCard
                  title="Lista desordenada"
                  leftBox={(
                    <Box>
                      <Text>- Item 1</Text>
                      <Text>- Item 2</Text>
                    </Box>
                  )}
                  rightBox={(
                    <ul>
                      <li>Item 1</li>
                      <li>Item 2</li>
                    </ul>
                  )}
                />
                <ComparativeCard
                  title="Links"
                  leftBox={(
                    <Text>
                      *[Site da Aristo](https://aristo.com.br/)*
                    </Text>
                  )}
                  rightBox={(
                    <ReactMarkdown>
                      *[Site da Aristo](https://aristo.com.br/)*
                    </ReactMarkdown>
                  )}
                />
                <ComparativeCard
                  title="Parágrafo"
                  leftBox={(
                    <Box>
                      <Text>Parágrafo</Text>
                      <br />
                      <Text>Parágrafo</Text>
                    </Box>
                  )}
                />
                <ComparativeCard
                  title="Imagens"
                  leftBox={(
                    <Text>
                      ![Logo da Aristo](https://aristo.com.br/wp-content/themes/theme-aristo/img/logo-aristo.svg)
                    </Text>
                  )}
                  rightBox={(
                    <ReactMarkdown>
                      ![Logo da Aristo](https://aristo.com.br/wp-content/themes/theme-aristo/img/logo-aristo.svg)
                    </ReactMarkdown>
                  )}
                />
                <Flex justify="center" w="100%">
                  <SecondaryButton onClick={() => setIsKnowMoreActive(false)}>
                    Voltar
                  </SecondaryButton>
                </Flex>
              </VStack>
            ) : (
              <Flex flexFlow="column" w="100%" gridGap={2}>
                {
                  answerTargetComment && (
                    <Flex
                      flexFlow="column"
                      border="1px solid"
                      borderColor={colors.secondary.keep}
                      h={180}
                    >
                      <Box
                        w="100%"
                        bgColor={colors.lighter.goAlmostFullDarker}
                        px={4}
                        py={2}
                      >
                        <Text
                          fontSize="sm"
                          color={colors.secondary.keep}
                        >
                          Comentário sendo respondido
                        </Text>
                      </Box>
                      <Box
                        h={180}
                        overflowY="auto"
                        px={4}
                        py={2}
                      >
                        <ReactMarkdown>
                          {answerTargetComment?.content}
                        </ReactMarkdown>
                      </Box>
                    </Flex>
                  )
                }
                <Flex
                  flexFlow="column"
                  border="1px solid"
                  borderColor={colors.secondary.keep}
                >
                  <Box
                    w="100%"
                    bgColor={colors.lighter.goAlmostFullDarker}
                    px={4}
                    py={2}
                  >
                    <Text
                      fontSize="sm"
                      color={colors.secondary.keep}
                    >
                      Sua resposta
                    </Text>
                  </Box>
                  <Textarea
                    px={4}
                    py={2}
                    resize="none"
                    height={150}
                    _placeholder={{ fontSize: (answerTargetComment && device === 'mobile') ? 'xs' : undefined }}
                    value={commentBeingCreated}
                    onChange={c => {
                      setCommentBeingCreated(c.target.value.replaceAll(SPLIT_MARKER, ''));
                    }}
                    disabled={loadingSendComment}
                    color={loadingSendComment ? 'gray' : undefined}
                    variant="unstyled"
                    placeholder="Escreva um comentário..."
                  />
                </Flex>
                <Flex
                  h={180}
                  border="1px solid"
                  borderColor={colors.secondary.keep}
                  flexFlow="column"
                  overflowY="auto"
                >
                  <Box
                    w="100%"
                    bgColor={colors.lighter.goAlmostFullDarker}
                    px={4}
                    py={2}
                  >
                    <Text
                      fontSize="sm"
                      color={colors.secondary.keep}
                    >
                      Pré-visualização
                    </Text>
                  </Box>
                  <Box
                    px={4}
                    py={2}
                    overflowY="auto"
                  >
                    {
                      answerTargetComment?.userName && (
                        <Text color={colors.secondary.keep} fontStyle="oblique" fontSize="xs">
                          {`Resposta a ${answerTargetComment?.userName}`}
                        </Text>
                      )
                    }
                    <ReactMarkdown className="inline">
                      {commentBeingCreated}
                    </ReactMarkdown>
                  </Box>
                </Flex>
                <HStack justify="flex-end" w="100%" mt={4}>
                  <SecondaryButton
                    onClick={() => setIsKnowMoreActive(true)}
                  >
                    Saiba mais
                  </SecondaryButton>
                  <PrimaryButton
                    disabled={commentBeingCreated.length === 0}
                    onClick={() => {
                      handleSendCommentClick();
                      onClose();
                    }}
                  >
                    Enviar resposta
                  </PrimaryButton>
                </HStack>
              </Flex>
            )
          }
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
