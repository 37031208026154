import {
  FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Box, Flex } from '@chakra-ui/react';
import Flashcard from '../../_components/Flashcard.component';
import { Flashcard as FlashcardInterface } from '../../../api/agenda/flashcards';
import { getTheoreticalType } from '../../../hooks/getTheoreticalType';
import { AgendaContext } from '../Agenda.context';
import useQuestionControls from '../../../hooks/useQuestionControls';
import { ExtraSmartReviewContext } from '../extra-activities/contexts/ExtraSmartReview.context';
import { ButtonNavigator } from '../../../lib/components/ButtonNavigator';
import { ActivityProgress } from '../_components/ActivityProgress';
import { Forum } from '../../_components/Forum/Forum';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { FlashcardIndexNavigator } from '../_components/FlashcardIndexNavigator';
import { FlashcardsTopButtons } from '../_components/FlashcardsTopButtons';
import { EmptyPage } from '../../_components/Forum/components/EmptyPage';
import { WholeAppContext } from '../WholeApp.context';
import { isTodayExtended } from '../../../utils/isTodayExtended';

const quant = 10;
interface AgendaFlashcardsProps {
  data: FlashcardInterface[];
  activityId?: string;
  finished?: boolean;
  shouldFinishActivity?: boolean;
  showTopButtons?: boolean;
}

const AgendaFlashcards: FC<AgendaFlashcardsProps> = ({
  data,
  activityId,
  finished,
  shouldFinishActivity = true,
  showTopButtons = true,
}) => {
  const { onFinishExtraActivity } = useContext(AgendaContext);
  const { questionsAndFlashcardsFinished, finishFlashcards } = useContext(ExtraSmartReviewContext);
  const { areShortcutsEnabled } = useContext(WholeAppContext);
  const [isReverse, setIsReverse] = useState(false);

  const { index, onIndex } = useQuestionControls(data);
  const { colors } = useDefaultStyles();
  const flashcardFullscreenHandle = useFullScreenHandle();
  const isFullscreen = flashcardFullscreenHandle.active;

  /**
   * States
   */
  const [init, setInit] = useState(0);
  const [answers, setAnswers] = useState(data.map(item => (typeof item.answer === 'number' ? item.answer : -1)));

  /**
   * Callbacks
   */
  const handlePrev = useCallback(() => {
    setInit(old => Math.max(old - quant, 0));
  }, []);

  const handleNext = useCallback(() => {
    setInit(old => Math.min(old + quant, Math.max(data.length - quant, 0)));
  }, [data.length]);

  const handlePrevIndex = useCallback(() => {
    const newIndex = Math.max(index - 1, 0);

    if (newIndex < init) {
      setInit(old => old - 1);
    }

    onIndex(newIndex)();
  }, [index, init, onIndex]);

  const handleNextIndex = useCallback(() => {
    const newIndex = Math.min(index + 1, data.length - 1);

    if (newIndex >= init + quant) {
      setInit(old => old + 1);
    }

    onIndex(newIndex)();
  }, [index, data.length, init, onIndex]);

  useEffect(() => {
    const prevOnKeyUp = document.onkeyup;
    if (areShortcutsEnabled) {
      document.onkeyup = e => {
        if (e.key === 'd' || e.code === 'ArrowRight') {
          handleNextIndex();
        } else if (e.key === 'a' || e.code === 'ArrowLeft') {
          handlePrevIndex();
        }
      };
    }
    return () => {
      document.onkeyup = prevOnKeyUp;
    };
  }, [areShortcutsEnabled, handleNextIndex, handlePrevIndex]);

  const handleSetAnswers = useCallback((i: number, value: number) => {
    setAnswers(old => [...old.slice(0, i), value, ...old.slice(i + 1)]);
    setTimeout(() => {
      handleNextIndex();
    }, 500);
  }, [handleNextIndex]);

  useEffect(() => {
    if (activityId && !finished && shouldFinishActivity) {
      if (!answers.includes(-1)) {
        if (finishFlashcards(activityId) !== undefined
          && !questionsAndFlashcardsFinished.find(obj => obj._id === activityId)?.questions) {
          return;
        }
        (async () => {
          await onFinishExtraActivity(activityId);
        })();
      }
    }
  }, [activityId,
    answers,
    finishFlashcards,
    finished,
    onFinishExtraActivity,
    questionsAndFlashcardsFinished,
    shouldFinishActivity]);

  /**
   * Memos
   */

  const fcCorrectAndWrongCount = useMemo(() => {
    const correctAndWrong = {
      correct: 0,
      wrong: 0,
      total: data.length,
    };
    answers.forEach(curr => {
      if (curr !== -1 && curr === 1) {
        correctAndWrong.correct += 1;
      }
      if (curr !== -1 && curr === 0) {
        correctAndWrong.wrong += 1;
      }
    });
    return correctAndWrong;
  }, [answers, data.length]);

  const { _id, front, back, classification, prefix } = useMemo(() => data[index], [data, index]);

  const isCommunityFlashcard = !classification;

  const fcAdditionalInfo = useMemo(() => {
    if (prefix) {
      return prefix;
    }
    if (classification) {
      return classification[1];
    }
    return undefined;
  }, [classification, prefix]);

  if (data[index] === undefined) {
    return <EmptyPage label="Sem flashcards por aqui..." />;
  }

  return (
    <FullScreen handle={flashcardFullscreenHandle} className="flashcard-component">
      <Box
        maxW={isFullscreen ? undefined : 800}
        w={isFullscreen ? '100%' : undefined}
        m="0 auto"
        overflowY={flashcardFullscreenHandle.active ? 'scroll' : undefined}
        px={isFullscreen ? { base: '5%', md: '10%', lg: '20%' } : 2}
        h={isFullscreen ? '100vh' : undefined}
        bgColor={isFullscreen ? colors.background : undefined}
      >
        <ActivityProgress
          summary={fcCorrectAndWrongCount}
          index={index}
          showStats
          my={4}
        />
        <FlashcardIndexNavigator
          index={index}
          init={init}
          onClick={onIndex}
          answers={answers}
          data={data}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
        <Flex
          my={4}
          flexFlow="column"
          align="center"
          w="100%"
        >
          <FlashcardsTopButtons
            flashcardId={_id}
            fullscreenHandle={flashcardFullscreenHandle}
            show={showTopButtons}
            isCommunityFlashcard={isCommunityFlashcard}
            isReverse={isReverse}
            setIsReverse={setIsReverse}
          />
          <Flex justify="center" flexFlow="column" align="center" w="100%">
            <Flashcard
              id={_id}
              front={isReverse ? back : front}
              back={isReverse ? front : back}
              additionalInfo={fcAdditionalInfo}
              index={index}
              active
              answer={answers[index]}
              activityId={activityId}
              classification={classification}
              onSetAnswers={handleSetAnswers}
              disabled={getTheoreticalType() === 'favorites' || !isTodayExtended()}
            />
            <ButtonNavigator
              handleNext={handleNextIndex}
              handlePrev={handlePrevIndex}
              isNextDisabled={index === (data.length - 1)}
              isPrevDisabled={index === 0}
            />
          </Flex>
        </Flex>
        {
          classification && (
            <Forum
              show={answers[index] !== -1}
              id={_id}
              tag={['Flashcard', classification.join('|') || 'Sem classificação'].join('|')}
            />
          )
        }
      </Box>
    </FullScreen>
  );
};

export default AgendaFlashcards;
