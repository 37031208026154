import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import React, { createContext, FC, useCallback, useRef, useState } from 'react';
import { CommentDataType, ForumCommentType } from '../views/_components/Forum/types/forum.types';

type DisclosureType = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isControlled: boolean;
};

type RemovedCommentType = {
  id: string,
  isFirstLevel: boolean,
}

export interface ForumInterface {
  comments: ForumCommentType[],
  setComments: React.Dispatch<React.SetStateAction<ForumCommentType[]>>,
  answerTargetComment?: CommentDataType,
  setAnswerTargetComment: React.Dispatch<React.SetStateAction<CommentDataType | undefined>>;
  removedComments: RemovedCommentType[];
  setRemovedComments: React.Dispatch<React.SetStateAction<RemovedCommentType[]>>;
  updateComments: (incomingComments: ForumCommentType[]) => void;
  updateRemovedCommentsList: (newRemovedComments: RemovedCommentType[]) => void;
  updateCommentAnswers: ({ firstLevelCommentId, newComments }: UpdateCommentAnswersType) => void;
  commentBoxRef: React.RefObject<HTMLInputElement>,
  forumRef: React.RefObject<HTMLDivElement>;
  answersCursorShouldResetFlag: 1|-1,
  setAnswersCursorShouldResetFlag: React.Dispatch<React.SetStateAction<1|-1>>,
  forumDisclosure: DisclosureType,
  isCurrUserSpecialist: boolean,
  setIsCurrUserSpecialist: React.Dispatch<React.SetStateAction<boolean>>,
  currCommentId?: string,
  setCurrCommentId: React.Dispatch<React.SetStateAction<string | undefined>>,
}

export type UpdateCommentAnswersType = {
  firstLevelCommentId: string,
  newComments: ForumCommentType[],
}

export const ForumContext = createContext({} as ForumInterface);

export const ForumStorage: FC = ({ children }) => {
  const [comments, setComments] = useState<ForumCommentType[]>([]);
  const [currCommentId, setCurrCommentId] = useState<string>();
  const [answerTargetComment, setAnswerTargetComment] = useState<CommentDataType>();
  const [removedComments, setRemovedComments] = useState<RemovedCommentType[]>([]);
  const [answersCursorShouldResetFlag, setAnswersCursorShouldResetFlag] = useState<1|-1>(-1);
  const [isCurrUserSpecialist, setIsCurrUserSpecialist] = useState(false);
  const commentBoxRef = useRef<HTMLInputElement>(null);
  const forumRef = useRef<HTMLDivElement>(null);
  const forumDisclosure = useDisclosure();

  const updateRemovedCommentsList = useCallback((newRemovedComments: RemovedCommentType[]) => {
    setComments(prev => {
      const withoutFirstLevelRemoved = prev.filter(x => !newRemovedComments.map(y => y.id).includes(x._id));
      const withoutFromSecondLevelOnRemoved = withoutFirstLevelRemoved
        .map(firstLevelComment => {
          if (firstLevelComment.commentAnswers) {
            const newCommentAnswers = firstLevelComment
              .commentAnswers
              .filter(ans => !newRemovedComments.map(y => y.id).includes(ans._id));
            return { ...firstLevelComment, commentAnswers: newCommentAnswers };
          } return firstLevelComment;
        });
      return withoutFromSecondLevelOnRemoved;
    });
  }, []);

  const updateComments = useCallback((incomingComments: ForumCommentType[]) => {
    setComments(prev => {
      const previousCommentsIds = prev.map(x => x._id);
      const nonExistentComments = incomingComments.filter(x => !previousCommentsIds.includes(x._id));
      const updatedAlreadyExistentComments = prev.map(prevComment => {
        const currComment = incomingComments.filter(x => x._id === prevComment._id)[0];
        const updatedComment = { ...prevComment, ...currComment };
        return updatedComment;
      });
      const sortedComments = [...updatedAlreadyExistentComments, ...nonExistentComments]
        .sort((a, b) => {
          return moment(a['Created Date']) > moment(b['Created Date']) ? -1 : 1;
        });
      return sortedComments.filter(x => !removedComments.map(y => y.id).includes(x._id));
    });
  }, [removedComments, setComments]);

  const updateCommentAnswers = useCallback(({
    firstLevelCommentId,
    newComments,
  }: UpdateCommentAnswersType) => {
    setComments(prev => {
      const newValue = prev.map(firstLevelComment => {
        if ((firstLevelComment._id === firstLevelCommentId) && firstLevelComment.commentAnswers) {
          return { ...firstLevelComment, commentAnswers: [...firstLevelComment.commentAnswers, ...newComments] };
        } return firstLevelComment;
      });
      return newValue;
    });
  }, []);

  return (
    <ForumContext.Provider
      value={{
        isCurrUserSpecialist,
        setIsCurrUserSpecialist,
        forumDisclosure,
        answersCursorShouldResetFlag,
        setAnswersCursorShouldResetFlag,
        comments,
        setComments,
        commentBoxRef,
        answerTargetComment,
        setAnswerTargetComment,
        removedComments,
        setRemovedComments,
        updateComments,
        updateRemovedCommentsList,
        updateCommentAnswers,
        forumRef,
        currCommentId,
        setCurrCommentId,
      }}
    >
      {children}
    </ForumContext.Provider>
  );
};
