import { FC } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Forum } from '../_components/Forum/Forum';

export const ForumViewer: FC = () => {
  const { uid } = useParams<{ uid: string }>();
  const splittedUid = uid.split('|');
  const len = splittedUid.length;
  if (uid.includes('Questão')) {
    return (
      <Redirect
        to={`/viewer/questao?id=${splittedUid[len - 1]}`}
      />
    );
  }
  if (uid.includes('Flashcard')) {
    return (
      <Redirect
        to={`/viewer/flashcard?id=${splittedUid[len - 1]}`}
      />
    );
  }
  if (uid.includes('Video')) {
    return (
      <Redirect
        to={`/viewer/video?id=${splittedUid[len - 1]}`}
      />
    );
  }
  return (
    <Forum id={uid} />
  );
};
