import { ApolloQueryResult } from '@apollo/client';
import { Box, Circle, Flex, HStack, Square, Text, useTheme, Wrap, WrapItem } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PreChoiceInterface } from '../../../api/agenda/mock-exam';
import { Question } from '../../../api/agenda/questions';
import { useGetActivityDetailsForPrint } from '../../../api/extra-activities/activities-for-print';
import { QuestionManyFavoriteOutputType, SkipLimitInputType } from '../../../api/favorites';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { QuestionCardType } from '../../../hooks/useQuestionHooks';
import { Loading } from '../../../lib/components/Loading';
import { PrivateContext } from '../../../Private.context';
import { NavigatorItemStyle } from '../../../utils/getNavColors';
import { PrintMenu } from './PrintMenu.component';

export interface PrintProps {
  name?: string,
  title?: string,
  date?: string,
  subtitle?: string,
  institution?: string,
  uf?: string,
  year?: number,
}
interface QuestionIndexNavigatorProps {
  accumulatedData: Question[];
  getNavColors: (ind: number) => NavigatorItemStyle;
  onClick: (value: number) => () => void;
  device: 'mobile' | 'web';
  hiddenIndexes: number[];
  printProps?: PrintProps;
  clientPreChoices?: PreChoiceInterface[];
  setClientPreChoices?: Dispatch<SetStateAction<PreChoiceInterface[]>>;
  hideExamFeedbackButton?: boolean;
  hidePrintButton?: boolean;
  refetchFavorites?: (variables?: Partial<SkipLimitInputType> | undefined) =>
    Promise<ApolloQueryResult<QuestionManyFavoriteOutputType>>;
  bgColorProp?: string;
  colorProp?: string;
  toggleFullscreen?: () => void;
  model: QuestionCardType;
  isFullscreen: boolean;
  setAccumulatedData: React.Dispatch<React.SetStateAction<Question[]>>;
  favoritesSkip: number;
  setFavoritesSkip: React.Dispatch<React.SetStateAction<number>>;
}

export function QuestionIndexNavigator({
  accumulatedData, getNavColors, onClick,
  device, hiddenIndexes, printProps, model,
  clientPreChoices, setClientPreChoices, hidePrintButton = false, setAccumulatedData,
  hideExamFeedbackButton = false, bgColorProp, colorProp, toggleFullscreen, isFullscreen, refetchFavorites,
  favoritesSkip, setFavoritesSkip,
}: QuestionIndexNavigatorProps) {
  const STEP = useMemo(() => 100, []);
  const theme = useTheme();
  const { toRGB } = useRGBColor();
  const { cardBorder, colors } = useDefaultStyles();
  // const [skip, setSkip] = useState(0);
  const [loadingRefetchFavorites, setLoadingRefetchFavorites] = useState(false);
  const [finishedLoadingAllQuestions, setFinishedLoadingAllQuestions] = useState(false);
  const [haveEverLoadedQuestions, setHaveEverLoadedQuestions] = useState(false);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');
  const activityId = String(params.get('a') || '');
  const { doubtQuestionIds } = useContext(PrivateContext);
  const isDiagnostic = window.location.pathname.includes('diagnostico')
    && !isCompass;

  const query = useGetActivityDetailsForPrint({
    activityId,
  });

  const handleInfiniteScrollNext = useCallback(async () => {
    setHaveEverLoadedQuestions(true);
    setLoadingRefetchFavorites(true);
    if (refetchFavorites) {
      const { data: refetchData } = await refetchFavorites({ skip: favoritesSkip + STEP });
      if (refetchData.questionManyFavorite.length > 0) {
        setAccumulatedData(prev => {
          return [...prev, ...refetchData.questionManyFavorite];
        });
        setFavoritesSkip(prev => prev + STEP);
      } else {
        setFinishedLoadingAllQuestions(true);
      }
    }
    setLoadingRefetchFavorites(false);
  }, [STEP, favoritesSkip, refetchFavorites, setAccumulatedData, setFavoritesSkip]);

  const infiniteScrollHeight = useMemo(() => {
    const numberOfVisibleQuestions = accumulatedData.length - hiddenIndexes.length;
    if (model === 'favorites') {
      if (numberOfVisibleQuestions > 75) {
        return 380 + Number(finishedLoadingAllQuestions) * 45;
      } return 50 + 25 * (Math.ceil(numberOfVisibleQuestions / 5));
    } return undefined;
  }, [accumulatedData.length, finishedLoadingAllQuestions, hiddenIndexes.length, model]);

  if (device === 'mobile') {
    return (
      <HStack
        display={{ base: 'flex', md: 'none' }}
        align="stretch"
        overflowX="scroll"
        m="0 auto"
        p={2}
      >
        {accumulatedData.map((item, i) => {
          const { bgColor, textColor, borderColor } = getNavColors(i);
          if (hiddenIndexes.includes(i)) {
            return null;
          }
          return (
            <Box
              as="button"
              onClick={onClick(i)}
              key={Math.random()}
              position="relative"
            >
              <Square
                border={`
                ${borderColor === theme.colors.primary[500] ? '2px' : '1px'}
                solid ${isDiagnostic ? 'white' : borderColor}
                `}
                color={isDiagnostic ? 'white' : textColor}
                backgroundColor={bgColor}
                borderRadius={5}
                boxSize="45px"
              >
                <Text>{i + 1}</Text>
              </Square>
              {
                doubtQuestionIds.includes(item._id) && (
                  <Circle
                    position="absolute"
                    size={3}
                    bgColor={colors.red.keep}
                    top={0}
                    right={0}
                  />
                )
              }
            </Box>
          );
        })}
        {
          model === 'favorites' && !finishedLoadingAllQuestions && (
            <Square
              border={`2px solid ${toRGB(colors.secondary.keep)}`}
              color={colors.secondary.keep}
              as="button"
              onClick={handleInfiniteScrollNext}
              borderRadius={5}
              style={{
                width: '45px',
                height: '45px',
              }}
            >
              {
                loadingRefetchFavorites
                  ? <Loading />
                  : <FontAwesomeIcon icon={faPlus} />
              }
            </Square>
          )
        }
      </HStack>
    );
  }

  return (
    <Box
      {...cardBorder}
      display={{ base: 'none', md: 'block' }}
      px={1}
      py={2}
      overflow="auto"
      maxHeight={480}
      bgColor={bgColorProp}
      color={colorProp}
    >
      <InfiniteScroll
        dataLength={favoritesSkip + STEP}
        style={{
          width: '100%',
          overflowY: model === 'favorites' ? 'auto' : 'hidden',
          overflowX: 'hidden',
          display: 'flex',
          flexFlow: 'wrap',
        }}
        hasMore={!finishedLoadingAllQuestions}
        height={infiniteScrollHeight}
        loader={(
          <Flex w="100%" justify="center" py={2}>
            <Loading />
          </Flex>
        )}
        next={handleInfiniteScrollNext}
      >
        <Flex justify="center" align="center" py={1} w="100%">
          <PrintMenu
            model={model}
            loadingMenu={query.loading}
            data={accumulatedData.filter((_, i) => !hiddenIndexes.includes(i))}
            clientPreChoices={clientPreChoices}
            setClientPreChoices={setClientPreChoices}
            hideExamFeedbackButton={hideExamFeedbackButton}
            hidePrintButton={hidePrintButton}
            printProps={printProps}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
          />
        </Flex>
        <Wrap justify="center" columns={5}>
          {
            accumulatedData.map((item, i) => {
              if (hiddenIndexes.includes(i)) {
                return null;
              }
              const tId = `${item._id}-${i}`;
              const { textColor, borderColor, bgColor } = getNavColors(i);
              return (
                <WrapItem as="button" onClick={onClick(i)} className="m-0" key={tId}>
                  <Flex position="relative">
                    <Square
                      border={`${borderColor === theme.colors.primary[500] ? '2px' : '1px'} solid ${borderColor}`}
                      color={textColor}
                      backgroundColor={bgColor}
                      height="25px"
                      width="25px"
                      borderRadius={4}
                    >
                      <Text fontSize="xs">{i + 1}</Text>
                    </Square>
                    {
                      doubtQuestionIds.includes(item._id) && (
                        <Circle
                          position="absolute"
                          size={2}
                          bgColor={colors.red.keep}
                          top={0}
                          right={0}
                        />
                      )
                    }
                  </Flex>
                </WrapItem>
              );
            })
          }
        </Wrap>
      </InfiniteScroll>
      {
        !finishedLoadingAllQuestions && haveEverLoadedQuestions && model === 'favorites' && (
          <Flex
            justify="center"
            w="100%"
            px={3}
            fontSize={14}
            bottom={1}
            color={colors.red.keep}
          >
            <Text>Há mais questões a serem carregadas</Text>
          </Flex>
        )
      }
    </Box>
  );
}
