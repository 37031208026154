import { ApolloQueryResult } from '@apollo/client';
import { Box, Flex, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useHistory } from 'react-router-dom';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PreChoiceInterface, RankMockExamInterface } from '../../../api/agenda/mock-exam';
import { Question } from '../../../api/agenda/questions';
import { getTheoreticalType } from '../../../hooks/getTheoreticalType';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { QuestionCardType, useQuestionHooks } from '../../../hooks/useQuestionHooks';
import { ButtonNavigator } from '../../../lib/components/ButtonNavigator';
import { PrivateContext } from '../../../Private.context';
import { toast } from '../../../utils/toast';
import { isTodayExtended } from '../../../utils/isTodayExtended';
import { ExamFeedbackButton } from '../../print/_components/ExamFeedback.component';
import Counter from '../../_components/Counter.component';
import { Forum } from '../../_components/Forum/Forum';
import EssayQuestion from '../../_components/Question/EssayQuestion.component';
import QuestionCard from '../../_components/Question/QuestionCard.component';
import { AgendaContext } from '../Agenda.context';
import { ActivityProgress } from '../_components/ActivityProgress';
import { ExamModeSwitch } from '../_components/ExamModeSwitch.component';
import { FinishButton } from '../_components/FinishButton.component';
import { QuestionIndexNavigator } from '../_components/QuestionIndexNavigator';
import { RankingInfoCard } from '../_components/RankingInfoCard.component';
import Results from '../_components/Results.component';
import { UseDiagnosticExamOutputType } from '../../../api/diagnosis';
import { useOnExamAlert } from '../../diagnosis/hooks/useOnExamAlerts';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { getTimeSpentSince } from '../../../utils/getTimeSpent';
import { useDevice } from '../../../hooks/useDevice';
import { WholeAppContext } from '../WholeApp.context';
import { NeedToAnswerEssayAlert } from '../../_components/Question/NeedToAnswerEssayAlert';
import { ShowAnswersOnExamsButton } from '../../_components/Question/ShowAnswersOnExamsButton';
import { FullscreenQuestionMenu } from '../../_components/Question/FullscreenQuestionMenu';
import { ForumContext } from '../../../contexts/Forum.context';
import { QuestionManyFavoriteOutputType, SkipLimitInputType } from '../../../api/favorites';
import { FilterQuestionsByTheme } from '../../_components/Question/FilterQuestionsByTheme';
import { FilterQuestionsByDifficulty } from '../../_components/Question/FilterQuestionsByDifficulty';
import { DifficultyThermometer } from '../../_components/Question/DifficultyThermometer';
import { setParam } from '../../../hooks/useSearchParam';

type QuestionsType = {
  model: QuestionCardType;
  data: Question[];
  serverPreChoices?: PreChoiceInterface[];
  finished?: boolean;
  answersAt?: Date;
  startedAt?: Date;
  timeToDo?: number;
  activityId?: string;
  mockExamTemplate?: string;
  finishesAt?: Date;
  rankingAt?: Date;
  ranking?: RankMockExamInterface;
  shouldFinishActivity?: boolean;
  diagnosticExamCallback?: UseDiagnosticExamOutputType;
  disableCommunication?: boolean;
  refetchFavorites?: (variables?: Partial<SkipLimitInputType> | undefined) =>
    Promise<ApolloQueryResult<QuestionManyFavoriteOutputType>>;
}

export const Questions: FC<QuestionsType> = ({
  model,
  data,
  serverPreChoices,
  activityId,
  finished,
  finishesAt,
  answersAt,
  startedAt,
  timeToDo,
  mockExamTemplate,
  refetchFavorites,
  rankingAt,
  ranking,
  shouldFinishActivity = true,
  diagnosticExamCallback,
  disableCommunication = false,
}: QuestionsType) => {
  const {
    areAllQuestionsChosen,
    index,
    isQuestionsModel,
    isExamMode,
    isMocksModel,
    onPrev,
    onNext,
    onIndex,
    getNavColors,
    answers,
    essays,
    currentPreChoice,
    clientPreChoices,
    setClientPreChoices,
    finishedState,
    prefersExamMode,
    handleSetPrefersExamMode,
    questionsSummary,
    shouldConsiderExamMode,
    isDisabled,
    result,
    loading,
    handleSelectChoice,
    handleFinish,
    handleSaveEssay,
    handleSubmitEssayAnswer,
    handleAnswerClick,
    showForum,
    answeredAll,
    selectedAll,
    modelName,
    finishQuestions,
    questionsAndFlashcardsFinished,
    onFinishExtraActivity,
    handleRankMockExam,
    isAnsweredQuestion,
    showCounter,
    alreadyStarted,
    setFinishedState,
    isQuestionsDefaultMode,
    hideResults,
    hiddenIndexes,
    visibleIndexes,
    toggleDifficultyVisibility,
    needToAnswer,
    questionsToShow,
    isFavoritesModel,
    selectedChoice,
    setSelectedChoice,
    calcDifficulty,
    accumulatedData,
    setAccumulatedData,
    isAddThemeButtonDisabled,
    classificationCascade,
    onThemeSelectorChange,
  } = useQuestionHooks({
    model,
    data,
    serverPreChoices,
    disableCommunication,
    activityId,
    finished,
    startedAt,
    timeToDo,
    mockExamTemplate,
    diagnosticExamCallback,
  });

  const {
    refetchExtras,
    setPercentOfQuestionsAnswered,
  } = useContext(AgendaContext);
  const { forumDisclosure } = useContext(ForumContext);
  const device = useDevice();
  const questionFullscreenHandle = useFullScreenHandle();
  const isQuestionComponent = questionFullscreenHandle.node.current?.className.includes('question-component') || false;
  const isFullscreen = questionFullscreenHandle.active && device === 'web' && isQuestionComponent;
  const [focusStartDate, setFocusStartDate] = useState<Date | undefined>(new Date());
  const [isNavigatorVisible, setIsNavigatorVisible] = useState(true);
  const { refetchAgenda } = useContext(PrivateContext);
  const { setHideElementsThatTriggerModals } = useContext(WholeAppContext);
  const { colors } = useDefaultStyles();
  const shouldHideNavigator = !isNavigatorVisible && isFullscreen;
  const history = useHistory();
  const { handleCanFinishExam, handleCannotFinishExam, handleWelcomeBack } = useOnExamAlert();
  const isDiagnosticRoute = window.location.href.includes('diagnostico');
  const isPartialCompass = window.location.href.includes('diagnostico-parcial');
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');
  const [focusTimeSpent, setFocusTimeSpent] = useState('');
  const [isViewMode, setIsViewMode] = useState(false);
  const [favoritesSkip, setFavoritesSkip] = useState(0);

  useEffect(() => {
    if (activityId) {
      history.push(setParam({ key: 'a', value: activityId }));
    }
  }, [activityId, history]);

  const answeredAny = useMemo(() => {
    return answers.some(x => x !== -1);
  }, [answers]);

  const showResults = useMemo(() => {
    if (isFavoritesModel) {
      return selectedChoice !== -1;
    }
    if ((isViewMode && !answeredAny) || !isTodayExtended()) {
      return true;
    }
    if (isQuestionsModel) {
      return isAnsweredQuestion || finishedState;
    }
    return finishedState;
  }, [isFavoritesModel, isViewMode, answeredAny, isQuestionsModel, finishedState, selectedChoice, isAnsweredQuestion]);

  const showComment = useMemo(() => {
    if (!isTodayExtended() || (isViewMode && !answeredAny)) {
      return true;
    }
    return (isAnsweredQuestion || (isExamMode && finishedState)) && (isQuestionsModel || finishedState);
  }, [isViewMode, answeredAny, isAnsweredQuestion, finishedState, isExamMode, isQuestionsModel]);

  const questionCardProps = useMemo(() => ({
    answer: answers[index],
    data: accumulatedData[index],
    bgColorProp: colors.background,
    disabled: isDisabled,
    isFavoritesModel,
    disableCommunication,
    index,
    isExam: isExamMode || !isQuestionsModel,
    showResults,
    showComment,
    showCommentElements: !isDiagnosticRoute,
  }), [
    answers, index, accumulatedData, colors.background, isDisabled, isFavoritesModel,
    disableCommunication, isExamMode, isQuestionsModel, showResults,
    showComment, isDiagnosticRoute,
  ]);

  useEffect(() => {
    if (!isFullscreen) {
      setFocusStartDate(undefined);
      setHideElementsThatTriggerModals(false);
    } else {
      setHideElementsThatTriggerModals(true);
    }
  }, [isFullscreen, setFocusStartDate, setHideElementsThatTriggerModals]);

  useEffect(() => {
    if (isFullscreen) {
      const intervalId = setInterval(() => {
        if (focusStartDate) {
          setFocusTimeSpent(getTimeSpentSince(focusStartDate));
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
    return () => null;
  }, [focusStartDate, isFullscreen]);

  useEffect(() => {
    if (isQuestionsModel && answeredAll && activityId && !finished && shouldFinishActivity) {
      if (finishQuestions(activityId) !== undefined
        && !questionsAndFlashcardsFinished.find(obj => obj._id === activityId)?.flashcards) {
        return;
      }
      (async () => {
        await onFinishExtraActivity(activityId);
      })();
    }
  }, [activityId, answers, finishQuestions, isQuestionsModel, finished,
    onFinishExtraActivity, questionsAndFlashcardsFinished, shouldFinishActivity, answeredAll]);

  useEffect(() => {
    const questionsAnsweredAmount = answers.filter(x => x !== -1).length;
    const totalQuestions = answers.length;
    const currLink = window.location.href;
    if (currLink.includes('questao') && currLink.includes('revisao-teorica')) {
      const value = questionsAnsweredAmount >= 10 ? 1 : questionsAnsweredAmount / totalQuestions;
      setPercentOfQuestionsAnswered(value);
    }
  }, [answers, clientPreChoices.length, setPercentOfQuestionsAnswered]);

  useEffect(() => {
    refetchExtras();
    refetchAgenda();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPreChoices]);

  useEffect(() => {
    if (isMocksModel
      && !ranking
      && rankingAt
      && new Date() > rankingAt
      && !getTheoreticalType().includes('extras')
    ) {
      handleRankMockExam();
    }
  }, [handleRankMockExam, isMocksModel, ranking, rankingAt]);

  useEffect(() => {
    if (isMocksModel && startedAt && finishesAt && timeToDo && !loading) {
      const started = new Date(startedAt);
      const millisec = started.getTime();
      const dueDate = new Date(millisec + timeToDo);
      if ((dueDate < new Date() || finishesAt < new Date()) && !finishedState) {
        handleFinish();
      }
    }
  }, [finished, finishedState, finishesAt, handleFinish, isMocksModel, loading, startedAt, timeToDo]);

  const toggleFullscreen = useCallback(async () => {
    try {
      if (!questionFullscreenHandle.active) {
        questionFullscreenHandle.enter();
        setFocusStartDate(new Date());
      } else {
        questionFullscreenHandle.exit();
        setFocusStartDate(undefined);
      }
    } catch (e) {
      toast.error({
        title: 'Não foi possível alternar o modo foco.',
        description: 'Talvez seu dispositivo seja incompatível.',
      });
      console.error(e);
    }
  }, [questionFullscreenHandle, setFocusStartDate]);

  const handleFinishDiagnostic = useCallback(async () => {
    if (diagnosticExamCallback && model === 'diagnostic') {
      const { handleFinishDiagnosticExam, findDiagnosticExamQuery } = diagnosticExamCallback;
      const { refetch } = findDiagnosticExamQuery;
      if (finishedState) {
        history.push('/diagnostico/finalizar');
      } else if (answeredAll) {
        handleCanFinishExam(async () => {
          try {
            questionFullscreenHandle?.exit();
            setFocusStartDate(undefined);
          } catch (e) {
            console.error(e);
          }
          await handleFinishDiagnosticExam();
          localStorage.setItem('@plataformaJJMentoria:finished', 'true');
          if (refetch) {
            await refetch();
          }
          setFinishedState(true);
        });
      } else {
        handleCannotFinishExam();
      }
    }
  }, [answeredAll, diagnosticExamCallback, finishedState,
    handleCanFinishExam, handleCannotFinishExam, history, model,
    questionFullscreenHandle, setFinishedState, setFocusStartDate]);

  const handleFinishPartialDiagnostic = useCallback(async () => {
    if (diagnosticExamCallback && model === 'diagnostic') {
      try {
        await document.exitFullscreen();
        setFocusStartDate(undefined);
      } catch (e) {
        console.error(e);
      }
      const { handleFinishDiagnosticExam, findDiagnosticExamQuery } = diagnosticExamCallback;
      const { refetch } = findDiagnosticExamQuery;
      if (finishedState) {
        history.push('/aristo-compass/diagnostico-parcial/questoes/resultado');
      } else if (answeredAll) {
        handleCanFinishExam(async () => {
          await handleFinishDiagnosticExam();
          localStorage.setItem('@plataformaJJMentoria:finishedPartialDiagnostic', 'true');
          if (refetch) {
            await refetch();
          }
          setFinishedState(true);
        });
      } else {
        handleCannotFinishExam();
      }
    }
  }, [
    answeredAll, diagnosticExamCallback, finishedState, handleCanFinishExam,
    handleCannotFinishExam, history, model, setFinishedState, setFocusStartDate,
  ]);

  useEffect(() => {
    if (answeredAll && !finishedState && isPartialCompass && diagnosticExamCallback) {
      const { handleFinishDiagnosticExam } = diagnosticExamCallback;
      const { refetch } = diagnosticExamCallback.findDiagnosticExamQuery;
      handleCanFinishExam(async () => {
        setFinishedState(true);
        await handleFinishDiagnosticExam();
        if (isPartialCompass) {
          localStorage.setItem('@plataformaJJMentoria:finishedPartialDiagnostic', 'true');
        } else {
          localStorage.setItem('@plataformaJJMentoria:finished', 'true');
        }
        if (refetch) {
          await refetch();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answeredAll, finishedState, handleCanFinishExam, model]);

  useEffect(() => {
    if (diagnosticExamCallback) {
      const { findDiagnosticExamQuery } = diagnosticExamCallback;
      const _data = findDiagnosticExamQuery.data?.findDiagnosticExam;
      const preChoices = _data?.preChoice ?? [];
      setClientPreChoices(preChoices);
      if (preChoices.length && !_data?.finished) {
        handleWelcomeBack(() => {
          setClientPreChoices([]);
          localStorage.removeItem('@plataformaJJMentoria:id');
          localStorage.removeItem('@plataformaJJMentoria:partialDiagnosticId');
          localStorage.removeItem('@plataformaJJMentoria:institutions');
          localStorage.removeItem('@Aristoclass:isCompass');
          localStorage.removeItem('@Aristoclass:examVariant');
          history.push(isCompass ? '/aristo-compass' : '/diagnostico');
        });
      }

      if (_data?.finished) {
        if (isPartialCompass) {
          localStorage.setItem('@plataformaJJMentoria:finishedPartialDiagnostic', 'true');
        } else {
          localStorage.setItem('@plataformaJJMentoria:finished', 'true');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleWelcomeBack, history, setClientPreChoices]);

  const onFinishButtonClick = useMemo(() => {
    if (isPartialCompass) {
      return handleFinishPartialDiagnostic;
    }
    if (model === 'diagnostic') {
      return handleFinishDiagnostic;
    }
    return handleFinish;
  }, [handleFinish, handleFinishDiagnostic, handleFinishPartialDiagnostic, isPartialCompass, model]);

  const difficultyStats = useMemo(() => {
    return calcDifficulty(result.easy.total, result.medium.total, result.hard.total, result.unknown.total);
  }, [calcDifficulty, result.easy.total, result.hard.total, result.medium.total, result.unknown.total]);

  return (
    <Box w="100%">
      <FullScreen
        handle={questionFullscreenHandle}
        className="question-component"
      >
        <Flex
          px={isFullscreen ? { base: '5%', md: '10%', lg: '20%' } : 2}
          h={isFullscreen ? '100vh' : undefined}
          bgColor={model !== 'diagnostic' || isFullscreen ? colors.background : undefined}
          overflow="auto"
          transition="all .4s"
          justify="center"
          m="0.5rem auto"
          w="100%"
          maxW={isFullscreen ? undefined : 1000}
        >
          <Box
            mt={isFullscreen ? '10%' : undefined}
            fontSize={isFullscreen ? 20 : undefined}
            w="100%"
          >
            <NeedToAnswerEssayAlert
              show={!isFavoritesModel && finishedState && !selectedAll && needToAnswer.length !== 0}
              needToAnswer={needToAnswer}
            />
            <Counter
              device="mobile"
              showCounter={showCounter}
              startedAt={startedAt}
              timeToDo={timeToDo}
            />
            <RankingInfoCard
              device="mobile"
              hide={!isMocksModel && !isFavoritesModel}
              answersAt={answersAt}
              rankingAt={rankingAt}
              finishedState={finishedState}
            />
            <Box
              maxW={400}
              m="0.5rem auto"
              display={hideResults ? 'none' : undefined}
            >
              <Results
                device="mobile"
                hide={hideResults}
                result={result}
              />
            </Box>
            <ActivityProgress
              hide={isFavoritesModel}
              color={model === 'diagnostic' && !isCompass ? 'white' : undefined}
              index={index}
              summary={questionsSummary}
              showStats={((isQuestionsModel && !prefersExamMode) || !hideResults) && !shouldHideNavigator}
              my={4}
            />
            <Flex w="100%" justify="space-between" flexFlow={{ base: 'column', md: 'row' }}>
              {
                ((!hideResults || isViewMode) || isFavoritesModel) && (
                  <VStack
                    w="100%"
                    align="flex-start"
                    color={model === 'diagnostic' && !isCompass && !isFullscreen ? colors.white.keep : undefined}
                  >
                    <FilterQuestionsByDifficulty
                      questionsToShow={questionsToShow}
                      toggleDifficultyVisibility={toggleDifficultyVisibility}
                    />
                    <DifficultyThermometer
                      difficultyStats={difficultyStats}
                      hide={isFavoritesModel}
                    />
                  </VStack>
                )
              }
              <ShowAnswersOnExamsButton
                show={!answeredAny && model === 'exams' && getTheoreticalType().includes('extras')}
                isViewMode={isViewMode}
                setIsViewMode={setIsViewMode}
              />
            </Flex>
            <FilterQuestionsByTheme
              hide={!isFavoritesModel}
              isAddThemeButtonDisabled={isAddThemeButtonDisabled}
              onThemeSelectorChange={onThemeSelectorChange}
              classificationCascade={classificationCascade}
            />
            {
              isFavoritesModel && (
                <Flex w="100%" color={colors.secondary.keep} flexFlow="column">
                  <Text fontSize="xs">
                    * Questões feitas na tela de favoritos não serão contabilizadas.
                  </Text>
                </Flex>
              )
            }
            <FinishButton
              device="mobile"
              show={model === 'diagnostic'}
              mb={3}
              isBlinking={model === 'diagnostic' && finishedState}
              label={model === 'diagnostic' && finishedState ? 'Meu diagnóstico' : undefined}
              modelName={modelName}
              disabled={!areAllQuestionsChosen || isDisabled}
              isLoading={loading}
              onClick={onFinishButtonClick}
            />
            <HStack justifyContent="space-between" align="center">
              <QuestionIndexNavigator
                device="mobile"
                isFullscreen={isFullscreen}
                hideExamFeedbackButton={isQuestionsDefaultMode || finishedState || isViewMode || !isTodayExtended()}
                accumulatedData={accumulatedData}
                refetchFavorites={refetchFavorites}
                favoritesSkip={favoritesSkip}
                setFavoritesSkip={setFavoritesSkip}
                setAccumulatedData={setAccumulatedData}
                getNavColors={getNavColors}
                onClick={onIndex}
                model={model}
                hiddenIndexes={hiddenIndexes}
              />
              {
                !isFavoritesModel && (
                  <ExamFeedbackButton
                    device="mobile"
                    hide={!clientPreChoices
                      || !setClientPreChoices
                      || isQuestionsDefaultMode
                      || finishedState
                      || model === 'diagnostic'}
                    data={accumulatedData}
                    clientPreChoices={clientPreChoices}
                    setClientPreChoices={setClientPreChoices}
                  />
                )
              }
            </HStack>
            {
              accumulatedData[index] && (
                <Box>
                  <Flex
                    mb={5}
                    w="100%"
                    justify={!shouldConsiderExamMode || finishedState ? 'flex-end' : 'space-between'}
                  >
                    <ExamModeSwitch
                      active={prefersExamMode}
                      disabled={alreadyStarted}
                      hide={!shouldConsiderExamMode || finishedState || !isTodayExtended()}
                      onChange={handleSetPrefersExamMode}
                    />
                    <Flex w="100%" justify="flex-end" align="center">
                      <FullscreenQuestionMenu
                        show={isFullscreen}
                        focusTimeSpent={focusTimeSpent}
                        isNavigatorVisible={isNavigatorVisible}
                        setIsNavigatorVisible={setIsNavigatorVisible}
                      />
                    </Flex>
                  </Flex>
                  <Grid templateColumns="repeat(12, 1fr)" gap={2} transition="all 0.4s">
                    <GridItem colSpan={{ base: 12, md: shouldHideNavigator ? 12 : 9 }}>
                      {
                        accumulatedData[index].isEssay
                          ? (
                            <EssayQuestion
                              {...questionCardProps}
                              preEssay={currentPreChoice}
                              activityId={activityId}
                              essay={essays[index]}
                              onSubmitAnswer={handleSubmitEssayAnswer}
                              onSaveEssay={handleSaveEssay}
                            />
                          )
                          : (
                            <QuestionCard
                              {...questionCardProps}
                              onPrev={onPrev}
                              onNext={onNext}
                              selectedChoice={selectedChoice}
                              setSelectedChoice={setSelectedChoice}
                              isNavigatorVisible={isNavigatorVisible}
                              isFullscreen={isFullscreen}
                              preChoice={currentPreChoice}
                              onSelectChoice={handleSelectChoice}
                              showMoreOptions={!isDiagnosticRoute}
                              onAnswerClick={handleAnswerClick}
                            />
                          )
                      }
                      <Flex
                        w="100%"
                        justify={{ base: 'center', md: 'flex-end' }}
                        mb={isFullscreen ? 20 : undefined}
                      >
                        <ButtonNavigator
                          handleNext={onNext}
                          handlePrev={onPrev}
                          isNextDisabled={
                            (index === visibleIndexes[visibleIndexes.length - 1]) || !visibleIndexes.length
                          }
                          isPrevDisabled={index === visibleIndexes[0] || !visibleIndexes.length}
                        />
                      </Flex>
                      {
                        forumDisclosure && (
                          <Forum
                            show={showForum || (isViewMode && !answeredAny)}
                            id={accumulatedData[index]._id}
                            tag={[
                              'Questão',
                              accumulatedData[index].classification.join('|') || 'Sem classificação',
                            ].join('|')}
                          />
                        )
                      }
                      <FinishButton
                        device="mobile"
                        modelName={modelName}
                        isBlinking={model === 'diagnostic' && finishedState}
                        label={model === 'diagnostic' && finishedState ? 'Meu diagnóstico' : undefined}
                        isLoading={loading}
                        show={
                          (!isQuestionsDefaultMode && !finishedState)
                          && model !== 'diagnostic'
                          && !isFavoritesModel
                        }
                        disabled={!areAllQuestionsChosen || isDisabled}
                        onClick={onFinishButtonClick}
                      />
                    </GridItem>
                    {
                      !shouldHideNavigator && (
                        <GridItem colSpan={3}>
                          <FinishButton
                            device="web"
                            show={model === 'diagnostic'}
                            mb={3}
                            mt={0}
                            isBlinking={model === 'diagnostic' && finishedState}
                            label={model === 'diagnostic' && finishedState ? 'Meu diagnóstico' : undefined}
                            modelName={modelName}
                            disabled={!areAllQuestionsChosen || isDisabled}
                            isLoading={loading}
                            onClick={onFinishButtonClick}
                          />
                          <Counter
                            device="web"
                            showCounter={showCounter}
                            startedAt={startedAt}
                            timeToDo={timeToDo}
                          />
                          <QuestionIndexNavigator
                            bgColorProp={questionCardProps.bgColorProp}
                            device="web"
                            favoritesSkip={favoritesSkip}
                            setFavoritesSkip={setFavoritesSkip}
                            accumulatedData={accumulatedData}
                            setAccumulatedData={setAccumulatedData}
                            refetchFavorites={refetchFavorites}
                            model={model}
                            isFullscreen={isFullscreen}
                            // hidePrintButton={isFavoritesModel}
                            hideExamFeedbackButton={isQuestionsDefaultMode
                              || isFavoritesModel
                              || finishedState
                              || isViewMode
                              || !isTodayExtended()}
                            setClientPreChoices={setClientPreChoices}
                            hiddenIndexes={hiddenIndexes}
                            clientPreChoices={clientPreChoices}
                            getNavColors={getNavColors}
                            onClick={onIndex}
                            toggleFullscreen={toggleFullscreen}
                          />
                          <Results
                            device="web"
                            hide={hideResults || isFavoritesModel}
                            result={result}
                          />
                          <FinishButton
                            device="web"
                            show={
                              (!isQuestionsDefaultMode && !finishedState)
                              && model !== 'diagnostic'
                              && !isFavoritesModel
                            }
                            mb={isFullscreen && shouldHideNavigator ? 10 : 0}
                            isBlinking={model === 'diagnostic' && finishedState}
                            label={model === 'diagnostic' && finishedState ? 'Meu diagnóstico' : undefined}
                            modelName={modelName}
                            disabled={!areAllQuestionsChosen || isDisabled}
                            isLoading={loading}
                            onClick={onFinishButtonClick}
                          />
                          <RankingInfoCard
                            device="web"
                            hide={!isMocksModel}
                            finishedState={finishedState}
                            answersAt={answersAt}
                            rankingAt={rankingAt}
                          />
                        </GridItem>
                      )
                    }
                  </Grid>
                </Box>
              )
            }
          </Box>
        </Flex>
      </FullScreen>
    </Box>
  );
};
