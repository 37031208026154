import { gql, QueryResult, useQuery } from '@apollo/client';

export interface InstitutionExam {
  year: number;
  template: string;
  complement: string;
  examType: string;
}
export interface Institution {
  name: string;
  exams: InstitutionExam[];
}
export interface InstitutionList {
  uf: string;
  institutions: Institution[];
}

interface GetInstitutionListOutput {
  getInstitutionList: InstitutionList[];
}

interface GetInstitutionListDiagnosticInput {
  hasRecentExamsOnly?: boolean;
}
interface GetInstitutionListDiagnosticOutput {
  getInstitutionListForDiagnostic: InstitutionList[];
}

const GET_INSTITUTION_LIST = gql`
  query GetInstitutionList {
    getInstitutionList {
      uf
      institutions {
        name
        exams {
          year
          template
          complement
          examType
        }
      }
    }
  }
`;

export function useGetInstitutionList(): QueryResult<GetInstitutionListOutput> {
  return useQuery<GetInstitutionListOutput>(GET_INSTITUTION_LIST);
}

const GET_INSTITUTION_LIST_DIAGNOSTIC = gql`
  query GetInstitutionListForDiagnostic (
    $hasRecentExamsOnly: Boolean
  ) {
    getInstitutionListForDiagnostic (
      hasRecentExamsOnly: $hasRecentExamsOnly
    ) {
      uf
      institutions {
        name
        exams {
          year
          template
          complement
          examType
        }
      }
    }
  }
`;

export function useGetInstitutionListForDiagnostic() {
  return useQuery<
    GetInstitutionListDiagnosticOutput, GetInstitutionListDiagnosticInput
  >(GET_INSTITUTION_LIST_DIAGNOSTIC, {
    variables: {
      hasRecentExamsOnly: true,
    },
  });
}
