import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

export interface Graduation {
  institution: string;
  uf: string;
  yearConclusion: string;
  desiredExpertise: string;
  isGraduated: boolean;
}

export interface ProfileWork {
  inHealthArea: boolean;
  placeOfWork: string;
  weeklyHours: string;
}
export interface InstitutionTarget {
  uf: string;
  institution: string;
}

export interface StudyWeekSchema {
  theoreticalStudy: boolean;
  theoreticalReview: boolean;
  smartReview: boolean;
  examAndMocks: boolean;
}

export interface StudyWeek {
  sunday: StudyWeekSchema;
  monday: StudyWeekSchema;
  tuesday: StudyWeekSchema;
  wednesday: StudyWeekSchema;
  thursday: StudyWeekSchema;
  friday: StudyWeekSchema;
  saturday: StudyWeekSchema;
}

export interface CourseObjectType {
  course: string;
  userCourse: string;
  classificationTree: string;
  status: string;
  studyWeek: StudyWeek;
  institutionTarget?: InstitutionTarget[];
  detailedClassificationTree: string;
}

export type Role = 'admin' | 'commentator' | 'curator' | 'super-admin';
export interface Profile {
  _id: string;
  name: string;
  email: string;
  courseFinished: boolean;
  courses: CourseObjectType[];
  graduation: Graduation;
  questionnaireAnswered: boolean;
  institutionTarget: InstitutionTarget[];
  studyWeek: StudyWeek;
  mobilePhone: string;
  uid: string;
  createdAt: string;
  updatedAt: string;
  gender: 'f' | 'm';
  work: ProfileWork;
  birthday: string;
  roles?: string[];
  trialFinished?: boolean;
}

export interface ProfileOutput {
  profile: Profile;
}

const studyWeekFragment = `
  studyWeek {
    sunday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    monday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    tuesday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    wednesday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    thursday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    friday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    saturday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
  }
`;

const institutionTargetFragment = `
  institutionTarget {
    uf
    institution
  }`;

export const PROFILE = gql`
  query Profile {
    profile {
      _id
      name
      email
      courseFinished
      courses {
        course
        userCourse
        classificationTree
        detailedClassificationTree
        ${studyWeekFragment}
        ${institutionTargetFragment}
        status
      }
      graduation {
        institution
        uf
        yearConclusion
        desiredExpertise
        isGraduated
      }
      questionnaireAnswered
      ${institutionTargetFragment}
      gender
      work {
        inHealthArea
        placeOfWork
        weeklyHours
      }
      ${studyWeekFragment}
      mobilePhone
      uid
      createdAt
      updatedAt
      birthday
      roles
      trialFinished
    }
  }
`;

const GET_PROFILE_COURSES = gql`
  query GetProfileCourses {
    profile {
      courses {
        course
      }
    }
  }
`;

type ProfileCoursesOutputType = {
  profile: {
    courses: CourseObjectType[],
  }
}

export function useGetProfileCourses() {
  return useQuery<ProfileCoursesOutputType>(GET_PROFILE_COURSES);
}

interface UpdateProfileInputInterface {
  name?: string;
  graduation?: Graduation;
  questionnaireAnswered?: boolean;
  gender?: 'f' | 'm';
  institutionTarget?: InstitutionTarget[];
  studyWeek?: StudyWeek;
  birthday?: Date;
  work?: ProfileWork;
}

interface UpdateProfileInput {
  input: UpdateProfileInputInterface;
}

interface UpdateProfileOutput {
  updateProfile: Profile;
}

const UPDATE_PROFILE = gql`
  mutation UpdateProfile ($input: ProfileUpdateInputType!) {
    updateProfile (input: $input) {
      _id
      name
      email
      courses {
        course
        userCourse
        classificationTree
        detailedClassificationTree
        ${studyWeekFragment}
        ${institutionTargetFragment}
        status
      }
      graduation {
        institution
        uf
        yearConclusion
        desiredExpertise
        isGraduated
      }
      questionnaireAnswered
      institutionTarget {
        uf
        institution
      }
      ${studyWeekFragment}
      ${institutionTargetFragment}
      gender
      work {
        inHealthArea
        placeOfWork
        weeklyHours
      }
      mobilePhone
      uid
      createdAt
      updatedAt
      birthday
      trialFinished
    }
  }
`;

interface UseProfileInterface {
  onUpdateProfile: (props: UpdateProfileInputInterface) => Promise<Profile | undefined>;
  onSwapCourse: (course: string) => Promise<boolean | undefined>;
}

interface SwapCurrentCourseInput {
  course: string;
}

interface SwapCurrentCourseOutput {
  swapCurrentCourse: boolean;
}

const SWAP_CURRENT_COURSE = gql`
  mutation SwapCurrentCourse($course: ObjectId!) {
    swapCurrentCourse(course: $course)
  }
`;

export function useProfile(): UseProfileInterface {
  const [updateProfile] = useMutation<UpdateProfileOutput, UpdateProfileInput>(UPDATE_PROFILE);
  const [swapCurrentCourse] = useMutation<SwapCurrentCourseOutput, SwapCurrentCourseInput>(SWAP_CURRENT_COURSE);

  const handleUpdateProfile = useCallback(async ({
    name,
    graduation,
    questionnaireAnswered,
    gender,
    institutionTarget,
    studyWeek,
    birthday,
    work,
  }: UpdateProfileInputInterface) => {
    const res = await updateProfile({
      variables: {
        input: {
          name,
          graduation,
          questionnaireAnswered,
          gender,
          institutionTarget,
          studyWeek,
          birthday,
          work,
        },
      },
    });
    return res.data?.updateProfile;
  }, [updateProfile]);

  const handleSwapCurrentCourse = useCallback(async (course: string) => {
    const res = await swapCurrentCourse({
      variables: {
        course,
      },
    });
    return res.data?.swapCurrentCourse;
  }, [swapCurrentCourse]);

  return {
    onUpdateProfile: handleUpdateProfile,
    onSwapCourse: handleSwapCurrentCourse,
  };
}
