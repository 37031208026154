import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { StarsRating } from './StarsRating.component';

type RatingModalType = {
  isOpen: boolean,
  onClose: () => void,
  setIsRated: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLiked: React.Dispatch<React.SetStateAction<boolean>>,
  targetURL: string,
}

export const RatingModal: FC<RatingModalType> = ({
  isOpen, onClose, setIsRated, setIsLiked, targetURL,
}: RatingModalType) => {
  const { modal } = useDefaultStyles();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        mx={4}
        borderRadius={10}
        overflow="hidden"
      >
        <ModalHeader
          py={2}
          display="flex"
          alignItems="center"
          // bgColor={modal.header.bgColor}
          color={modal.header.color}
        >
          <Text margin="auto">
            Avalie este comentário
          </Text>
        </ModalHeader>
        <ModalBody justify="center">
          <VStack justify="center">
            <Text textAlign="center" fontSize="sm">Avalie com cautela. Esta ação é irreversível!</Text>
            <StarsRating
              setIsRated={setIsRated}
              setIsLiked={setIsLiked}
              onClose={onClose}
              targetURL={targetURL}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>

  );
};
