import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faArrowRight, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useDevice } from '../../hooks/useDevice';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { PrivateContext } from '../../Private.context';
import CourseSelectionModal from './CourseSelectionModal';

export const TrialEndedModal: FC = () => {
  const { profile: { trialFinished, courseFinished, courses } } = useContext(PrivateContext);
  const device = useDevice();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: modalIsOpen, onClose: modalOnClose, onOpen: modalOnOpen } = useDisclosure();

  const shouldOpenModal = useMemo(() => {
    return (trialFinished || courseFinished) ?? false;
  }, [courseFinished, trialFinished]);

  const { content, link, title } = useMemo(() => {
    if (courseFinished) {
      return {
        title: 'Que pena!',
        content: [
          'O seu acesso completo ao nosso curso de 2022 chegou ao fim.',
          `Para continuar aproveitando tudo o que a Aristo pode oferecer,
           clique no botão abaixo e renove a sua matrícula.`,
          'Estamos torcendo por você!',
        ],
        link: 'https://pages.aristo.com.br/inscricao/renovacao/?utm_source=plataforma&utm_content=acesso-expirado',
      };
    }
    return {
      title: 'Chegou a hora!',
      content: [
        'A Aristo Experience 2023 chegou ao fim.',
        `Para continuar aproveitando tudo o que a Aristo pode oferecer basta clicar no botão
        abaixo para efetivar a sua matrícula.`,
      ],
      link: `https://pages.aristo.com.br/extensivo/vendas/?utm_source=plataforma&utm_medium=
      &utm_content=&utm_term={{ad.name}}&utm_campaign=aristo-exp-2023`,
    };
  }, [courseFinished]);

  useEffect(() => {
    modalOnOpen();
  }, [modalOnOpen, onOpen]);

  return (
    <Modal
      isOpen={shouldOpenModal && modalIsOpen}
      onClose={modalOnClose}
      isCentered
    // closeOnOverlayClick={false}
    // closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent maxW={700} w="100%" p={4} m={2}>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexFlow={{ base: 'column', md: 'row' }} align="center">
            <Image
              src={`/images/aristo-design/${device === 'mobile' ? 'p1' : 'p2'}.svg`}
              width="12rem"
              m={4}
            />
            <VStack textAlign="center">
              <Text fontSize="2xl" fontWeight="bold">{title}</Text>
              {
                content.map(x => <Text key={Math.random()}>{x}</Text>)
              }
              <PrimaryButton
                maxW="400px"
                w="100%"
                onClick={() => {
                  window.open(
                    link,
                    '_blank',
                  );
                }}
                mt={2}
                rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
              >
                QUERO CONTINUAR COM A ARISTO
              </PrimaryButton>
              {courses.length > 1 && (
                <>
                  <SecondaryButton
                    maxW="400px"
                    w="100%"
                    rightIcon={<FontAwesomeIcon icon={faExchangeAlt} />}
                    onClick={onOpen}
                  >
                    Trocar de curso
                  </SecondaryButton>
                  <CourseSelectionModal isOpen={isOpen} onClose={onClose} />
                </>
              )}
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
