import { Center, Flex, VStack } from '@chakra-ui/react';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ForumContext } from '../../../../contexts/Forum.context';
import { useForumAPI } from '../../../../hooks/useForumAPI';
import { Loading } from '../../../../lib/components/Loading';
import { ForumCommentWithAnswersType } from '../types/forum.types';
import { generateCommentData } from '../../../../utils/forumUtils';
import { ForumComment } from './ForumComment.component';
import { PrivateContext } from '../../../../Private.context';

export const ForumCommentWithAnswers: FC<ForumCommentWithAnswersType> = ({
  commentData, forumUserIdMap, forumId, redirectUrl, commentOfQuestion,
}: ForumCommentWithAnswersType) => {
  const { setComments, answersCursorShouldResetFlag } = useContext(ForumContext);
  const { forumCredentials } = useContext(PrivateContext);
  const [showAnswers, setShowAnswers] = useState(false);
  const { getAnswersByFirstLevelCommentId } = useForumAPI();
  const [loadingMoreSecondLevelComments, setLoadingMoreSecondLevelComments] = useState(false);

  useEffect(() => {
    setShowAnswers(false);
  }, [answersCursorShouldResetFlag]);

  const refetchAnswers = useCallback(async () => {
    const res = await getAnswersByFirstLevelCommentId({ firstLevelCommentId: commentData.commentId });
    setComments(prev => {
      return prev.map(firstLevelComment => {
        if (firstLevelComment._id === commentData.commentId) {
          return { ...firstLevelComment, commentAnswers: res };
        } return firstLevelComment;
      });
    });
    setLoadingMoreSecondLevelComments(false);
  }, [commentData.commentId, getAnswersByFirstLevelCommentId, setComments]);

  return (
    <Flex flexFlow="column" w="100%">
      <ForumComment
        setShowAnswers={setShowAnswers}
        showAnswers={showAnswers}
        setLoadingMoreSecondLevelComments={setLoadingMoreSecondLevelComments}
        refetchAnswers={refetchAnswers}
        comment={commentData}
        redirectUrl={redirectUrl}
        commentOfQuestion={commentOfQuestion}
      />
      <VStack
        display={!showAnswers ? 'none' : undefined}
        pl={{ base: 5, md: 10 }}
        align="flex-end"
      >
        {commentData.answers && commentData.answers
          .sort((a, b) => {
            return moment(a['Created Date']) > moment(b['Created Date']) ? 1 : -1;
          })
          .map(commentAnswer => {
            return (
              <ForumComment
                key={commentAnswer._id}
                comment={generateCommentData({
                  comment: commentAnswer,
                  forumUserIdMap,
                  forumId,
                  userId: forumCredentials?.userId || '',
                })}
                redirectUrl={redirectUrl}
                commentOfQuestion={commentOfQuestion}
              />
            );
          })}
        {loadingMoreSecondLevelComments && (
          <Center w="100%" fontSize="sm">
            <Loading />
          </Center>
        )}
      </VStack>
    </Flex>
  );
};
