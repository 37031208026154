import { PreChoiceInterface } from '../api/agenda/mock-exam';
import { Question } from '../api/agenda/questions';
import { ThemeColorsType, ThemeColorType } from '../lib/theme';

export type colorCustomPropsType = {
  index: number;
  data: Question[];
  answers: number[];
  showResults: boolean;
  themeColors: ThemeColorsType;
  clientPreChoices: PreChoiceInterface[];
  isExamMode: boolean;
  doubtQuestionIds: string[];
  colorMode: 'dark' | 'light';
}

type getNavColorsProps = {
  ind: number;
  customProps: colorCustomPropsType;
}

export type NavigatorItemStyle = {
  bgColor: ThemeColorType,
  textColor: ThemeColorType,
  borderColor: ThemeColorType,
}

export const getNavColorsUtil = (
  {
    ind,
    customProps,
  }: getNavColorsProps,
): NavigatorItemStyle => {
  const {
    data,
    index,
    answers,
    showResults,
    clientPreChoices,
    themeColors,
    isExamMode,
    colorMode,
  } = customProps;

  const item = data[ind];
  const {
    green,
    aristo,
    red,
    white,
    gray,
  } = themeColors;

  const clientPreChoice = clientPreChoices.find(i => i.questionId === item?._id);
  const isDarkMode = colorMode === 'dark';

  const styles = {
    correct: {
      bgColor: isDarkMode ? green[900] : green[100],
      textColor: isDarkMode ? green[500] : green[500],
      borderColor: isDarkMode ? green[500] : green[500],
    },
    incorrect: {
      bgColor: isDarkMode ? red[900] : red[100],
      textColor: isDarkMode ? red[500] : red[500],
      borderColor: isDarkMode ? red[500] : red[500],
    },
    chosen: {
      bgColor: isDarkMode ? aristo[800] : aristo[200],
      textColor: isDarkMode ? aristo[500] : aristo[600],
      borderColor: isDarkMode ? aristo[500] : aristo[600],
    },
    selected: {
      bgColor: isDarkMode ? aristo[600] : aristo[500],
      textColor: white,
      borderColor: isDarkMode ? aristo[600] : aristo[500],
    },
    standard: {
      bgColor: 'transparent',
      textColor: isDarkMode ? gray[400] : gray[700],
      borderColor: isDarkMode ? gray[400] : gray[700],
    },
  };

  const correctChoiceSelected = (clientPreChoice && (
    item?.correctChoice === clientPreChoice.selected
    || item?.correctChoice === -1
  ));
  const correctEssayQuestion = (item?.isEssay && (answers[ind] === 1));
  const wrongEssayQuestion = (item?.isEssay && (answers[ind] === 0));
  const correctOrCancelledChoiceQuestion = !item?.isEssay
    && (item?.correctChoice === answers[ind] || item?.correctChoice === -1);
  const isQuestionAnswered = answers[ind] !== -1;

  if (ind === index) {
    return styles.selected;
  }

  if (showResults
    && ((isQuestionAnswered && (correctEssayQuestion || correctOrCancelledChoiceQuestion))
      || (isExamMode && correctChoiceSelected && !wrongEssayQuestion))) {
    return styles.correct;
  }

  if (showResults && (wrongEssayQuestion || (!item?.isEssay && !correctOrCancelledChoiceQuestion))) {
    return styles.incorrect;
  }

  if ((!showResults || item?.isEssay) && clientPreChoice) {
    return styles.chosen;
  }

  return styles.standard;
};
