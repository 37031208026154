import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import { DocTypes } from './agenda/agenda';
import { Flashcard } from './agenda/flashcards';
import { Mindmap } from './agenda/mindmaps';
import { Question } from './agenda/questions';
import { Video } from './agenda/videos';
import { Text } from './agenda/texts';

const VIDEO_MANY_FAVORITE = gql`
  query VideoManyFavorite {
    videoManyFavorite {
        _id
        url
        title
        subtitle
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
    }
  }
`;

const TEXT_MANY_FAVORITE = gql`
  query TextManyFavorite {
    textManyFavorite {
        _id
        url
        title
        subtitle
        description
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
     }
    }
`;

const QUESTION_MANY_FAVORITE = gql`
   query QuestionManyFavorite (
    $skip: Int
    $limit: Int
    ) {
    questionManyFavorite (
      skip: $skip
      limit: $limit
    )  {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        isEssay
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
       }
    }
`;

const MINDMAP_MANY_FAVORITE = gql`
  query MindmapManyFavorite {
    mindmapManyFavorite {
        _id
        url
        description
        title
        subtitle
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
     }
    }
`;

const FLASHCARD_MANY_FAVORITE = gql`
  query FlashcardManyFavorite {
    flashcardManyFavorite {
        _id
        front
        back
        classification
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
     }
    }

`;

interface FavoriteOneInput {
  document: string;
  type: DocTypes;
}

interface FavoriteOneOutput {
  favoriteOne: boolean;
}

const FAVORITE_ONE = gql`
  query FavoriteOne (
    $document: ObjectId!
    $type: ContentType!
  ) {
    favoriteOne (
      document: $document
      type: $type
    )
  }
`;

interface FavoritesInput {
  document: string;
  type: DocTypes;
  enabled: boolean;
}

interface FavoriteCreateOrDeleteInput {
  input: FavoritesInput;
}

interface FavoriteCreateOrDeleteOutput {
  favoriteCreateOrDelete: boolean;
}

const FAVORITE_CREATE_OR_DELETE = gql`
  mutation FavoriteCreateOrDelete ($input: FavoriteInputType!) {
    favoriteCreateOrDelete (input: $input)
  }
`;

interface UseFavoritesInterface {
  onFavoriteCreateOrDelete: (props: FavoritesInput) => Promise<boolean>;
}

export function useFavorites(): UseFavoritesInterface {
  const [createOrDelete] = useMutation<
    FavoriteCreateOrDeleteOutput, FavoriteCreateOrDeleteInput
  >(FAVORITE_CREATE_OR_DELETE);

  const handleCreateOrDelete = useCallback(async ({
    document,
    enabled,
    type,
  }: FavoritesInput) => {
    const res = await createOrDelete({
      variables: {
        input: {
          document,
          enabled,
          type,
        },
      },
    });
    return res.data?.favoriteCreateOrDelete || false;
  }, [createOrDelete]);

  return {
    onFavoriteCreateOrDelete: handleCreateOrDelete,
  };
}

type VideoManyFavoriteOutputType = {
  videoManyFavorite: Video[];
}

type TextManyFavoriteOutputType = {
  textManyFavorite: Text[];
}

export type QuestionManyFavoriteOutputType = {
  questionManyFavorite: Question[];
}

export type SkipLimitInputType = {
  skip?: number;
  limit?: number;
}

type MindmapManyFavoriteOutputType = {
  mindmapManyFavorite: Mindmap[];
}

type FlashcardManyFavoriteOutputType = {
  flashcardManyFavorite: Flashcard[];
}

export function useVideoManyFavorite(): QueryResult<VideoManyFavoriteOutputType> {
  return useQuery<VideoManyFavoriteOutputType>(VIDEO_MANY_FAVORITE, {
    fetchPolicy: 'network-only',
  });
}

export function useTextManyFavorite(): QueryResult<TextManyFavoriteOutputType> {
  return useQuery<TextManyFavoriteOutputType>(TEXT_MANY_FAVORITE, {
    fetchPolicy: 'network-only',
  });
}

export function useQuestionManyFavorite({ skip, limit }: SkipLimitInputType) {
  return useQuery<QuestionManyFavoriteOutputType, SkipLimitInputType>(QUESTION_MANY_FAVORITE, {
    fetchPolicy: 'network-only',
    variables: {
      skip,
      limit,
    },
  });
}

export function useMindmapManyFavorite(): QueryResult<MindmapManyFavoriteOutputType> {
  return useQuery<MindmapManyFavoriteOutputType>(MINDMAP_MANY_FAVORITE, {
    fetchPolicy: 'network-only',
  });
}

export function useFlashcardManyFavorite(): QueryResult<FlashcardManyFavoriteOutputType> {
  return useQuery<FlashcardManyFavoriteOutputType>(FLASHCARD_MANY_FAVORITE, {
    fetchPolicy: 'network-only',
  });
}

export function useFavoriteOne({ type, document }: FavoriteOneInput): QueryResult<FavoriteOneOutput, FavoriteOneInput> {
  return useQuery<FavoriteOneOutput, FavoriteOneInput>(FAVORITE_ONE, {
    variables: {
      document,
      type,
    },
  });
}
