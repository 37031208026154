import { useCallback, useEffect, useMemo, useState } from 'react';
import { Institution, InstitutionList, useGetInstitutionListForDiagnostic } from '../api/question-institutions';

export type ExamVariant = 'R1' | 'REVALIDA' | 'R3-CM';

export const useInstitutionsDiagnostic = () => {
  const [innerUf, setInnerUf] = useState('');
  const [examVariant, setExamVariant] = useState<ExamVariant>();
  const { data: rawData, loading } = useGetInstitutionListForDiagnostic();

  useEffect(() => {
    if (examVariant) {
      localStorage.setItem('@Aristoclass:examVariant', `"${examVariant}"`);
    }
  }, [examVariant]);

  const nameContainsRevalida = useCallback((item: Institution) => {
    return item.name.toLowerCase().includes('revalida');
  }, []);

  const nameDoesntContainR3CM = useCallback((item: Institution) => {
    return !item.name.includes('R3-CM');
  }, []);

  const filters = useMemo(() => {
    if (examVariant === 'REVALIDA') {
      return {
        ufs: (item: InstitutionList) => item.institutions.some(nameContainsRevalida),
        institutions: (item: Institution) => nameContainsRevalida(item),
      };
    }
    if (examVariant === 'R1') {
      return {
        ufs: () => true,
        institutions: (item: Institution) => !nameContainsRevalida(item),
      };
    }
    return {
      ufs: () => true,
      institutions: () => true,
    };
  }, [examVariant, nameContainsRevalida]);

  const data = useMemo(() => {
    if (rawData) {
      return rawData.getInstitutionListForDiagnostic;
    } return [];
  }, [rawData]);

  const UFS = useMemo(() => {
    return data
      .filter(filters.ufs)
      .map(item => item.uf)
      .sort((a, b) => (a < b ? -1 : 1));
  }, [data, filters.ufs]);

  const institutions = useMemo(() => {
    if (data && innerUf) {
      const find = data.find(item => item.uf === innerUf);
      if (find) {
        return find
          .institutions
          .filter(filters.institutions)
          .filter(nameDoesntContainR3CM)
          .map(item => item.name)
          .sort((a, b) => (a < b ? -1 : 1));
      }
    }
    return [];
  }, [data, filters.institutions, innerUf, nameDoesntContainR3CM]);

  const handleUf = useCallback(e => {
    setInnerUf(e.target.value);
  }, []);

  return {
    uf: innerUf,
    ufs: UFS,
    institutions,
    handleUf,
    setExamVariant,
    loading,
  };
};
