import { useCallback } from 'react';
import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { PreChoiceInterface } from '../agenda/mock-exam';

export type ExtraTypes = 'ActivityQuestion' | 'ActivityExam';

interface ActivityStatus {
  _id: string;
  finished: boolean;
  createdAt?: string;
  preChoice?: PreChoiceInterface[];
}

export interface Activity {
  _id: string;
  authorId: string;
  finished: boolean;
  createdAt: Date;
  updatedAt: Date;
  classification: string[];
}

export interface ActivityTheoreticalExtra extends ActivityStatus {
  classification: string[];
  contents: {
    videos?: string[],
    texts?: string[],
    mindmaps?: string[],
    flashcards?: string[],
    questions?: string[],
  };
  lessonId: string;
}

export interface ActivitySmartReviewExtra extends ActivityStatus {
  questions: string[];
  flashcards: string[];
  preChoice?: PreChoiceInterface[];
}

export interface ActivityFlashcardExtra extends ActivityStatus {
  classification: string[];
}

export interface ActivityExamExtra extends ActivityStatus {
  uf: string;
  institution: string;
  year: string;
}

export interface ActivitySmartExamExtra extends ActivityStatus {
  uf: string;
  institution: string;
}

export interface ActivityMockExamExtra extends ActivityStatus {
  title: string;
  subtitle: string;
}

export interface ActivitiesList {
  activityQuestion?: ActivityStatus[];
  activityFlashcard?: ActivityFlashcardExtra[];
  activityExamExtra?: ActivityExamExtra[];
  activityMockExamExtra?: ActivityMockExamExtra[];
  activitySmartExamExtra?: ActivitySmartExamExtra[];
  activityTheoreticalExtra?: ActivityTheoreticalExtra[];
  activityTheoreticalReviewExtra?: ActivityTheoreticalExtra[];
  activitySmartReviewExtra?: ActivitySmartReviewExtra[];
}

interface GetExtraActivitiesInput {
  calendarDate: string;
}

interface GetExtraActivitiesOutput {
  getExtraActivities: ActivitiesList;
}

const GET_EXTRA_ACTIVITIES = gql`
  query GetExtraActivities ($calendarDate: CalendarDate!) {
    getExtraActivities (calendarDate: $calendarDate) {
      activityQuestion {
        _id
        finished
        createdAt
        preChoice {
          questionId
          selected
          essay
        }
      }
      activityFlashcard{
        _id
        finished
        classification
        createdAt
      }
      activityExamExtra{
        _id
        finished
        uf
        institution
        year
        createdAt
      }
      activityMockExamExtra{
        _id
        finished
        title
        subtitle
        createdAt
      }
      activitySmartExamExtra{
        _id
        finished
        uf
        institution
        createdAt
      }
      activityTheoreticalExtra {
        _id
        classification
        finished
        lessonId
        contents {
          videos
          texts
          mindmaps
          flashcards
          questions
        }
        createdAt
      }
      activityTheoreticalReviewExtra {
        _id
        classification
        finished
        lessonId
        contents {
          videos
          texts
          mindmaps
          flashcards
          questions
        }
        createdAt
      }
      activitySmartReviewExtra {
        _id
        finished
        questions
        flashcards
        preChoice {
          questionId
          selected
          essay
        }
        createdAt
      }
    }
  }
`;

export interface FinishExtraActivityInput {
  _id: string;
}

interface FinishExtraActivityOutput {
  finishExtraActivity: boolean;
}

const FINISH_EXTRA_ACTIVITY = gql`
  mutation FinishExtraActivity ($_id: ObjectId!) {
    finishExtraActivity (_id: $_id)
  }
`;

interface DeleteExtraActivityInput {
  activityId: string;
}

interface DeleteExtraActivityOutput {
  deleteExtraActivity: boolean;
}

const DELETE_EXTRA_ACTIVITY = gql`
  mutation DeleteExtraActivity (
    $activityId: ObjectId!
  ) {
    deleteExtraActivity (
      activityId: $activityId
    )
  }
`;

interface UseExtraActivitiesInterface {
  onFinishExtraActivity: (props: FinishExtraActivityInput) => Promise<void>;
  onDeleteExtraActivity: (props: DeleteExtraActivityInput) => Promise<void>;
}

export function useExtraActivities(): UseExtraActivitiesInterface {
  const [finishActivity] = useMutation<FinishExtraActivityOutput, FinishExtraActivityInput>(FINISH_EXTRA_ACTIVITY);
  const [deleteActivity] = useMutation<DeleteExtraActivityOutput, DeleteExtraActivityInput>(DELETE_EXTRA_ACTIVITY);

  const handleFinishExtraActivity = useCallback(async ({ _id }: FinishExtraActivityInput) => {
    await finishActivity({
      variables: {
        _id,
      },
    });
  }, [finishActivity]);
  const handleDeleteExtraActivity = useCallback(async ({ activityId }: DeleteExtraActivityInput) => {
    await deleteActivity({
      variables: {
        activityId,
      },
    });
  }, [deleteActivity]);

  return {
    onFinishExtraActivity: handleFinishExtraActivity,
    onDeleteExtraActivity: handleDeleteExtraActivity,
  };
}

export function useGetExtraActivities({
  calendarDate,
}: GetExtraActivitiesInput): QueryResult<GetExtraActivitiesOutput, GetExtraActivitiesInput> {
  return useQuery<GetExtraActivitiesOutput, GetExtraActivitiesInput>(GET_EXTRA_ACTIVITIES, {
    variables: {
      calendarDate,
    },
    fetchPolicy: 'network-only',
  });
}

const GET_COURSE_EXTRA_ACTIVITIES = gql`
  query GetCourseExtraActivities {
    getCourseExtraActivities
  }
`;

type GetCourseExtraActivitiesOutputType = {
  getCourseExtraActivities: string[]
}

export function useGetCourseExtraActivities() {
  const courseExtras = useQuery<GetCourseExtraActivitiesOutputType>(GET_COURSE_EXTRA_ACTIVITIES);
  return {
    data: courseExtras.data?.getCourseExtraActivities,
  };
}
