import axios from 'axios';

const testEnv = process.env.REACT_APP_ENV === 'PROD' ? '/version-live' : '/version-test';

const api = axios.create({
  baseURL: `https://aristoacademy.com.br${testEnv}/api/1.1/`,
});

// api.defaults.headers.common.Authorization = `Bearer ${process.env.REACT_APP_FORUM_AUTH_TOKEN}`;

export default api;
