import React, { FC, useMemo } from 'react';
import FavoritesEmpty from '../types/FavoritesEmpty';
import AgendaTexts from '../types/AgendaTexts';
import { Text } from '../../../api/agenda/texts';
import { useTextManyFavorite } from '../../../api/favorites';
import { SkeletonLoading } from '../../_components/SkeletonLoading';

const FavoriteTexts: FC = () => {
  const { data, loading } = useTextManyFavorite();

  const texts = useMemo(() => {
    return data?.textManyFavorite || [] as Text[];
  }, [data?.textManyFavorite]);

  if (loading) {
    return (
      <SkeletonLoading />
    );
  }

  if (texts.length > 0) {
    return (
      <AgendaTexts
        data={texts}
      />
    );
  }

  return (
    <FavoritesEmpty />
  );
};

export default FavoriteTexts;
