/* eslint-disable global-require */
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getMessaging } from 'firebase/messaging';
import notificationConfig from './notifications.json';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const firebaseConfig = ((): Record<string, any> => {
  switch (process.env.REACT_APP_ENV) {
    case 'HOMOLOG':
      return require('./firebase-homolog.json');
    case 'PROD':
      return require('./firebase-prod.json');
    case 'DEV':
    default:
      return require('./firebase-dev.json');
  }
})();

if (!getApps().length) {
  initializeApp(firebaseConfig, 'main');
  initializeApp(notificationConfig, 'notifications');
}

export const app = getApp('main');
// export const firestore = firebase.firestore();
export const auth = getAuth(app);
// export const analytics = firebase.analytics();
export const database = getDatabase(app);
export const storage = getStorage(app);

export const notificationsApp = getApp('notifications');
export const messaging = getMessaging(notificationsApp);
// export const functions = app.functions('southamerica-east1');

auth.onAuthStateChanged(async next => {
  if (next && next.uid) {
    try {
      await auth.updateCurrentUser(next);
    } catch (error) {
      console.error(error);
    }
  }
});

// functions.useFunctionsEmulator('http://localhost:5001');

auth.languageCode = 'pt-br';
export default app;
