import {
  Avatar, AvatarBadge, Box, Button,
  Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text,
} from '@chakra-ui/react';
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useContext, useState } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import useFirebaseStorageImg from '../../hooks/useFirebaseStorageImg';
import { useProfileImg } from '../../hooks/useProfileImg';
import { PrivateContext } from '../../Private.context';
import { getBase64 } from '../../utils/file-utils';
import PersonalInformation from './profile-sections/PersonalInformation.section';
import StudyProfile from './profile-sections/StudyProfile.section';

const ProfilePage: FC = () => {
  const { profile } = useContext(PrivateContext);
  const { imageSrc, setImage } = useProfileImg(profile._id);
  const { uploadFromBase64 } = useFirebaseStorageImg();
  const { colors, colorPalette } = useDefaultStyles();

  const [loading, setLoading] = useState(false);

  const handleInputPhoto = useCallback(async e => {
    setLoading(true);
    try {
      const base64 = await getBase64(e.target.files[0]);
      if (base64) {
        await uploadFromBase64('/images/users', String(base64), profile._id);
        setImage(URL.createObjectURL(e.target.files[0]));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [profile._id, setImage, uploadFromBase64]);

  return (
    <Box maxWidth={800} margin="auto">
      <Box paddingY={10} align="center">
        <Box>
          <Button
            width={250}
            height={250}
            borderRadius="50%"
            variant="unstyled"
            _focus={{ border: 'none' }}
            position="relative"
          >
            <Avatar
              as="label"
              htmlFor="avatar"
              borderStyle="solid"
              borderWidth="6px"
              borderColor={colors.primary.goALittleLighter}
              borderRadius="50%"
              size="2xl"
              width={250}
              height={250}
              name={profile.name}
              src={imageSrc}
            >
              <AvatarBadge
                fontSize="lg"
                marginRight={6}
                marginBottom={6}
                boxSize="2.5rem"
                bg={colors.secondary.goDarker}
                border="none"
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin color="white" />
                ) : (
                  <FontAwesomeIcon icon={faPen} color="white" />
                )}
              </AvatarBadge>
            </Avatar>
            <Input
              type="file"
              id="avatar"
              name="avatar"
              accept="image/png, image/jpeg"
              hidden
              onChange={handleInputPhoto}
            />
          </Button>

          <Text marginTop={5} fontWeight="bold" fontSize="1.2rem" color={colors.primary.goMoreLighter}>
            {profile.name}
          </Text>
          <Text fontWeight="light" fontSize="sm" color={colors.darkGray.keep}>
            {profile.email}
          </Text>
        </Box>
      </Box>
      <Tabs size="md" colorScheme={colorPalette}>
        <TabList justifyContent="center">
          <Tab>
            Perfil de estudo
          </Tab>
          <Tab>
            Dados pessoais
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <StudyProfile />
          </TabPanel>
          <TabPanel>
            <PersonalInformation />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ProfilePage;
