import { AspectRatio, Box, Divider, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Video as VideoProps } from '../../../api/agenda/videos';
import { useUserClasses } from '../../../api/flashcards';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import Video from '../../../lib/components/Video';
import { CardsButton } from '../../flashcards-creation/_components/Buttons/CardsButton';
import { Forum } from '../../_components/Forum/Forum';
import { MoreOptions } from '../../_components/MoreOptions';
import Stars from '../../_components/Stars';
import FavoriteBtn from './FavoriteBtn.component';

interface PlaylistNavigatorProps {
  playlistData: VideoProps[];
}

export const PlaylistNavigator: FC<PlaylistNavigatorProps> = ({
  playlistData,
}: PlaylistNavigatorProps) => {
  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { colors, logo } = useDefaultStyles();
  const { getLessonIdByName } = useUserClasses();

  const lessonId = useMemo(() => {
    return getLessonIdByName({ title: playlistData[0].title });
  }, [getLessonIdByName, playlistData]);

  useEffect(() => {
    if (!query.get('id')) {
      query.set('id', playlistData[0]._id);
      history.replace({ search: String(query) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentVideoIndex = useMemo(() => {
    const index = playlistData.findIndex(v => v._id === query.get('id'));
    return index === -1 ? 0 : index;
  }, [playlistData, query]);

  const currentVideo = useMemo(() => playlistData[currentVideoIndex], [currentVideoIndex, playlistData]);

  return (
    <Box m="0 auto" w="100%">
      <Flex
        align="center"
        justify="space-between"
      >
        <Text my={2}>{`${currentVideoIndex + 1}/${playlistData.length}`}</Text>
        <Flex my={4} align="center" justify="space-between" gridGap={3}>
          <CardsButton lessonId={lessonId} />
          <Stars
            document={currentVideo._id}
            type="video"
          />
          <HStack>
            <FavoriteBtn
              document={currentVideo._id}
              type="video"
            />
            <MoreOptions
              contentId={currentVideo._id}
              type="video"
            />
          </HStack>
        </Flex>
      </Flex>
      {playlistData && playlistData.length === 0 ? (
        <Text>Nenhum vídeo nessa playlist =/</Text>
      ) : (
        <Box>
          {currentVideo && (
            <>
              <Video
                id={currentVideo._id}
                url={currentVideo.url}
              />
              {currentVideo.liveChatLink && (
                <Box w="100%" bgColor="light.100" h={500}>
                  <iframe
                    width="100%"
                    height="100%"
                    title="live chat"
                    src={`${currentVideo
                      .liveChatLink}&embed_domain=${process.env.REACT_APP_URL?.replace('https://', '')}`}
                  />
                </Box>
              )}
            </>
          )}
          <Divider />
          {playlistData && playlistData.length === 0 && <Text>Nenhum vídeo nessa playlist=/</Text>}
          {playlistData.length > 1 && (
            <VStack align="start" w="100%" px={2} py={4} spacing={4} overflow="auto">
              {playlistData.map((video, i) => (
                <HStack
                  w="100%"
                  role="group"
                  as="button"
                  transition="all 0.6s"
                  align="center"
                  bgColor={currentVideoIndex === i ? colors.lighter.goAlmostFullDarker : ''}
                  _hover={{ bgColor: colors.alpha[400] }}
                  key={video._id}
                  p={2}
                  onClick={() => {
                    query.set('id', video._id);
                    history.replace({ search: String(query) });
                  }}
                >
                  <Text
                    alignSelf="center"
                    minW={4}
                    color={currentVideoIndex === i ? colors.secondary.keep : ''}
                  >
                    {currentVideoIndex === i ? <FontAwesomeIcon icon={faPlay} /> : (i + 1)}
                  </Text>
                  <AspectRatio ratio={1920 / 1080} w="100%" maxW={{ base: '100px', md: '200px' }}>
                    <Flex
                      w="100%"
                      h="100%"
                      bgColor={colors.lightSecondary.goAlmostFullDarker}
                      position="relative"
                    >
                      <Flex
                        position="absolute"
                        w="70%"
                        left={0}
                        align="center"
                        justify="center"
                      >
                        <Image
                          w="100%"
                          opacity={0.7}
                          transition="opacity .4s"
                          _groupHover={{ opacity: 1 }}
                          src={logo('playlist-logo')}
                        />
                      </Flex>
                      <Flex
                        bgColor={colors.secondary.goDarker}
                        w="30%"
                        h="100%"
                        position="absolute"
                        right={0}
                      />
                    </Flex>
                    {/* <Image src="/images/thumb_Aristo.png" /> */}
                    {/* <Image src={logo} /> */}
                  </AspectRatio>
                  <Box py={2}>
                    <Text
                      textAlign="left"
                      fontWeight={currentVideoIndex === i ? 'bold' : ''}
                      color={currentVideoIndex === i ? colors.secondary.keep : ''}
                    >
                      {video.title}
                    </Text>
                  </Box>
                </HStack>
              ))}
            </VStack>
          )}
          {
            query.get('id') && (
              <Forum
                id={query.get('id') || ''}
                tag={['Video',
                  playlistData[currentVideoIndex].title,
                  playlistData[currentVideoIndex].subtitle]
                  .join('|')}
              />

            )
          }
        </Box>
      )}
    </Box>
  );
};
