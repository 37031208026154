import { Flex, Box } from '@chakra-ui/react';
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { BurgerMenuItemType, useFindBurgerMenu } from '../api/burger-menu';
import WeekMenu from '../views/agenda/_components/WeekMenu.component';
import { BottomMenu } from '../views/_components/Footer/BottomMenu';
import { Header } from '../views/_components/Header/Header.component';
import { SideMenu } from '../views/_components/Header/SideMenu';

const IGNORED_ROUTES = ['copa-aristo'];

interface HeaderContextType {
  setHeaderComponent: (h?: ReactNode) => void;
}

export const MainWrapperContext = createContext({} as HeaderContextType);

interface MainWrapperContextProviderProps {
  children?: ReactNode;
}

export function MainWrapperContextProvider({
  children,
}: MainWrapperContextProviderProps) {
  const [headerComponent, setHeaderComponent] = useState<ReactNode>(<WeekMenu />);

  const { data, loading } = useFindBurgerMenu();

  const rawBurgerItems = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    return data.findBurgerMenuItems;
  }, [data, loading]);

  const handleFindSubgroupsFromBurgerItems = useCallback((items: BurgerMenuItemType[]) => (
    items.reduce((acc, curr) => {
      acc[curr.subgroup] = Math.min(acc[curr.subgroup] ?? Infinity, curr.order);
      return acc;
    }, {} as Record<string, number>)
  ), []);

  const handleOrderBurgerSubgroups = useCallback((item: Record<string, number>) => {
    return [...Object.entries(item)]
      .sort(([subgroupA, orderA], [subgroupB, orderB]) => orderA - orderB)
      .map(([subgroup]) => subgroup);
  }, []);

  const burgerItems = useMemo(() => {
    if (!rawBurgerItems) {
      return undefined;
    }

    const subgroups = handleFindSubgroupsFromBurgerItems(rawBurgerItems);

    return handleOrderBurgerSubgroups(subgroups).map(subgroup => [
      subgroup,
      rawBurgerItems.filter(item => item.subgroup === subgroup).sort((a, b) => a.order - b.order),
    ]) as [string, BurgerMenuItemType[]][];
  }, [handleFindSubgroupsFromBurgerItems, handleOrderBurgerSubgroups, rawBurgerItems]);

  if (IGNORED_ROUTES.map(x => window.location.pathname.includes(x)).some(x => x)) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <MainWrapperContext.Provider value={{ setHeaderComponent }}>
      {window.location.pathname.includes('impressao')
        || window.location.pathname.includes('gabarito')
        ? (
          <Box m="0 auto">
            {children}
          </Box>
        ) : (
          <Box maxH="100vh" fontSize={{ base: 'sm', md: 'md' }}>
            <Header burgerItems={burgerItems}>
              {headerComponent ?? <WeekMenu />}
            </Header>
            <Flex
              as="main"
              h={{ base: 'calc(100vh - 3.5rem - 3.5rem)', md: 'calc(100vh - 5rem)' }}
              overflow="hidden"
            >
              <SideMenu items={burgerItems?.[0]?.[1]} isLoading={loading} />
              <Box
                px={{ base: 3, md: 8 }}
                pb={{ base: 8, md: 20 }}
                flex={1}
                overflowY="auto"
                overflowX="hidden"
              >
                <Box m="0 auto">
                  {children}
                </Box>
              </Box>
            </Flex>
            {/* <OnlineCounter /> */}
            <BottomMenu items={burgerItems?.[0]?.[1]} />
          </Box>
        )}
    </MainWrapperContext.Provider>
  );
}
