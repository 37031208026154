/* eslint-disable no-undef */
import { Box, Image, Text, Flex, Circle, HStack } from '@chakra-ui/react';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { Comment as CommentType } from '../../api/agenda/agenda';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import useFirebaseStorageImg from '../../hooks/useFirebaseStorageImg';
import { CardsButton } from '../flashcards-creation/_components/Buttons/CardsButton';
import { LikeDislike } from './LikeDislike';
import { MoreOptions } from './MoreOptions';
import AddExtraQuestions from './Question/AddExtraButton.component';

interface CommentProps {
  data: CommentType;
  id?: string;
  classification?: string[];
  showCommentElements?: boolean;
  bgColorProp?: string,
  colorProp?: string,
}

export function Comment({
  data, id, classification, showCommentElements = true, bgColorProp, colorProp,
}: CommentProps) {
  const { colors, cardBorder } = useDefaultStyles();
  const storage = useFirebaseStorageImg();
  const getImage = useCallback((uri: string) => storage.getURL(uri), [storage]);

  return (
    <Box
      p={4}
      py={6}
      position="relative"
      bgColor={bgColorProp}
      color={colorProp}
      {...cardBorder}
    >
      <Flex justify="space-between" mb={4}>
        <HStack align="center">
          <Circle
            size="40px"
            bgColor={colors.secondary.goALittleDarker}
            borderWidth="2px"
            borderColor={colors.primary.keep}
            color="light.50"
          >
            <FontAwesomeIcon icon={faGraduationCap} />
          </Circle>
          <Box>
            <Text fontSize="lg" fontWeight="bold">
              {data.title}
            </Text>
            {
              classification && classification.length > 1 && (
                <Text color={colors.secondary.goALittleDarker} fontSize="0.8rem">
                  {`${classification[classification.length - 1]} - ${classification[classification.length - 2]}`}
                </Text>
              )
            }
          </Box>
        </HStack>
      </Flex>

      {
        data.body && (
          <ReactMarkdown
            transformImageUri={getImage}
            renderers={{ image: (props): JSX.Element => <Image {...props} /> }}
            className="markdown-body"
          >
            {data.body}
          </ReactMarkdown>
        )
      }
      {
        showCommentElements && (
          <HStack
            marginTop={8}
            alignItems="center"
            justifyContent={classification && classification.length > 0 ? 'space-between' : 'flex-end'}
          >
            {
              classification && (
                <AddExtraQuestions classification={classification} />
              )
            }
            {id && (
              <HStack justifySelf="flex-end">
                <CardsButton />
                <LikeDislike
                  document={id}
                  type="comment"
                />
                <MoreOptions
                  contentId={id}
                  type="comment"
                />
              </HStack>
            )}
          </HStack>
        )
      }
    </Box>
  );
}
