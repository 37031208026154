import { Avatar, Box, Flex, HStack, Square, Text, Tooltip, useDisclosure, VStack } from '@chakra-ui/react';
import { faEllipsisH, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ForumContext } from '../../../../contexts/Forum.context';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { useDevice } from '../../../../hooks/useDevice';
import { useForumAPI } from '../../../../hooks/useForumAPI';
import { ForumCommentComponentType } from '../types/forum.types';
import { PrivateContext } from '../../../../Private.context';
import { formatName } from '../../../../utils/forumUtils';
import { toast } from '../../../../utils/toast';
import { InlineText } from '../../InlineText.component';
import { MoreOptionsModal } from '../../MoreOptionsModal.component';
import ConfirmModal from '../../../../lib/components/ConfirmModal';
import { IS_DEV_ENV } from '../Forum';
import { HeartLikeButton } from './HeartLike.component';
import { StarRatingButton } from './StarRatingButton.component';
import { RatingModal } from './RatingModal.component';
import { ZoomImg } from '../../../../lib/components/ZoomImg';
import { WholeAppContext } from '../../../agenda/WholeApp.context';

export const ForumComment: FC<ForumCommentComponentType> = ({
  comment, showAnswers, setShowAnswers, redirectUrl,
  refetchAnswers, setLoadingMoreSecondLevelComments, commentOfQuestion,
}: ForumCommentComponentType) => {
  const {
    isOpen: isRatingModalOpen,
    onOpen: onRatingModalOpen,
    onClose: onRatingModalClose,
  } = useDisclosure();
  const {
    isOpen: isMoreOptionsModalOpen,
    onOpen: onMoreOptionsModalOpen,
    onClose: onMoreOptionsModalClose,
  } = useDisclosure();
  const CONTENT_LENGTH_STEP = 400;
  const { colors } = useDefaultStyles();
  const {
    commentBoxRef,
    answerTargetComment,
    setAnswerTargetComment,
    setRemovedComments,
    removedComments,
    updateRemovedCommentsList,
    forumRef,
    forumDisclosure,
    setCurrCommentId,
  } = useContext(ForumContext);
  const { hideElementsThatTriggerModals } = useContext(WholeAppContext);

  const handleRatingStarClick = useCallback(() => {
    onRatingModalOpen();
    setCurrCommentId(comment.commentId);
  }, [comment.commentId, onRatingModalOpen, setCurrCommentId]);

  const device = useDevice();
  const [mouseOver, setMouseOver] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isRated, setIsRated] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const { forumCredentials } = useContext(PrivateContext);
  const [loadingDeleteComment, setLoadingDeleteComment] = useState(false);
  const { deleteComment, addLikeOnComment, removeLikeOnComment, changeCommentVisibility } = useForumAPI();
  const [contentVisibleLength, setContentVisibleLength] = useState(CONTENT_LENGTH_STEP);
  const [loadingChangeVisibility, setLoadingChangeVisibility] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (forumRef.current
        && !forumRef.current.contains(event.target as Node)
        && !forumDisclosure.isOpen
      ) {
        setAnswerTargetComment(undefined);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    const prevOnKeyDown = document.onkeydown;
    document.onkeydown = e => {
      if (e.code === 'Escape') {
        setAnswerTargetComment(undefined);
      }
    };
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.onkeydown = prevOnKeyDown;
    };
  }, [forumDisclosure.isOpen, forumRef, setAnswerTargetComment]);

  useEffect(() => {
    if (comment.liked && forumCredentials?.userId) {
      setIsLiked(comment.liked.includes(forumCredentials?.userId));
    }
  }, [comment.liked, forumCredentials?.userId]);

  useEffect(() => {
    if (comment.hasUserRated) {
      setIsRated(comment.hasUserRated);
    }
  }, [comment.hasUserRated]);

  useEffect(() => {
    if (comment.isPrivate) {
      setIsPrivate(comment.isPrivate);
    }
  }, [comment.isPrivate]);

  const likeCount = useMemo(() => {
    if (comment.liked && forumCredentials?.userId) {
      const likeInitialState = comment.liked.includes(forumCredentials?.userId);
      const likeCurrentState = isLiked;
      const delta = Number(likeCurrentState) - Number(likeInitialState);
      return comment.liked.length + delta;
    } return 0 + Number(isLiked);
  }, [comment.liked, forumCredentials?.userId, isLiked]);

  const answersCount = useMemo(() => {
    if (comment.answerIds) {
      return comment.answerIds.filter(answerId => !removedComments.map(y => y.id).includes(answerId)).length;
    } return 0;
  }, [comment.answerIds, removedComments]);

  const onShowAnswersClick = useCallback(async () => {
    if (setLoadingMoreSecondLevelComments) {
      setLoadingMoreSecondLevelComments(true);
    }
    if (setShowAnswers) {
      setShowAnswers(prev => {
        if (prev === false) {
          if (refetchAnswers) {
            refetchAnswers();
          }
        }
        return !prev;
      });
    }
  }, [refetchAnswers, setLoadingMoreSecondLevelComments, setShowAnswers]);

  const onVisibilityChangeClick = useCallback(async () => {
    setLoadingChangeVisibility(true);
    try {
      await changeCommentVisibility({
        commentId: comment.commentId,
        turnPrivate: !isPrivate,
      });
      setIsPrivate(prev => !prev);
    } catch (e) {
      console.error(e);
      toast.error({
        title: 'Não foi possível tornar o comentário privado.',
        description: 'Cheque sua conexão e tente novamente.',
      });
    } finally {
      setLoadingChangeVisibility(false);
    }
  }, [changeCommentVisibility, comment.commentId, isPrivate]);

  const handleLikeComment = useCallback(async () => {
    if (isLiked) {
      try {
        setIsLiked(false);
        await removeLikeOnComment({ commentId: comment.commentId });
      } catch (e) {
        setIsLiked(true);
        console.error(e);
        toast.error({
          title: 'Não foi possível descurtir o comentário.',
          description: 'Verifique sua conexão.',
        });
      }
    } else {
      try {
        setIsLiked(true);
        await addLikeOnComment({
          commentId: comment.commentId,
          targetURL: redirectUrl || window.location.origin,
        });
      } catch (e) {
        setIsLiked(false);
        console.error(e);
        toast.error({
          title: 'Não foi possível curtir o comentário.',
          description: 'Verifique sua conexão.',
        });
      }
    }
  }, [addLikeOnComment, comment.commentId, isLiked, redirectUrl, removeLikeOnComment]);

  const handleDeleteComment = useCallback(async () => {
    setLoadingDeleteComment(true);
    try {
      await deleteComment({
        commentId: comment.commentId,
        forumId: comment.forumId,
      });
      if (setRemovedComments) {
        setRemovedComments(prev => {
          const newRemovedComments = [...prev, { id: comment.commentId, isFirstLevel: comment.isFirstLevel }];
          updateRemovedCommentsList(newRemovedComments);
          return newRemovedComments;
        });
      }
    } catch (e) {
      toast.error({
        title: 'Não foi possível excluir o comentário.',
        description: 'Verifique sua conexão.',
      });
      console.error(e);
    } finally {
      setLoadingDeleteComment(false);
      onMoreOptionsModalClose();
    }
  }, [comment.commentId, comment.forumId, comment.isFirstLevel,
    deleteComment, onMoreOptionsModalClose, setRemovedComments, updateRemovedCommentsList]);

  const modalOptions = useMemo(() => {
    const visibilityOption = {
      label: isPrivate ? 'Tornar visível' : 'Tornar privado',
      onClick: onVisibilityChangeClick,
      color: isPrivate ? 'green' : 'orange',
    };
    const options = [
      { label: 'Excluir', onClick: onOpenDeleteModal, color: colors.red.keep },
      { label: 'Cancelar', onClick: onMoreOptionsModalClose },
    ];
    if (forumCredentials?.isSpecialist) {
      return [visibilityOption, ...options];
    }
    return options;
  }, [
    isPrivate, onVisibilityChangeClick, onOpenDeleteModal,
    colors.red.keep, onMoreOptionsModalClose, forumCredentials?.isSpecialist,
  ]);

  const isCurrCommentSelected = useMemo(() => {
    return answerTargetComment?.commentId === comment.commentId;
  }, [answerTargetComment?.commentId, comment.commentId]);

  const isCurrUserComment = useMemo(() => {
    return comment.userId === forumCredentials?.userId;
  }, [comment.userId, forumCredentials?.userId]);

  const haveAllContentBeenShown = contentVisibleLength >= comment.content.length;

  const commentContent = useMemo(() => {
    const isBeforeNewForumImplementation = moment(comment.createdAt).isBefore(moment('20220701'));
    if (isBeforeNewForumImplementation) {
      const withoutImgLinks = comment.content.replace(/\[img.*?\[\/img\]/g, ' ');
      const withoutBBCode = withoutImgLinks.replace(/\[(.*?)\]/g, '\n');
      const contractedComment = `${withoutBBCode
        .slice(0, contentVisibleLength)}${haveAllContentBeenShown ? '' : '...'}`;
      return contractedComment;
    }
    return `${comment.content
      .slice(0, contentVisibleLength)}${haveAllContentBeenShown ? '' : '...'}`;
  }, [comment.content, comment.createdAt, contentVisibleLength, haveAllContentBeenShown]);

  const handleAnswerClick = useCallback(() => {
    setAnswerTargetComment(comment);
    if (commentBoxRef && commentBoxRef.current) {
      commentBoxRef.current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      commentBoxRef.current.focus();
    }
  }, [comment, commentBoxRef, setAnswerTargetComment]);

  if (!forumDisclosure) {
    return null;
  }

  return (
    <Flex
      overflowWrap="break-word"
      flexFlow="row"
      justify="space-between"
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      py={2}
      px={2}
      borderRadius={10}
      transition="all 0.4s"
      bgColor={isCurrCommentSelected
        ? colors.lighter.goAlmostFullDarker
        : undefined}
    >
      <ConfirmModal
        title="Apagar este comentário?"
        icon={<FontAwesomeIcon icon="exclamation-circle" />}
        onDecline={() => {
          onCloseDeleteModal();
          onMoreOptionsModalClose();
        }}
        isOpen={isDeleteModalOpen}
        confirmText="Deletar"
        declineText="Cancelar"
        onConfirm={handleDeleteComment}
        loading={loadingDeleteComment}
        loadingText="Deletando..."
      >
        {`Deseja mesmo apagar o comentário de ${comment.userName}? Esta ação é irreversível!`}
      </ConfirmModal>
      <RatingModal
        setIsRated={setIsRated}
        setIsLiked={setIsLiked}
        targetURL={redirectUrl || window.location.origin}
        isOpen={isRatingModalOpen}
        onClose={onRatingModalClose}
      />
      <MoreOptionsModal
        options={modalOptions}
        disableAll={loadingDeleteComment || loadingChangeVisibility}
        isOpen={isMoreOptionsModalOpen}
        onClose={onMoreOptionsModalClose}
      />
      <HStack align="left" overflowWrap="break-word" w="calc(100% - 40px)">
        <Avatar
          size={device === 'web' ? 'md' : 'sm'}
          name={formatName(comment.userName)}
          src={comment.profileImage}
        />
        <VStack align="left" w="85%">
          <Box w="100%">
            <HStack
              spacing={0}
            >
              <InlineText
                fontWeight="bold"
                color={colors.black.goInvert}
                noOfLines={1}
              >
                {formatName(comment.userName, device)}
              </InlineText>
              {
                comment.specialist && (
                  <Box
                    display="inline"
                    fontSize={device === 'mobile' ? 'xx-small' : 'xs'}
                    color={colors.secondary.keep}
                    px={2}
                    borderRadius={10}
                    border="1px solid"
                    borderColor={colors.secondary.keep}
                  >
                    Moderação
                  </Box>
                )
              }
            </HStack>
            {
              comment.targetUserName && (
                <Text color={colors.secondary.keep} fontStyle="oblique" fontSize="xs">
                  {`Resposta a ${comment.targetUserName}`}
                </Text>
              )
            }
            {
              (comment.specialist || IS_DEV_ENV) ? (
                <ReactMarkdown
                  className="inline"
                  // eslint-disable-next-line no-undef
                  renderers={{ image: (props): JSX.Element => <ZoomImg {...props} /> }}
                >
                  {commentContent}
                </ReactMarkdown>
              ) : (
                <InlineText>
                  {commentContent}
                </InlineText>
              )
            }
            {
              !haveAllContentBeenShown && (
                <InlineText
                  as="button"
                  fontSize="xs"
                  color={colors.secondary.keep}
                  onClick={() => setContentVisibleLength(prev => prev + CONTENT_LENGTH_STEP)}
                >
                  (Ver mais)
                </InlineText>
              )
            }
          </Box>
          <HStack color="gray" align="left" fontSize="xs" w="100%" justify="left">
            {
              comment.createdAt && (
                <Text fontSize="xs">
                  {`${moment(comment.createdAt).fromNow()}`}
                </Text>
              )
            }
            {
              likeCount > 0 && (
                <Text fontStyle="italic">
                  {`${likeCount} curtida${likeCount > 1 ? 's' : ''}`}
                </Text>
              )
            }
          </HStack>
          <HStack color="gray" align="left" fontSize="xs" w="100%" justify="left">
            {
              (!commentOfQuestion && (
                <Text
                  as="button"
                  color={isCurrCommentSelected ? colors.secondary.keep : undefined}
                  fontWeight="bold"
                  onClick={handleAnswerClick}
                >
                  {isCurrCommentSelected ? 'Respondendo' : 'Responder'}
                </Text>
              )
              )
            }
            {
              answersCount > 0 && (
                <Flex align="left">
                  <Text as="button" fontWeight="bold" fontSize="xs" onClick={onShowAnswersClick}>
                    {showAnswers ? 'Ocultar respostas' : `Ver respostas (${answersCount})`}
                  </Text>
                </Flex>
              )
            }
            {
              (mouseOver || device === 'mobile')
              && (isCurrUserComment || forumCredentials?.isSpecialist || IS_DEV_ENV)
              && (!hideElementsThatTriggerModals)
              && (!commentOfQuestion)
              && (
                <Text px={2} as="button" fontSize="xs" onClick={onMoreOptionsModalOpen}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Text>
              )
            }
          </HStack>
        </VStack>
      </HStack>
      <Flex align="center" justify="center" w={10}>
        {
          isPrivate ? (
            <Tooltip label="Comentário privado" hasArrow>
              <Square size={2} color={colors.alpha[400]}>
                <FontAwesomeIcon icon={faEyeSlash} />
              </Square>
            </Tooltip>
          ) : (
            <>
              {
                (comment.specialist
                  && !comment.isFirstLevel
                  && !comment.hasUserRated
                  && !isRated
                  && !hideElementsThatTriggerModals)
                  ? (
                    <StarRatingButton
                      isRated={isRated}
                      onClick={handleRatingStarClick}
                    />
                  )
                  : (
                    <HeartLikeButton
                      isLiked={isLiked}
                      onClick={handleLikeComment}
                    />
                  )
              }
            </>
          )
        }
      </Flex>
    </Flex>
  );
};
