import React, { useCallback } from 'react';
import {
  ForumConsultOutputType, ForumUserDataType,
  ForumUserIdentificationType,
} from '../views/_components/Forum/types/forum.types';
import api from '../services/api';
import { getUserPicture } from '../utils/forumUtils';

export const COMMENT_PAGE_STEP = 5;
export const VISIBLE_ANSWERS_LIMIT = 5;

export const useForumAPI = () => {
  const getCredentialsByEmail = useCallback(async ({
    email,
    firebaseId,
    userName,
    aristoClassId,
  }: {
    email: string,
    firebaseId: string,
    userName: string,
    aristoClassId: string,
  }) => {
    const credentials = await api
      .post('wf/user_login', {
        email,
        firbaseid: firebaseId,
        nome: userName,
        aristoClassID: aristoClassId,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_FORUM_AUTH_TOKEN}`,
        },
      });
    return {
      userToken: credentials.data.response.token,
      userId: credentials.data.response.user_id,
      isSpecialist: credentials.data.response.isSpecialist,
    };
  }, []);

  const getUsersByIds = useCallback(async ({
    userIds,
  }: {
    userIds: string[],
  }) => {
    if (userIds.length > 0) {
      const users = await api
        .get('/obj/user/', {
          params: {
            constraints: JSON.stringify([
              {
                key: '_id',
                constraint_type: 'in',
                value: userIds.length === 0 ? [''] : userIds,
              },
            ]),
          },
        });
      return users.data.response.results as ForumUserDataType[];
    } return [];
  }, []);

  const updateForumUserIdMap = useCallback(async ({
    forumUserIds,
    forumUserObjs,
    forumUserIdMap,
    setForumUserIdMap,
  }: {
    forumUserIds?: string[],
    forumUserObjs?: ForumUserDataType[],
    forumUserIdMap: Record<string, ForumUserIdentificationType>,
    setForumUserIdMap: React.Dispatch<React.SetStateAction<Record<string, ForumUserIdentificationType>>>
  }) => {
    const applyUserObjsOnIdMap = (usersData: ForumUserDataType[]) => {
      usersData.forEach(async forumUser => {
        const { aristoClassID, especialista, _id, nomeCompleto } = forumUser;
        if (!Object.keys(forumUserIdMap).includes(_id)) {
          setForumUserIdMap(prev => {
            const name = nomeCompleto?.trim() === '' ? 'Aristoter' : nomeCompleto;
            return {
              ...prev,
              [_id]: {
                name,
                specialist: especialista,
                aristoClassId: aristoClassID,
                profileImage: getUserPicture(aristoClassID),
              },
            };
          });
        }
      });
    };

    if (forumUserIds) {
      const usersData = await getUsersByIds({ userIds: forumUserIds });
      applyUserObjsOnIdMap(usersData);
    }

    if (forumUserObjs) {
      applyUserObjsOnIdMap(forumUserObjs);
    }
  }, [getUsersByIds]);

  const getResourceIdByInstitution = useCallback(
    async ({ institution }: { institution: string }) => {
      const resource = await api.post('/wf/forum_getResources', {
        institution,
      });

      return resource.data.response.foruns[0] ? resource.data.response.foruns[0].UID : '';
    },
    [],
  );

  const getAnswersByFirstLevelCommentId = useCallback(
    async ({ firstLevelCommentId }: { firstLevelCommentId: string }) => {
      const answers = await api.post('/wf/forum_responsesConsult', {
        comment: firstLevelCommentId,
      });
      return answers.data.response.comment_list;
    },
    [],
  );

  const rateComment = useCallback(async ({
    commentId, value }: {
      commentId: string,
      value: number,
    }) => {
    const res = await api.post('/wf/forum_reactionStar', {
      comment: commentId,
      value,
    });
    return res;
  }, []);

  const addLikeOnComment = useCallback(async ({
    commentId, targetURL }: {
      commentId: string,
      targetURL: string,
    }) => {
    const res = await api.post('/wf/forum_addlike', {
      comment: commentId,
      targetURL,
    });
    return res;
  }, []);

  const removeLikeOnComment = useCallback(async ({
    commentId }: {
      commentId: string,
    }) => {
    const res = await api.post('/wf/forum_RemoveLike', {
      comment: commentId,
    });
    return res.status;
  }, []);

  const changeCommentVisibility = useCallback(async ({
    commentId,
    turnPrivate,
  }: {
    commentId: string,
    turnPrivate: boolean,
  }) => {
    const res = await api.post('wf/forum_visibilityControl', {
      comment: commentId,
      turnPrivate,
    });
    return res.status;
  }, []);

  const createOrGetForumByUID = useCallback(async ({
    uid,
    nav,
  }: {
    uid: string,
    nav?: number,
  }) => {
    const forum = await api.post('/wf/forum_consultPg', {
      uid,
      maxEntries: COMMENT_PAGE_STEP,
      RenderURL: '',
      nav: nav || 0,
    });
    return forum.data.response as ForumConsultOutputType;
  }, []);

  const createFirstLevelComment = useCallback(async ({
    author,
    text,
    forumId,
  }: {
    author: string,
    text: string,
    forumId: string,
  }) => {
    const newComment = await api.post('/wf/forum_addcomment', {
      text,
      author,
      forum: forumId,
    });
    return newComment.status;
  }, []);

  const createSecondLevelComment = useCallback(async ({
    content,
    targetURL,
    forumId,
    parentCommentId,
  }: {
    content: string,
    targetURL: string,
    forumId: string,
    parentCommentId: string,
  }) => {
    const newComment = await api.post('/wf/forum_addreply', {
      text: content,
      targetURL,
      forum: forumId,
      FatherComment: parentCommentId,
    });
    return newComment.status;
  }, []);

  const createThirdLevelComment = useCallback(async ({
    firstLevelCommentId,
    secondLevelCommentId,
    targetURL,
    content,
    forumId,
  }: {
    firstLevelCommentId: string,
    secondLevelCommentId: string,
    targetURL: string,
    content: string,
    forumId: string,
  }) => {
    const newComment = await api.post('/wf/forum_replyToReply', {
      text: content,
      targetURL,
      forum: forumId,
      FatherComment: firstLevelCommentId,
      TargetComment: secondLevelCommentId,
    });
    return newComment.status;
  }, []);

  const deleteComment = useCallback(async ({
    commentId,
    forumId,
  }: {
    commentId: string,
    forumId: string,
  }) => {
    const deletedComment = await api.post('/wf/forum_deleteComment', {
      comment: commentId,
      forum: forumId,
    });
    return deletedComment.status;
  }, []);

  return {
    getCredentialsByEmail,
    createOrGetForumByUID,
    getResourceIdByInstitution,
    createFirstLevelComment,
    createSecondLevelComment,
    createThirdLevelComment,
    deleteComment,
    addLikeOnComment,
    updateForumUserIdMap,
    removeLikeOnComment,
    getAnswersByFirstLevelCommentId,
    rateComment,
    changeCommentVisibility,
  };
};
