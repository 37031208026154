import { Box, Center, Text } from '@chakra-ui/react';
import moment from 'moment';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';

type RankingInfoCardType = {
  device: 'web' | 'mobile';
  answersAt?: Date;
  rankingAt?: Date;
  finishedState?: boolean;
  hide?: boolean;
}

export const RankingInfoCard: FC<RankingInfoCardType> = ({
  answersAt, rankingAt, finishedState, device, hide = false,
}: RankingInfoCardType) => {
  const { cardBorder } = useDefaultStyles();
  const isMobile = (device === 'mobile');

  if ((useDevice() !== device)
    || hide
    || ((!answersAt || finishedState) && !rankingAt)) {
    return <></>;
  }

  return (
    <Center
      {...cardBorder}
      flexDir={{ base: 'row', md: 'column' }}
      maxW={isMobile ? 400 : undefined}
      m={isMobile ? '0.5rem auto' : undefined}
      alignItems={isMobile ? 'start' : undefined}
      p={2}
      my={2}
    >
      {answersAt && !finishedState && (
        <Box px={isMobile ? 2 : undefined}>
          <Text fontSize="small" fontWeight="bold" textAlign="center">Resultado disponível em:</Text>
          <Text fontSize="small" textAlign="center">{moment(answersAt).format('DD [de] MMMM [de] YYYY')}</Text>
        </Box>
      )}
      {rankingAt && (
        <Box
          px={isMobile ? 2 : undefined}
          ml={isMobile ? 2 : undefined}
          mt={!isMobile ? 1 : undefined}
        >
          <Text fontSize="small" fontWeight="bold" textAlign="center">Ranking disponível em:</Text>
          <Text fontSize="small" textAlign="center">{moment(rankingAt).format('DD [de] MMMM [de] YYYY')}</Text>
        </Box>
      )}
    </Center>
  );
};
