import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import './index.scss';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from './lib/theme';

ReactDOM.render(
  <>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </ChakraProvider>
    <ToastContainer />
  </>,
  document.getElementById('root'),
);
