import { FC, useEffect } from 'react';

const insertScript = (
  src: string,
  id: string,
  parentElement: HTMLElement,
): void => {
  const script = window.document.createElement('script');
  script.id = id;
  script.src = src;
  parentElement.appendChild(script);
};

const removeScript = (id: string, parentElement: HTMLElement): void => {
  const script = window.document.getElementById(id);
  if (script) {
    parentElement.removeChild(script);
  }
};

export const SirenaButton: FC = () => {
  useEffect(() => {
    if (!window) {
      return (): undefined => undefined;
    }
    const { document } = window;
    insertScript('https://widget.sirena.app/get?token=cb1eb2caf55c458f834fb292f0a71715', 'sirena-btn', document.body);
    return (): void => removeScript('sirena-btn', document.body);
  }, []);
  return null;
};
