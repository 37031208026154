import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, HStack, Text, useDisclosure, Fade, Image, Center, useColorMode } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { faCircle, IconName, IconLookup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { BurgerMenuItemType } from '../../../api/burger-menu';
import aristoDetail from '../../../assets/aristo-detail.svg';
import aristoDetailDark from '../../../assets/aristo-detail-dark.svg';
import { useOnline } from '../../../hooks/useOnline';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

export const EMCCourses = ['63650e482496b3700990facf', '63650f68f5d699409ae09289'];
export const EMCAllowedMenuItems = ['Agenda', 'Perfil', 'Atividades extras',
  'Lista de temas', 'Aristo Academy', 'Aristo Store'];

interface SideMenuItemProps {
  isExpanded?: boolean;
  item: Pick<BurgerMenuItemType, 'title' | 'url' | 'icon'>;
}

function SideMenuItem({
  isExpanded = false, item,
}: SideMenuItemProps) {
  const iconLookup: IconLookup = { prefix: 'fas', iconName: item.icon as IconName };
  const iconDefinition = findIconDefinition(iconLookup) || faCircle;
  const history = useHistory();
  const { colorMode } = useColorMode();
  const { colors } = useDefaultStyles();

  const isSelected = useMemo(() => {
    if (!item.url) {
      return false;
    }
    return history.location.pathname.startsWith(item.url);
  }, [history.location.pathname, item.url]);

  return (
    <Box mb={2}>
      <HStack
        as="button"
        pl={3}
        w="100%"
        minH="48px"
        color={colors.lightGray.goDarker}
        align="center"
        onClick={() => {
          if (item.url) {
            history.push(item.url);
          }
        }}
      >
        <Box>
          <FontAwesomeIcon icon={iconDefinition} style={{ width: '24px', height: '24px' }} />
        </Box>
        <Fade
          in={isExpanded}
          unmountOnExit
          style={{ flex: 1 }}
        >
          <Text
            as="div"
            textAlign="left"
            flex={1}
            noOfLines={2}
            overflow="hidden"
            mr={1}
          >
            {item.title}
          </Text>
        </Fade>
        {isSelected && (
          <Center h="50px">
            <Image h="100%" src={colorMode === 'light' ? aristoDetail : aristoDetailDark} />
          </Center>
        )}
      </HStack>
    </Box>
  );
}

interface SideMenuProps {
  items?: BurgerMenuItemType[];
  isLoading?: boolean;
}

export function SideMenu({
  items, isLoading = false,
}: SideMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onlineCounter = useOnline(isOpen);
  const sideBarRef = useRef<HTMLDivElement>(null);

  const width = useMemo(() => {
    if (isLoading) {
      return '0px';
    }
    return isOpen ? '230px' : '60px';
  }, [isLoading, isOpen]);
  const { colors } = useDefaultStyles();
  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });

  useEffect(() => {
    // 👇️ get global mouse coordinates
    const handleWindowMouseMove = (event: MouseEvent) => {
      setGlobalCoords({
        x: event.clientX,
        y: event.clientY,
      });
    };
    document.addEventListener('mouseover', handleWindowMouseMove);

    return () => {
      document.removeEventListener('mouseover', handleWindowMouseMove);
    };
  }, []);

  useEffect(() => {
    if (globalCoords.x < window.innerWidth / 10) {
      if (globalCoords.y > window.innerHeight - 50) {
        sideBarRef.current?.scrollBy(0, 150);
      }
      if (globalCoords.y > 80 && globalCoords.y < 80 + 50) {
        sideBarRef.current?.scrollBy(0, -150);
      }
    }
  }, [globalCoords.x, globalCoords.y]);

  return (
    <Box
      ref={sideBarRef}
      flexDirection="column"
      display={{ base: 'none', md: 'flex' }}
      w={width}
      className="hide-scrollbar"
      overflowY="auto"
      bgColor={colors.secondary.goDarker}
      transition="width 0.2s"
      alignSelf="stretch"
      py={4}
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <Box ml={1}>
        {items && items.length > 0 && items
          .map(item => (
            <SideMenuItem key={item.title} item={item} isExpanded={isOpen} />
          ))}
      </Box>
      <Box ml={1} mt="auto">
        <SideMenuItem
          isExpanded={isOpen}
          item={{
            title: `${onlineCounter} pessoas estudando agora!`,
            url: '',
            icon: 'rocket',
          }}
        />
      </Box>
    </Box>
  );
}
