import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import { getToken } from 'firebase/messaging';
import { QueryResult } from '@apollo/client';
import { toast } from 'react-toastify';
import { Profile, StudyWeekSchema } from './api/profile';
import LoadingScreen from './views/_components/LoadingScreen';
import { getDateParam } from './hooks/getDateParam';
import { Agenda, useAgendaQuery } from './api/agenda/agenda';
import { GlobalContext } from './Global.context';
import { buildWeekArray, DayItem } from './utils/weekArray';
import { SirenaButton } from './views/_components/SirenaButton';
import { messaging } from './client/firebase';
import { useRegisterUserNotifications } from './api/notifications/register';
import { useForumAPI } from './hooks/useForumAPI';
import api from './services/api';
import { useLocalStorage } from './hooks/useLocalStorage';
import { getParam } from './hooks/useSearchParam';
import { getTheoreticalType } from './hooks/getTheoreticalType';
import {
  GetActivityDoubtsInputType, GetActivityDoubtsOutputType, useAddOrRemoveQuestionAsDoubt, useGetActivityDoubts,
} from './api/agenda/questions';
import { getAgendaTheoreticalType } from './utils/getAgendaTheoreticalType';

const HIDE_SIRENA_ROUTES = ['copa-aristo'];

interface hasAgendaInterface {
  theoreticalStudy: boolean;
  theoreticalReview: boolean;
  smartReview: boolean;
  examType: 'exams' | 'smart-exams' | 'mocks' | '';
  all: boolean;
}
interface PrivateInterface {
  profile: Profile;
  profileLoading: boolean;
  hasAgenda: hasAgendaInterface;
  refetchAgenda: () => Promise<void>;
  showDays?: DayItem[];
  onPrevDays?: () => void;
  onNextDays?: () => void;
  agenda?: Agenda;
  daysSlice?: number;
  handleDaysSlice?: (amount: number) => void;
  forumCredentials?: ForumCredentialsType;
  isUserAristoTeam?: boolean;
  doubtQuestionIds: string[];
  setDoubtQuestionIds: React.Dispatch<React.SetStateAction<string[]>>;
  isInDoubtOnCurrQuestion?: boolean;
  handleAddOrRemoveDoubt: (action: 'add' | 'remove') => Promise<void>;
  activityDoubtsQuery: QueryResult<GetActivityDoubtsOutputType, GetActivityDoubtsInputType>;
  shouldEnableMarkDoubts?: boolean;
  onDoubtClick: () => Promise<void>;
}

const defaultStudyWeekSchema: StudyWeekSchema = {
  examAndMocks: false,
  smartReview: false,
  theoreticalReview: false,
  theoreticalStudy: false,
};

const dataDefault: PrivateInterface = {
  profile: {
    _id: '',
    courses: [],
    courseFinished: false,
    email: '',
    graduation: {
      institution: '',
      yearConclusion: '',
      desiredExpertise: '',
      isGraduated: false,
      uf: '',
    },
    name: '',
    studyWeek: {
      sunday: defaultStudyWeekSchema,
      monday: defaultStudyWeekSchema,
      tuesday: defaultStudyWeekSchema,
      wednesday: defaultStudyWeekSchema,
      thursday: defaultStudyWeekSchema,
      friday: defaultStudyWeekSchema,
      saturday: defaultStudyWeekSchema,
    },
    updatedAt: '',
    createdAt: '',
    institutionTarget: [],
    questionnaireAnswered: true,
    mobilePhone: '',
    uid: '',
    gender: 'm',
    work: {
      inHealthArea: false,
      placeOfWork: '',
      weeklyHours: '',
    },
    birthday: '',
    roles: [],
  },
  profileLoading: false,
  refetchAgenda: async () => undefined,
  forumCredentials: { userToken: '', userId: '', isSpecialist: false },
  isUserAristoTeam: false,
  hasAgenda: {
    theoreticalStudy: false,
    theoreticalReview: false,
    smartReview: false,
    examType: '',
    all: false,
  },
  isInDoubtOnCurrQuestion: false,
  handleAddOrRemoveDoubt: () => new Promise(() => null),
  doubtQuestionIds: [],
  activityDoubtsQuery: {} as QueryResult<GetActivityDoubtsOutputType, GetActivityDoubtsInputType>,
  setDoubtQuestionIds: () => null,
  shouldEnableMarkDoubts: false,
  onDoubtClick: () => new Promise(() => null),
};

type ForumCredentialsType = {
  userToken: string,
  userId: string,
  isSpecialist: boolean,
}

export const PrivateContext = createContext<PrivateInterface>(dataDefault);

export const PrivateStorage: FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setStorageSearchInput] = useLocalStorage('@Aristoclass:contentSearchValues');
  const { handleRegisterUser, handleCreateUser } = useRegisterUserNotifications();
  const [initDay, setInitDay] = useState(35);
  const [daysSlice, setDaysSlice] = useState(1);
  const [forumCredentials, setForumCredentials] = useState<ForumCredentialsType>();
  const daySelected = getDateParam();
  const query = useContext(GlobalContext).profile;
  const { getCredentialsByEmail } = useForumAPI();

  const profile = React.useMemo(() => {
    const { data } = query;
    if (data) {
      return data.profile;
    }
    return undefined;
  }, [query]);

  const maxDays = useMemo(() => {
    if (profile) {
      const today = new Date();
      const init = new Date(profile.createdAt);
      const diff = today.getTime() - init.getTime();
      const diffDays = diff / (24 * 3600 * 1000);
      const daysValue = Math.ceil(diffDays / daysSlice) * daysSlice;
      return daysValue;
    }
    return 35;
  }, [daysSlice, profile]);

  const agendaQuery = useAgendaQuery({
    calendarDate: getDateParam(),
  });

  /** Memos */
  const filterDays = useMemo(() => {
    const wd = buildWeekArray(maxDays);
    return wd[0];
  }, [maxDays]);

  useEffect(() => {
    const startOfWeekOfDaySelected = moment(daySelected)
      .subtract(1, 'day')
      .startOf('week')
      .add(1, 'day')
      .format('YYYYMMDD');
    const indexStartOfWeek = filterDays.findIndex(item => item.value === startOfWeekOfDaySelected);
    const indexDay = filterDays.findIndex(item => item.value === daySelected);
    if (indexStartOfWeek !== -1) {
      setInitDay(indexStartOfWeek + (daysSlice === 7 ? 0 : (indexDay - indexStartOfWeek)));
    }
  }, [daySelected, daysSlice, filterDays]);

  const showDays = useMemo(() => {
    return filterDays.slice(initDay, initDay + daysSlice);
  }, [daysSlice, filterDays, initDay]);

  const agenda = useMemo(() => {
    const { data, error } = agendaQuery;

    if (data && !error) {
      return data.agenda;
    }

    return undefined;
  }, [agendaQuery]);

  const hasAgenda: hasAgendaInterface = useMemo(() => {
    const { data } = agendaQuery;

    if (data) {
      const ag = data.agenda;

      const hasTheoreticalStudy = ag.theoreticalStudy.length > 0;

      const hasTheoreticalReview = ag.theoreticalReview.length > 0;

      const hasSmartReview = ag.smartReview.questions.length > 0
        || ag.smartReview.flashcards.length > 0;

      const examType = () => {
        if (ag.exams.length > 0) {
          return 'exams';
        }
        if (ag.smartExamActivity) {
          return 'smart-exams';
        }
        if (ag.mocks.length > 0) {
          return 'mocks';
        }
        return '';
      };

      return {
        theoreticalStudy: hasTheoreticalStudy,
        theoreticalReview: hasTheoreticalReview,
        smartReview: hasSmartReview,
        examType: examType(),
        all: hasTheoreticalStudy && hasTheoreticalReview && hasSmartReview,
      };
    }

    return {
      theoreticalStudy: false,
      theoreticalReview: false,
      smartReview: false,
      examType: '',
      all: false,
    };
  }, [agendaQuery]);

  /** Callbacks */
  const refetchAgenda = useCallback(async () => {
    try {
      await agendaQuery.refetch();
    } catch (error) {
      toast.error(error.message);
    }
  }, [agendaQuery]);

  const handlePrevDays = useCallback(() => {
    setInitDay(old => Math.max(old - daysSlice, 0));
  }, [daysSlice]);

  const handleNextDays = useCallback(() => {
    setInitDay(old => old + daysSlice);
  }, [daysSlice]);

  const handleDaysSlice = useCallback((amount: number) => {
    setDaysSlice(amount);
  }, []);

  useEffect(() => {
    const request = async () => {
      if (profile) {
        const credentials = await getCredentialsByEmail({
          aristoClassId: profile._id,
          email: profile.email,
          firebaseId: profile.uid,
          userName: profile.name,
        });
        if (credentials) {
          api.defaults.headers.common.Authorization = `Bearer ${credentials.userToken}`;
          setForumCredentials(credentials);
        }
      }
    };
    try {
      request();
    } catch (e) {
      console.error(e);
    }
  }, [getCredentialsByEmail, profile, profile?.email]);

  const isUserAristoTeam = useMemo(() => {
    const userRoles = profile?.roles;
    const majorRoles = ['super-admin', 'admin', 'commentator', 'curator'];
    if (userRoles?.some(x => majorRoles.includes(x))) {
      return true;
    } return false;
  }, [profile?.roles]);

  useEffect(() => {
    if (profile) {
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_NOTIFICATION_VAPID,
      }).then(token => {
        handleRegisterUser(token, profile).catch(console.error);
      }).catch(error => {
        console.error(error);
        handleCreateUser(profile.uid).catch(console.error);
      });
    }
  }, [handleCreateUser, handleRegisterUser, profile]);

  useEffect(() => {
    setStorageSearchInput({});
  }, [setStorageSearchInput]);

  const currQuestionId = getParam('id');
  const activityId = getParam('a');
  const isAgenda = !!getAgendaTheoreticalType();

  const activityDoubtsQuery = useGetActivityDoubts({
    input: {
      ...(activityId && !isAgenda && { activityId }),
      ...(isAgenda && agenda && { agendaId: agenda._id }),
      ...(isAgenda && { type: getAgendaTheoreticalType() }),
    },
  });
  const serverDoubtIds = useMemo(() => activityDoubtsQuery.data?.getActivityDoubts.doubtIds || [],
    [activityDoubtsQuery.data?.getActivityDoubts.doubtIds]);
  const [doubtQuestionIds, setDoubtQuestionIds] = useState(serverDoubtIds);
  const isInDoubtOnCurrQuestion = doubtQuestionIds.includes(getParam('id'));
  const { addOrRemoveQuestionAsDoubt } = useAddOrRemoveQuestionAsDoubt();

  const handleAddOrRemoveDoubt = useCallback((action: 'add' | 'remove') => {
    return addOrRemoveQuestionAsDoubt({
      action,
      questionId: currQuestionId,
      ...(activityId && !isAgenda && { activityId }),
      ...(isAgenda && agenda && { agendaId: agenda._id }),
      ...(isAgenda && { type: getTheoreticalType() }),
    });
  }, [activityId, addOrRemoveQuestionAsDoubt, agenda, currQuestionId, isAgenda]);

  const onDoubtClick = useCallback(async () => {
    if (isInDoubtOnCurrQuestion) {
      setDoubtQuestionIds(prev => prev.filter(x => x !== currQuestionId));
      await handleAddOrRemoveDoubt('remove');
    } else {
      setDoubtQuestionIds(prev => [...prev, currQuestionId]);
      await handleAddOrRemoveDoubt('add');
    }
    await activityDoubtsQuery.refetch();
  }, [activityDoubtsQuery, currQuestionId, handleAddOrRemoveDoubt, isInDoubtOnCurrQuestion, setDoubtQuestionIds]);

  useEffect(() => {
    setDoubtQuestionIds(serverDoubtIds);
  }, [serverDoubtIds]);

  const shouldEnableMarkDoubts = !!(activityId
    || (!getTheoreticalType().startsWith('extra')));

  return (
    <PrivateContext.Provider
      value={{
        profile: profile || dataDefault.profile,
        profileLoading: query.loading,
        hasAgenda,
        refetchAgenda,
        showDays,
        onPrevDays: handlePrevDays,
        onNextDays: handleNextDays,
        agenda,
        daysSlice,
        forumCredentials,
        handleDaysSlice,
        isUserAristoTeam,
        doubtQuestionIds,
        setDoubtQuestionIds,
        isInDoubtOnCurrQuestion,
        handleAddOrRemoveDoubt,
        activityDoubtsQuery,
        shouldEnableMarkDoubts,
        onDoubtClick,
      }}
    >
      {query.loading || agendaQuery.loading ? (
        <LoadingScreen />
      ) : (
        <>
          {children}
          {
            !HIDE_SIRENA_ROUTES.map(x => window.location.pathname.includes(x)).some(x => x) && (
              <SirenaButton />
            )
          }
        </>
      )}
    </PrivateContext.Provider>
  );
};
