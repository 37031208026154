import { Button, HStack } from '@chakra-ui/react';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useContext, useState } from 'react';
import { ForumContext } from '../../../../contexts/Forum.context';
import { useDefaultStyles, useRGBColor } from '../../../../hooks/useDefaultStyles';
import { useForumAPI } from '../../../../hooks/useForumAPI';
import { Loading } from '../../../../lib/components/Loading';
import { toast } from '../../../../utils/toast';

type SingleStarType = {
  index: number,
  currRating: number,
  currHover: number,
  setCurrHover: React.Dispatch<React.SetStateAction<number>>,
  onClick: (index: number) => void,
}

const SingleStar: FC<SingleStarType> = ({
  index,
  currRating,
  currHover,
  setCurrHover,
  onClick,
}: SingleStarType) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  return (
    <Button
      variant="unstyled"
      transition="0.3s all"
      onClick={() => onClick(index)}
      onMouseEnter={() => setCurrHover(index)}
      onMouseLeave={() => setCurrHover(0)}
      color={(currRating >= index || currHover >= index) ? colors.prizes.gold : undefined}
      _focus={{ variant: 'unstyled', outline: 'none' }}
      _hover={{
        bgColor: 'transparent',
        filter: 'brightness(1.2)',
        color: colors.prizes.gold,
      }}
      textShadow={`3px 2px ${toRGB(colors.prizes.gold)}`}
    >
      <FontAwesomeIcon icon={currRating >= index ? faStarSolid : faStar} />
    </Button>
  );
};

type StarsRatingType = {
  onClose: () => void,
  setIsRated: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLiked: React.Dispatch<React.SetStateAction<boolean>>,
  targetURL: string,
}

export const StarsRating: FC<StarsRatingType> = ({
  onClose, setIsRated, setIsLiked, targetURL,
}: StarsRatingType) => {
  const { currCommentId } = useContext(ForumContext);
  const { rateComment, addLikeOnComment } = useForumAPI();
  const [currRating, setCurrRating] = useState(0);
  const [currHover, setCurrHover] = useState(0);
  const [loadingRating, setLoadingRating] = useState(false);

  const handleStarClick = useCallback(async (index: number) => {
    setLoadingRating(true);
    try {
      setCurrRating(index);
      if (currCommentId) {
        await rateComment({ commentId: currCommentId, value: index });
        if (index >= 4) {
          await addLikeOnComment({ commentId: currCommentId, targetURL });
          setIsLiked(true);
        }
      }
      onClose();
      setIsRated(true);
      toast.success({
        title: `Avaliação realizada! Nota: ${index}`,
        description: 'Lembre-se de dar um feedback ao plantonista!',
      });
    } catch {
      toast.error({
        title: 'Não foi possível avaliar o comentário',
        description: 'Verifique sua conexão e tente novamente',
      });
    } finally {
      setLoadingRating(false);
    }
  }, [addLikeOnComment, currCommentId, onClose, rateComment, setIsLiked, setIsRated, targetURL]);

  if (loadingRating) {
    return <Loading />;
  }

  return (
    <HStack justify="center">
      {
        Array(5)
          .fill(0)
          .map((_, index) => {
            const key = `start${index + 1}`;
            return (
              <SingleStar
                key={key}
                index={index + 1}
                currRating={currRating}
                onClick={handleStarClick}
                currHover={currHover}
                setCurrHover={setCurrHover}
              />
            );
          })
      }
    </HStack>
  );
};
