import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import { Text } from '@chakra-ui/react';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCoursesManyInfo } from '../../api/courses';
import { useProfile } from '../../api/profile';
import { PrivateContext } from '../../Private.context';
import ConfirmModal from '../../lib/components/ConfirmModal';
import Select from '../../lib/components/Select';

type CourseSelectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const CourseSelectionModal: FC<CourseSelectionModalProps> = ({
  isOpen, onClose,
}) => {
  const { profile } = useContext(PrivateContext);
  const coursesInfoQuery = useCoursesManyInfo(profile.courses.map(c => c.course));
  const { onSwapCourse } = useProfile();

  const [swappingCourse, setSwappingCourse] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(profile.courses[0].course);

  const coursesInfo = useMemo(() => {
    const { data } = coursesInfoQuery;
    if (!data) {
      return undefined;
    }
    return data.coursesManyInfo;
  }, [coursesInfoQuery]);

  const handleSwapCourse = useCallback(async () => {
    try {
      setSwappingCourse(true);
      await onSwapCourse(selectedCourse);
      window.location.replace('/');
    } catch (error) {
      console.error(error);
    }
  }, [onSwapCourse, selectedCourse]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onDecline={onClose}
      confirmText="Trocar"
      declineText="Cancelar"
      title="Trocar curso"
      subtitle="Selecione o curso para o qual deseja ir"
      icon={<FontAwesomeIcon icon={faExchangeAlt} />}
      loading={swappingCourse}
      loadingText="Trocando"
      onConfirm={handleSwapCourse}
    >
      {coursesInfo ? (
        <Select
          required
          value={selectedCourse}
          onChange={e => setSelectedCourse(e.target.value)}
          options={coursesInfo.map(courseInfo => ({
            value: courseInfo._id,
            label: `${courseInfo.title}`,
          }))}
          mt={2}
        />
      ) : (
        <Text mt={2}>Carregando lista de cursos...</Text>
      )}
    </ConfirmModal>
  );
};

export default CourseSelectionModal;
