import { Button, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useDefaultStyles, useRGBColor } from '../../../../hooks/useDefaultStyles';

type StarRatingButtonType = {
  isRated: boolean,
  onClick: () => void,
}

export const StarRatingButton: FC<StarRatingButtonType> = ({
  isRated,
  onClick,
}: StarRatingButtonType) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  return (
    <Tooltip label="Avaliar">
      <Button
        fontSize="xs"
        variant="unstyled"
        transition="0.3s all"
        onClick={onClick}
        color={isRated ? colors.prizes.gold : undefined}
        _focus={{ variant: 'unstyled', outline: 'none' }}
        _hover={{
          bgColor: 'transparent',
          filter: 'brightness(1.2)',
          color: colors.prizes.gold,
        }}
        textShadow={`3px 2px ${toRGB(colors.prizes.gold)}`}
      >
        <FontAwesomeIcon
          style={{ textShadow: `2px 2px ${toRGB(colors.prizes.gold)}` }}
          icon={isRated ? faStar : faStarRegular}
        />
      </Button>
    </Tooltip>
  );
};
