import { FC, useMemo } from 'react';
import moment from 'moment';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useStopwatch } from '../../hooks/useStopWatch';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useDevice } from '../../hooks/useDevice';

interface CounterProps {
  startedAt?: Date;
  timeToDo?: number;
  showCounter?: boolean;
  device?: 'web' | 'mobile' | 'both';
}

const Counter: FC<CounterProps> = ({
  startedAt,
  timeToDo,
  device = 'both',
  showCounter = true,
}) => {
  const canDoUntil = useMemo(() => {
    if (!startedAt) {
      return moment();
    }
    return moment(startedAt).add(timeToDo, 'milliseconds');
  }, [startedAt, timeToDo]);

  const now = useStopwatch(canDoUntil);
  const currentDevice = useDevice();
  const { cardBorder, colors } = useDefaultStyles();

  if ((device !== 'both' && device !== currentDevice) || !showCounter || !timeToDo) {
    return <></>;
  }

  return (now.h && now.m && now.s) ? (
    <Box
      {...cardBorder}
      position="relative"
      px={1}
      py={2}
      mb={2}
    >
      <Flex align="center" justify="center">
        <Box position="absolute" left={2} color={colors.secondary.goALittleDarker}>
          <FontAwesomeIcon icon={faClock} size="lg" />
        </Box>
        <Text fontSize="small" fontWeight="bold" textAlign="center">
          {`${now.h}h${now.m}m${now.s}s`}
        </Text>
      </Flex>
    </Box>
  ) : null;
};

export default Counter;
