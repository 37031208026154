import { gql, QueryResult, useQuery } from '@apollo/client';
import { Comment } from './agenda';

export interface Mindmap {
  _id: string;
  url: string;
  description: string;
  comment: Comment;
  title?: string;
  subtitle?: string;
}

interface MindmapManyInput {
  ids: string[];
}

interface MindmapManyOutput {
  mindmapMany: Mindmap[];
}

const MINDMAP_MANY = gql`
  query MindmapMany(
    $ids: [ObjectId!]!
  ) {
    mindmapMany(
      ids: $ids
    ) {
      _id
      url
      description
      title
      subtitle
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
    }
  }
`;

export function useMindmaps({ ids }: MindmapManyInput): QueryResult<MindmapManyOutput, MindmapManyInput> {
  return useQuery<MindmapManyOutput, MindmapManyInput>(MINDMAP_MANY, {
    variables: {
      ids,
    },
    fetchPolicy: 'network-only',
    skip: ids.length === 0,
  });
}
