import { FC } from 'react';
import { getActivityIndex } from '../../hooks/getActivityIndex';
import { getDateParam } from '../../hooks/getDateParam';
import { AristoTab } from './AristoTab.component';

export interface MenuTabsItems {
  questao: boolean;
  video: boolean;
  flashcard: boolean;
  apostila: boolean;
  'mapa-mental': boolean;
}

interface MenuTabsProps {
  menuItems: MenuTabsItems;
  path: string;
}

const MenuTabs: FC<MenuTabsProps> = ({
  menuItems, path,
}) => {
  const a = getActivityIndex();
  const search = `?date=${getDateParam()}${a ? ['&a=', a].join('') : ''}`;

  return (
    <AristoTab
      search={search}
      tabsData={[
        {
          to: `${path}/questao`,
          title: 'Questões',
          visible: menuItems.questao,
        },
        {
          to: `${path}/video`,
          title: 'Videoaula',
          visible: menuItems.video,
        },
        {
          to: `${path}/apostila`,
          title: 'Apostila digital',
          visible: menuItems.apostila,
        },
        {
          to: `${path}/mapa-mental`,
          title: 'Mapa mental',
          visible: menuItems['mapa-mental'],
        },
        {
          to: `${path}/flashcard`,
          title: 'Flashcards',
          visible: menuItems.flashcard,
        },
      ]}
    />
  );
};

export default MenuTabs;
