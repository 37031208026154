/* eslint-disable max-len */
import { extendTheme, ThemeConfig } from '@chakra-ui/react';

type ColorKeys = 'primary'
  | 'secondary'
  | 'tertiary'
  | 'dark'
  | 'light'
  | 'success'
  | 'warning'
  | 'error'
  | 'gold'
  | 'silver'
  | 'bronze'
  | 'white'
  | 'green'
  | 'red'
  | 'aristo'
  | 'gray'
  | 'bbi'
  | 'cbi'

export type ThemeColorType = Record<number, string>
export type ThemeColorsType = Record<ColorKeys, ThemeColorType>

const config: ThemeConfig = {
  initialColorMode: 'system',
  // useSystemColorMode: true,
};

type ColorModeType = 'light' | 'dark';

type StylesGlobalType = {
  colorMode: ColorModeType;
}

export const theme = extendTheme({
  config,
  styles: {
    global: (props: StylesGlobalType) => ({
      'html, body': {
        color: props.colorMode === 'light' ? 'gray.600' : 'dark.400',
        bgColor: props.colorMode === 'light' ? 'background.light' : 'background.dark',
        h: '100vh',
        listStylePosition: 'inside',
      },
    }),
  },
  colors: {
    background: {
      light: '#fff',
      dark: '#000919',
    },
    aristo: {
      50: '#ddeeff',
      100: '#afcfff',
      200: '#7fb2ff',
      300: '#4d99fd',
      400: '#1d85fb',
      500: '#0473e3',
      600: '#004db0',
      700: '#002e7f',
      800: '#00164f',
      900: '#011224',
    },
    bbi: {
      50: '#faf7f4',
      100: '#ecded2',
      200: '#dcc1ab',
      300: '#c89e7b',
      400: '#b78c68',
      500: '#9a7657',
      600: '#82634a',
      700: '#68503b',
      800: '#584332',
      900: '#3f3024',
    },
    cbi: {
      50: '#ebf0ff',
      100: '#cbd1ea',
      200: '#a8b3d6',
      300: '#8794c4',
      400: '#6676b3',
      500: '#4c5c99',
      600: '#3b4878',
      700: '#293357',
      800: '#171f36',
      900: '#050a18',
    },
    primary: {
      50: '#E2F2FF',
      100: '#B1DEF3',
      400: '#8CCAFF',
      500: '#0473E3',
      600: '#011224',
    },
    secondary: {
      50: '#F3F7FC',
      100: '#EDFBFE',
      400: '#DAE6F2',
      500: '#A3C6E1',
      600: '#3A5075',
      700: '#092A57',
    },
    tertiary: {
      400: '#71C6C1',
      500: '#3CCBDA',
      600: '#35B2BF',
    },
    dark: {
      400: '#C4C4C4',
      500: '#656667',
      600: '#4B4F54',
      700: '#3D4140',
    },
    light: {
      50: '#FFFFFF',
      100: '#F3F3F3',
      200: '#7E878A',
    },
    success: {
      50: '#E8f9E7',
      100: '#C8F0C5',
      400: '#66de96',
      500: '#55D455',
    },
    warning: {
      200: '#ffd90060',
      500: '#EBC756',
    },
    error: {
      100: '#FFC9CC',
      400: '#F27979',
      500: '#E2231A',
    },
    complementary: {
      300: '#fa985b',
      500: '#e35904',
    },
    gold: {
      500: '#F1AC0E',
      600: '#F19B0B',
    },
    silver: {
      500: '#F2F2F2',
      600: '#D0D0D0',
    },
    bronze: {
      500: '#BF880B',
      600: '#B86500',
    },
  },
  fonts: {
    body: 'Inter, Ubuntu, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    heading: 'Inter, Ubuntu, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
});
