import { Box, Text } from '@chakra-ui/react';
import {
  FC, useState,
} from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useForumAPI } from '../../hooks/useForumAPI';
import { useInstitutionsDiagnostic } from '../../hooks/useInstitutionsDiagnostic';
import { Forum } from '../_components/Forum/Forum';
import { InstitutionSelector } from '../_components/InstitutionSelector';

const CommentedQuestionsPage: FC = () => {
  const { colors } = useDefaultStyles();
  const {
    uf,
    handleUf,
    ufs,
    institutions,
    loading,
  } = useInstitutionsDiagnostic();
  const {
    getResourceIdByInstitution,
  } = useForumAPI();
  const [forumUID, setForumUID] = useState('');
  const [selectedInstitution, setSelection] = useState(false);

  return (
    <Box m="2rem auto" maxW={800}>
      <Box m="2rem auto">
        <Text
          fontSize="xl"
          mb={4}
          fontWeight="bold"
        >
          Selecione a instituição que você deseja acessar o gabarito comentado
        </Text>
        <InstitutionSelector
          variant="auto-select"
          ufs={ufs}
          uf={uf}
          institutions={institutions}
          onSelectUf={handleUf}
          onSelectInstitution={async e => {
            const resourceId = await getResourceIdByInstitution({ institution: e.institution });
            setForumUID(resourceId);
            setSelection(true);
          }}
          loadingInstitutions={loading}
          bgColor={colors.background}
        />
      </Box>
      {(selectedInstitution) && (
        <Forum
          id={forumUID}
          type="questao-comentada"
        />
      )}
    </Box>
  );
};

export default CommentedQuestionsPage;
