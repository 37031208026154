import React, {
  FC, useState, useCallback, useEffect,
} from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { DiagnosisHomePage } from './pages/DiagnosisHomePage';
import { MainBoxTemplate } from './_components/MainBoxTemplate';
import { InstitutionSelected } from './_components/InstitutionSelected';
import { useDiagnosticExam } from '../../api/diagnosis';
import { InstitutionTarget } from '../../api/profile';
import { useInstitutionsDiagnostic } from '../../hooks/useInstitutionsDiagnostic';
import { InstitutionSelector } from '../_components/InstitutionSelector';
import { useLocalStorage } from '../../hooks/useLocalStorage';

export const DiagnosisPage: FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedInstitutions, setSelectedInstitutions] = useState<InstitutionTarget[]>([]);
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');

  const {
    uf,
    handleUf,
    ufs,
    institutions,
    loading,
  } = useInstitutionsDiagnostic();

  const history = useHistory();
  const { search } = useLocation();

  const { handleCreateDiagnosticExam } = useDiagnosticExam({
    _id: '',
    templateId: new URLSearchParams(search).get('id') || undefined,
  });

  useEffect(() => {
    if (localStorage.getItem('@plataformaJJMentoria:institutions') !== null) {
      const inst = localStorage.getItem('@plataformaJJMentoria:institutions') as string;
      setSelectedInstitutions(JSON.parse(inst));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('@plataformaJJMentoria:id')
      && !isCompass
      && !JSON.parse(localStorage.getItem('@plataformaJJMentoria:finished') ?? 'false')) {
      history.push('/diagnostico/prova');
    }
  }, [history, isCompass]);

  const removeInstitution = useCallback((name: string) => {
    setSelectedInstitutions(prev => {
      const newArray = prev.filter(inst => inst.institution !== name);
      localStorage.setItem('@plataformaJJMentoria:institutions', JSON.stringify(newArray));
      return newArray;
    });
  }, []);

  const handleAddInstitution = useCallback((value: InstitutionTarget) => {
    setSelectedInstitutions(prev => {
      if (value.institution
        && value.uf
        && !prev.map(x => x.institution).includes(value.institution)) {
        const newArray = [...prev, value];
        localStorage.setItem('@plataformaJJMentoria:institutions', JSON.stringify(newArray));
        return newArray;
      } return prev;
    });
  }, []);

  const pageChange = useCallback((id: number) => {
    setCurrentPage(id);
  }, []);

  const handleCreateMock = useCallback(async () => {
    try {
      const result = await handleCreateDiagnosticExam(selectedInstitutions);
      if (result) {
        localStorage.setItem('@plataformaJJMentoria:id', result);
        history.push('/diagnostico/prova');
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error('Erro ao criar o simulado!');
      console.error(error);
    }
  }, [handleCreateDiagnosticExam, history, selectedInstitutions]);

  if (currentPage === 0) {
    return (
      <DiagnosisHomePage
        onStart={() => {
          if (isCompass) {
            history.push('/diagnostico/prova');
          } else {
            pageChange(1);
          }
        }}
      />
    );
  }

  if (currentPage === 1) {
    return (
      <MainBoxTemplate
        descriptionTitle="Qual o seu objetivo?"
        details={['Conta pra gente! Em quais instituições você gostaria de passar?',
          'Você pode selecionar quantas quiser!',
          'Deixe em branco caso ainda esteja indeciso.',
          'Atente-se à ordem de prioridade das instituições, pois isso influenciará no seu diagnóstico!',
        ]}
        btn1Params={{ onClick: () => pageChange(0), text: 'Voltar' }}
        btn2Params={{ onClick: () => pageChange(2), text: 'Confirmar' }}
        hasMargin
      >
        <InstitutionSelector
          variant="add-button"
          ufs={ufs}
          uf={uf}
          institutions={institutions}
          onSelectUf={handleUf}
          onSelectInstitution={handleAddInstitution}
          loadingInstitutions={loading}
        />
        <Flex
          width="100%"
          flexFlow="column"
          justify="center"
          paddingY="2rem"
        >
          {selectedInstitutions.map((institutionName, index) => (
            <InstitutionSelected
              index={index}
              key={institutionName.institution}
              name={institutionName.institution}
              onDelete={() => removeInstitution(institutionName.institution)}
            />
          ))}
        </Flex>
      </MainBoxTemplate>
    );
  }

  if (currentPage === 2 && !selectedInstitutions.length) {
    return (
      <MainBoxTemplate
        descriptionTitle="Ainda não sabe em quais instituições deseja passar?"
        details={['Sem problemas!',
          'Iremos considerar a incidência geral dos temas nas diversas instituições do país!',
          'E aí, está pronto?',
          'Caso já saiba as instituições que deseja, clique em "Voltar" e as selecione.']}
        btn1Params={{ onClick: () => pageChange(1), text: 'Voltar' }}
        btn2Params={{
          onClick: handleCreateMock,
          text: 'Iniciar Simulado',
        }}
      />
    );
  }

  if (currentPage === 2 && !!selectedInstitutions.length) {
    return (
      <MainBoxTemplate
        descriptionTitle="Vamos começar?"
        details={[
          `O seu simulado será avaliado conforme o seu desempenho e conforme a
          incidência dos temas nas instituições que você escolheu!`,
          'A prova tem 60 questões.',
          `Antes de começar, procure um espaço adequado para que você possa
             se concentrar e responder todas as questões com atenção.`]}
        btn1Params={{ onClick: () => pageChange(1), text: 'Voltar' }}
        btn2Params={{
          onClick: handleCreateMock,
          text: 'Iniciar Simulado',
        }}
      />
    );
  }

  return null;
};
