import React, { FC, useMemo } from 'react';
import FavoritesEmpty from '../types/FavoritesEmpty';
import AgendaVideo from '../types/AgendaVideo';
import { Video } from '../../../api/agenda/videos';
import { useVideoManyFavorite } from '../../../api/favorites';
import { SkeletonLoading } from '../../_components/SkeletonLoading';

const FavoriteVideos: FC = () => {
  const { data, loading } = useVideoManyFavorite();

  const videos = useMemo(() => {
    return data?.videoManyFavorite || [] as Video[];
  }, [data?.videoManyFavorite]);

  if (loading) {
    return (
      <SkeletonLoading />
    );
  }

  if (videos.length > 0) {
    return (
      <AgendaVideo
        data={videos}
      />
    );
  }

  return (
    <FavoritesEmpty />
  );
};

export default FavoriteVideos;
