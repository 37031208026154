import {
  Box,
  Circle,
  HStack, IconButton, Text,
  Drawer, DrawerBody, DrawerContent, DrawerHeader,
  DrawerOverlay, useDisclosure, Center, VStack, Button, Menu,
  MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import { faArrowLeft, faBell, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onMessage } from 'firebase/messaging';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Notification, useNotifications } from '../../../api/notifications';
import { messaging } from '../../../client/firebase';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Loading } from '../../../lib/components/Loading';
import { PrivateContext } from '../../../Private.context';
import { toast } from '../../../utils/toast';
import { NotificationSection } from './Notification.component';

export const NotificationsComponent: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { profile } = useContext(PrivateContext);
  const NOTIFICATION_LIMIT = 10;
  const { colors } = useDefaultStyles();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const {
    getUserNewNotificationsAmount: {
      data: newNotificationsData,
      refetch: refetchNewNotifications,
    },
    getUserNotifications: {
      data: notificationsData,
      loading: notificationsLoading,
      called: notificationsCalled,
      refetch: refetchNotifications,
    },
    handleUpdateLastSeen,
    handleUpdateSeen,
    handleUpdateAllSeen,
  } = useNotifications(profile.uid, 0, NOTIFICATION_LIMIT);

  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  useEffect(() => {
    if (!notificationsData || notificationsLoading) {
      return;
    }

    setNotifications(prev => [...prev, ...notificationsData.findUserNotifications]);
  }, [notificationsCalled, notificationsData, notificationsLoading]);

  useEffect(() => {
    setHasNewNotifications(Boolean(newNotificationsData?.findUserNewNotificationsAmount));
  }, [newNotificationsData?.findUserNewNotificationsAmount]);

  const serviceWorkerPostMessageHandler = useCallback((e: MessageEvent<string>) => {
    if (e.data === 'NEW_NOTIFICATION_RECEIVED') {
      if (refetchNewNotifications) {
        refetchNewNotifications();
      }
      if (refetchNotifications) {
        refetchNotifications();
      }
    }
  }, [refetchNewNotifications, refetchNotifications]);

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', serviceWorkerPostMessageHandler);
    return () => navigator.serviceWorker.removeEventListener('message', serviceWorkerPostMessageHandler);
  }, [serviceWorkerPostMessageHandler]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, payload => {
      toast.info({
        title: payload.notification?.title,
        description: payload.notification?.body,
      });
      if (refetchNewNotifications) {
        refetchNewNotifications();
      }
      if (refetchNotifications) {
        refetchNotifications();
      }
    });
    return unsubscribe;
  }, [refetchNewNotifications, refetchNotifications]);

  const handleNotificationClick = useCallback(async (id: string) => {
    try {
      await handleUpdateSeen(id);
      setNotifications(prev => prev.map(n => {
        if (n._id === id) {
          return { ...n, seen: true };
        }
        return n;
      }));
    } catch (error) {
      console.error(error);
    }
  }, [handleUpdateSeen]);

  const handleUpdateAllSeenClick = useCallback(async () => {
    const res = await handleUpdateAllSeen();
    if (res?.data?.updateAllSeen === 0) {
      return;
    }
    if (res?.data?.updateAllSeen) {
      setNotifications(prev => prev.map(n => ({ ...n, seen: true })));
      return;
    }
    toast.error('Houve um erro ao tentar marcar todas as notificações como lidas. Tente novamente.');
  }, [handleUpdateAllSeen]);

  const handleFetchMore = useCallback(() => {
    refetchNotifications({
      limit: NOTIFICATION_LIMIT,
      skip: notifications.length,
    });
  }, [notifications, refetchNotifications]);

  return (
    <>
      <Box
        position="absolute"
        top={{ base: '14.5px', md: '27px' }}
        right={{ base: '68px', md: '65px' }}
      >
        <Box
          as="button"
          onClick={() => {
            handleUpdateLastSeen();
            setHasNewNotifications(false);
            onOpen();
          }}
          fontSize="1.3rem"
          color={colors.lighter.fromWhite}
        >
          <FontAwesomeIcon icon={faBell} />
        </Box>
        {hasNewNotifications && (
          <Circle
            top="1px"
            right={-1}
            size="10px"
            bgColor={colors.red.goDarker}
            position="absolute"
          />
        )}
      </Box>
      <Drawer placement="right" size="md" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bgColor={colors.background}>
          <DrawerHeader>
            <HStack w="100%" align="center" justify="space-between">
              <HStack>
                <IconButton
                  aria-label="voltar"
                  icon={<FontAwesomeIcon icon={faArrowLeft} />}
                  onClick={onClose}
                  variant="ghost"
                />
                <Text fontWeight="bold">Notificações</Text>
              </HStack>
              <Menu>
                <MenuButton as={Button} variant="ghost">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </MenuButton>
                <MenuList bgColor={colors.background}>
                  <MenuItem
                    onClick={handleUpdateAllSeenClick}
                    fontSize="md"
                  >
                    Marcar todas como lidas
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </DrawerHeader>
          <DrawerBody px={{ base: 0, md: 4 }}>
            {(notificationsLoading && !notificationsCalled) ? <Loading boxSize={10} /> : (
              <>
                {notifications.length > 0 ? (
                  <VStack w="100%">
                    <NotificationSection
                      title="Nas últimas 24 horas"
                      intervalRange={{ start: 0, end: 1 }}
                      notifications={notifications}
                      updateSeen={handleNotificationClick}
                    />

                    <NotificationSection
                      title="Na última semana"
                      intervalRange={{ start: 1, end: 7 }}
                      notifications={notifications}
                      updateSeen={handleNotificationClick}
                    />

                    <NotificationSection
                      title="No último mês"
                      intervalRange={{ start: 7, end: 30 }}
                      notifications={notifications}
                      updateSeen={handleNotificationClick}
                    />

                    <NotificationSection
                      title="Anteriores"
                      intervalRange={{ start: 30, end: 'infinite' }}
                      notifications={notifications}
                      updateSeen={handleNotificationClick}
                    />
                  </VStack>
                ) : (
                  <Center>
                    <Text>Nenhuma notificação</Text>
                  </Center>
                )}
              </>
            )}
            <Center my={4}>
              <Button variant="link" color={colors.secondary.goFullLighter} onClick={handleFetchMore}>
                Carregar mais...
              </Button>
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
