import { gql, QueryResult, useQuery } from '@apollo/client';

export interface BurgerMenuItemType {
  title: string;
  icon: string;
  url: string;
  order: number;
  subgroup: string;
}

interface FindBurgerMenuOutput {
  findBurgerMenuItems: BurgerMenuItemType[];
}

const FIND_BURGER_MENU_ITEMS = gql`
  query {
    findBurgerMenuItems {
      title
      icon
      url
      order
      subgroup
    }
  }
`;

export function useFindBurgerMenu(): QueryResult<FindBurgerMenuOutput> {
  return useQuery<FindBurgerMenuOutput>(FIND_BURGER_MENU_ITEMS);
}
