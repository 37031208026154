import { useCallback } from 'react';
import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { toast } from 'react-toastify';
import { PreChoiceInterface } from '../agenda/mock-exam';
import { Question } from '../agenda/questions';
import { Activity } from './extra-activities';
import errorHandler from '../error/errorHandler';

interface ExtraMockExam {
  _id: string;
  profile: string;
  mockExamTemplate: string;
  preChoice: PreChoiceInterface[];
  questions: Question[];
  title: string;
  subtitle: string;
  timeToDo: number;
  calendarDate: string;
  finished: boolean;
  createdAt: string;
  updatedAt: string;
  startedAt: string;
}

interface GetExtraMockExamsInput {
  id: string;
}

interface GetExtraMockExamsOutput {
  getMockExamExtraActivityDetails: ExtraMockExam;
}

const GET_EXTRA_MOCK_EXAMS = gql`
  query GetMockExamExtraActivityDetails ($id: ObjectId!) {
    getMockExamExtraActivityDetails (_id: $id) {
      _id
      mockExamTemplate
      preChoice {
        questionId
        selected
        essay
      }
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
        isEssay
        essay
      }
      calendarDate
      title
      subtitle
      timeToDo
      startedAt
      profile
      finished
      createdAt
      updatedAt
    }
  }
`;

export function useGetExtraMockExams({
  id,
}: GetExtraMockExamsInput): QueryResult<GetExtraMockExamsOutput, GetExtraMockExamsInput> {
  return useQuery<GetExtraMockExamsOutput, GetExtraMockExamsInput>(GET_EXTRA_MOCK_EXAMS, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    skip: id === '',
  });
}

interface ActivityMockExam extends Activity {
  mockExamTemplate: string;
  questions: Question[];
  title: string;
  subtitle: string;
  startsAt: string;
  finishesAt: string;
  timeToDo: number;
  answersAt: string;
  rankingAt: string;
}

interface CreateMockExamExtraActivityInput {
  template: string;
}

interface CreateMockExamExtraActivityOutput {
  createMockExamExtraActivity: ActivityMockExam;
}

const CREATE_MOCK_EXAM_ACTIVITY = gql`
  mutation CreateMockExamExtraActivity ($template: ObjectId!) {
    createMockExamExtraActivity (template: $template) {
      _id
    }
  }
`;

interface UseExtraMockExamsInterface {
  onCreateMockExamExtraActivity: (props: CreateMockExamExtraActivityInput) => Promise<void>;
}

export function useExtraMockExams(): UseExtraMockExamsInterface {
  const [createActivity] = useMutation<
    CreateMockExamExtraActivityOutput, CreateMockExamExtraActivityInput
  >(CREATE_MOCK_EXAM_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de simulado criada com sucesso!');
      }
    },
  });

  const handleCreateActivity = useCallback(async ({
    template,
  }: CreateMockExamExtraActivityInput) => {
    await createActivity({
      variables: {
        template,
      },
    });
  }, [createActivity]);

  return {
    onCreateMockExamExtraActivity: handleCreateActivity,
  };
}
