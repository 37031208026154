import { Box, Button, Center, HStack, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { faList, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrivateContext } from '../../../Private.context';
import {
  useGetOrCreateMockExam,
  useMockExam,
} from '../../../api/agenda/mock-exam';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { Loading } from '../../../lib/components/Loading';
import { PrintButton } from '../../_components/Question/PrintButton.component';
import { useGetActivityDetailsForPrint } from '../../../api/extra-activities/activities-for-print';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Questions } from '../types/Questions.component';

const AgendaMockExam: FC = () => {
  /** Context */
  const { agenda, hasAgenda } = useContext(PrivateContext);

  /** History */
  const history = useHistory();

  const { cardBorder, colors } = useDefaultStyles();

  /** Params */
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const activityId = params.get('a') || '';

  /** State */
  const [loading, setLoading] = useState(false);

  /** Memo */
  const id = useMemo(() => {
    if (agenda && agenda.mocks.length) {
      return agenda.mocks[0];
    }

    return '';
  }, [agenda]);

  /**
   * Queries & Mutations
   */
  const { onStartExam } = useMockExam();

  const query = useGetOrCreateMockExam({
    mockExamTemplate: id,
  });

  const { loading: printLoading, data: printData } = useGetActivityDetailsForPrint({
    activityId,
  });

  const activityDetails = printData?.getActivityDetailsForPrint;

  const handleTimeFormatter = useCallback((milisseconds: number) => {
    const hours = moment.duration(milisseconds).asHours();
    const minutes = moment.duration(milisseconds).minutes();
    const hoursHumanized = moment.duration(Math.floor(hours), 'hours').humanize();
    const minutesHumanized = moment.duration(minutes, 'minutes').humanize({ m: 60 });

    if (hours < 1) {
      return minutesHumanized;
    }

    if (minutes === 0) {
      return hoursHumanized;
    }

    return `${hoursHumanized} e ${minutesHumanized}`;
  }, []);

  /**
   * Callbacks
   */

  const setFeedbackModel = useCallback(() => {
    if (activityDetails) {
      const types = activityDetails.questions.map(q => ({ _id: q._id, isEssay: q.isEssay }));
      localStorage.setItem('@Aristoclass:feedbackPrintData', JSON.stringify({ types }));
      window.open(`${window.location.origin}/gabarito`, '_blank');
    }
  }, [activityDetails]);

  const handleStart = useCallback(async () => {
    if (query.data && query.data.mockExam._id) {
      setLoading(true);
      try {
        await onStartExam({
          activityId: query.data.mockExam._id,
        });
        await query.refetch();
      } catch (error) {
        if (error instanceof ApolloError) {
          console.log(error.graphQLErrors[0].message);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [onStartExam, query]);

  if (hasAgenda.examType === 'exams') {
    return <Redirect to={`/agenda/prova${window.location.search}`} />;
  }

  if (hasAgenda.examType === 'smart-exams') {
    return <Redirect to={`/agenda/simulado-inteligente${window.location.search}`} />;
  }

  if (query.loading) {
    return (
      <Center height="400px">
        <FontAwesomeIcon icon={faSpinner} spin className="color-dark-gray" size="5x" />
      </Center>
    );
  }

  if (query.data && !getActivityIndex()) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('a', query.data.mockExam._id);
    history.replace({ search: currentUrlParams.toString() });
  }

  if (!query.error && query.data && query.data.mockExam && !query.data.mockExam.startedAt) {
    return (
      <Center
        flexDirection="column"
        p={4}
        maxW={400}
        m="1rem auto"
        {...cardBorder}
      >
        <HStack>
          <Tooltip shouldWrapChildren hasArrow label="Imprimir prova">
            {
              !printLoading && activityDetails
                ? (
                  <PrintButton
                    questions={activityDetails.questions}
                    printProps={{
                      ...{
                        institution: activityDetails?.institution,
                        uf: activityDetails?.uf,
                        title: activityDetails?.title,
                        subtitle: activityDetails?.subtitle,
                        year: Number(activityDetails?.year),
                      },
                    }}
                  />
                )
                : (
                  <Loading mx={3} />
                )
            }
          </Tooltip>
          <Tooltip hasArrow label="Imprimir modelo de gabarito">
            <Button
              color={colors.secondary.goALittleDarker}
              variant="ghost"
              onClick={setFeedbackModel}
            >
              <FontAwesomeIcon icon={faList} />
            </Button>
          </Tooltip>
        </HStack>
        {
          query.data.mockExam.title && (
            <Text fontSize="lg" color={colors.secondary.goALittleDarker}>{query.data.mockExam.title}</Text>
          )
        }
        {
          query.data.mockExam.subtitle && (
            <Text fontSize="md">{query.data.mockExam.subtitle}</Text>
          )
        }
        <Box textAlign="center" my={4}>
          Ao iniciar o simulado você terá
          <Box mx={1} display="inline-block" color={colors.secondary.goALittleDarker} fontWeight="bold">
            {handleTimeFormatter(
              Math.min(query.data.mockExam.timeToDo, moment(query.data.mockExam.finishesAt).diff(moment())),
            )}
          </Box>
          para terminá-lo, após esse tempo você não poderá mais respondê-lo.
        </Box>
        <PrimaryButton
          onClick={handleStart}
          isLoading={loading}
        >
          Iniciar
        </PrimaryButton>
      </Center>
    );
  }

  if (!query.error && query.data && query.data.mockExam) {
    return (
      <Questions
        model="mocks"
        data={query.data.mockExam.questions}
        activityId={query.data.mockExam._id}
        timeToDo={query.data.mockExam.timeToDo}
        answersAt={new Date(query.data.mockExam.answersAt)}
        finishesAt={new Date(query.data.mockExam.finishesAt)}
        finished={query.data.mockExam.finished}
        ranking={query.data.mockExam.ranking}
        mockExamTemplate={query.data.mockExam.mockExamTemplate}
        rankingAt={new Date(query.data.mockExam.rankingAt)}
        startedAt={query.data.mockExam.startedAt ? new Date(query.data.mockExam.startedAt) : undefined}
        serverPreChoices={query.data.mockExam.preChoice}
      />
    );
  }

  return (
    <Box className="py-4">
      <Text tag="h5" center>
        Não há simulado disponível para esse dia
      </Text>
    </Box>
  );
};

export default AgendaMockExam;
