import React, { FC, useContext, useEffect } from 'react';
import {
  Redirect,
  Route, Switch, useHistory,
} from 'react-router-dom';
import useAccount from './hooks/useAccount';
import { PrivateStorage } from './Private.context';
import PrivateRoute from './PrivateRoute';
import EnterPage from './views/enter/Enter.page';
import NotFoundPage from './views/not-found/NotFound.page';
import ProfilePage from './views/profile/Profile.page';
import ThemeListPage from './views/theme-list/ThemeList.page';
import { AgendaStorage } from './views/agenda/Agenda.context';
import MainAgenda from './views/agenda/AgendaMain';
import AgendaExam from './views/agenda/exam/AgendaExam';
import AgendaFavorites from './views/agenda/favorites/AgendaFavorites';
import AgendaMockExam from './views/agenda/mock-exam/AgendaMockExam';
import AgendaSmartExam from './views/agenda/smart-exam/AgendaSmartExam';
import AgendaSmartReview from './views/agenda/smart-review/AgendaSmartReview';
import AgendaTheoreticalReview from './views/agenda/theoretical-review/AgendaTheoreticalReview';
import AgendaTheoreticalStudy from './views/agenda/theoretical-study/AgendaTheoreticalStudy';
import SubMenu from './views/agenda/_components/SubMenu.component';
import ExtraTheoreticalStudy from './views/agenda/extra-activities/ExtraTheoreticalStudy';
import ExtraTheoreticalReview from './views/agenda/extra-activities/ExtraTheoreticalReview';
import ExtraExams from './views/agenda/extra-activities/ExtraExams';
import ExtraFlashcards from './views/agenda/extra-activities/ExtraFlashcards';
import ExtraMockExams from './views/agenda/extra-activities/ExtraMockExams';
import ExtraQuestions from './views/agenda/extra-activities/ExtraQuestions';
import ExtraSmartExams from './views/agenda/extra-activities/ExtraSmartExams';
import PerformancePage from './views/performance/Performance.page';
import ExtraSmartReview from './views/agenda/extra-activities/ExtraSmartReview';
import ExamsHistoryPage from './views/exams-history/ExamsHistory.page';
import { CourseStorage } from './CourseInfoContext';
import { DiagnosisPageRoutes } from './views/diagnosis/DiagnosisPageRoutes';
import JJPage from './views/aristo-plus/JJ+.page';
import ChooseModal from './views/create-activity/Choose.modal';
import { MainWrapperContextProvider } from './contexts/MainWrapper.context';
import { FirstAccessModal } from './views/agenda/_components/FirstAccessModal';
import { useRegisterUserNotifications } from './api/notifications/register';
import { QuestionViewer } from './views/viewer/QuestionViewer';
import { FlashcardViewer } from './views/viewer/FlashcardViewer';
import { PastDayTag } from './views/_components/PastDayTag';
import PrintQuestions from './views/print/PrintQuestions.page';
import PrintFeedback from './views/print/PrintFeedback.page';
import { TrialEndedModal } from './views/_components/TrialEndedModal';
import { ForumStorage } from './contexts/Forum.context';
import { FlashcardsCreationMainPage } from './views/flashcards-creation/FlashcardsCreationMainPage';
import { FlashcardsCreationStorage } from './views/flashcards-creation/contexts/FlashcardsCreation.context';
import { ForumViewer } from './views/viewer/ForumViewer';
import CommentedQuestionsPage from './views/commented-questions/CommentedQuestions.page';
import { AristoCompass } from './views/aristo-compass/main';
import { StatisticsPage } from './views/aristo-compass/pages/StatisticsPage';
import { CompassPartialMockPage } from './views/aristo-compass/pages/CompassPartialMockPage';
import { DiagnosisAlertContextProvider } from './views/diagnosis/contexts/DiagnosisAlert.context';
import { CompassQuestionResultsPage } from './views/aristo-compass/pages/CompassQuestionResultsPage';
import { CompassFlashcardsPage } from './views/aristo-compass/pages/CompassFlashcardsPage';
import { useLocalStorage } from './hooks/useLocalStorage';
import { VideoViewer } from './views/viewer/VideoViewer';
import { RouteManager } from './views/agenda/_components/RouteManager';
import { WholeAppStorage } from './views/agenda/WholeApp.context';
import { GlobalContext } from './Global.context';
import { SizeLimiter } from './views/_components/SizeLimiter';
import { AristoCupMain } from './views/agenda/aristo-cup/AristoCupMain';
import { ArticlesPage } from './views/articles/ArticlesPage';

const PrivateRoutes: FC = () => {
  return (
    <WholeAppStorage>
      <PrivateStorage>
        <CourseStorage>
          <MainWrapperContextProvider>
            <ForumStorage>
              <AgendaStorage>
                <SizeLimiter>
                  <Route
                    path="/agenda"
                    render={() => (
                      <>
                        <PastDayTag />
                        <SubMenu />
                      </>
                    )}
                  />
                  <Switch>
                    <Route exact path="/agenda" component={MainAgenda} />
                    <Route path="/agenda/estudo-teorico/:lessonId?" component={AgendaTheoreticalStudy} />
                    <Route path="/agenda/revisao-teorica/:lessonId?" component={AgendaTheoreticalReview} />
                    <Route path="/agenda/revisao-inteligente" component={AgendaSmartReview} />
                    <Route path="/agenda/prova" component={AgendaExam} />
                    <Route path="/agenda/simulado" component={AgendaMockExam} />
                    <Route path="/agenda/simulado-inteligente" component={AgendaSmartExam} />
                    <Route path="/favoritos" component={AgendaFavorites} />
                    <Route exact path="/atividades-extras" component={ChooseModal} />
                    <Route path="/atividades-extras/questao" component={ExtraQuestions} />
                    <Route path="/atividades-extras/flashcard" component={ExtraFlashcards} />
                    <Route path="/atividades-extras/prova" component={ExtraExams} />
                    <Route path="/atividades-extras/simulado" component={ExtraMockExams} />
                    <Route path="/atividades-extras/estudo-teorico" component={ExtraTheoreticalStudy} />
                    <Route path="/atividades-extras/revisao-teorica" component={ExtraTheoreticalReview} />
                    <Route path="/atividades-extras/revisao-inteligente" component={ExtraSmartReview} />
                    <Route path="/atividades-extras/simulado-inteligente" component={ExtraSmartExams} />
                  </Switch>
                  <Route path="/lista-de-temas/:phase?" component={ThemeListPage} />
                  <Route path="/historico-provas/:id?" component={ExamsHistoryPage} />
                  <Route path="/gabarito" component={PrintFeedback} />
                  <Route path="/questoes-comentadas" component={CommentedQuestionsPage} />
                  <Route path="/desempenho" component={PerformancePage} />
                  <Route path="/artigos/:articleId?" component={ArticlesPage} />
                  <Route
                    path="/baralhos"
                    component={() => (
                      <FlashcardsCreationStorage>
                        <Switch>
                          <Route exact path="/baralhos" component={() => <Redirect to="/baralhos/comunidade" />} />
                          <Route path="/baralhos/meus-baralhos" component={FlashcardsCreationMainPage} />
                          <Route path="/baralhos/comunidade" component={FlashcardsCreationMainPage} />
                        </Switch>
                      </FlashcardsCreationStorage>
                    )}
                  />
                </SizeLimiter>
              </AgendaStorage>
              <Switch>
                <Route path="/perfil" component={ProfilePage} />
                <Route path="/mais-aristo" component={JJPage} />
                <Route exact path="/copa-aristo" component={AristoCupMain} />
                <Route exact path="/forum-viewer/:uid?" component={ForumViewer} />
                <Route path="/viewer/questao" component={QuestionViewer} />
                <Route path="/viewer/flashcard" component={FlashcardViewer} />
                <Route path="/viewer/video" component={VideoViewer} />
              </Switch>
              <FirstAccessModal />
            </ForumStorage>
          </MainWrapperContextProvider>
        </CourseStorage>
        <TrialEndedModal />
      </PrivateStorage>
    </WholeAppStorage>
  );
};

const Routes: FC = () => {
  const history = useHistory();
  const account = useAccount();
  const { profile } = useContext(GlobalContext);
  const hasCourses = !!profile.data?.profile.courses.length;
  const isAuthenticated = account.isAuthenticated();
  const { handleRegisterExpoUser } = useRegisterUserNotifications();
  const setCompass = useLocalStorage('@Aristoclass:isCompass')[1];

  useEffect(() => {
    const redirectPaths = [
      '/',
      '/redefinir-senha',
      '/registrar',
    ];

    if (isAuthenticated && redirectPaths.indexOf(history.location.pathname) !== -1) {
      if (account.uid) {
        const urlParams = new URLSearchParams(window.location.search);
        const expoToken = urlParams.get('expoToken');
        if (expoToken) {
          handleRegisterExpoUser(expoToken, account.uid);
        }
      }
      if (hasCourses) {
        history.replace('/route-manager');
      }
    }

    if ((!isAuthenticated)
      && !history.location.pathname.startsWith('/diagnostico')
      && !history.location.pathname.startsWith('/impressao')
      && !history.location.pathname.startsWith('/aristo-compass')) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasCourses && window.location.pathname === '/') {
      const timer = setTimeout(() => {
        history.replace('/route-manager');
      }, 50);
      return () => clearTimeout(timer);
    }
    return () => undefined;
  }, [hasCourses, history]);

  useEffect(() => {
    if (!history.location.pathname.startsWith('/aristo-compass')
      && !history.location.pathname.startsWith('/diagnostico')) {
      setCompass(false);
    }
  }, [history.location.pathname, setCompass]);

  return (
    <WholeAppStorage>
      <DiagnosisAlertContextProvider>
        <Switch>
          <Route exact path="/route-manager" component={RouteManager} />
          <Route path="/diagnostico" component={DiagnosisPageRoutes} />
          <Route exact path="/aristo-compass" component={AristoCompass} />
          <Route path="/aristo-compass/estatisticas" component={StatisticsPage} />
          <Route path="/impressao" component={PrintQuestions} />
          <Route exact path="/aristo-compass/diagnostico-parcial/questoes" component={CompassPartialMockPage} />
          <Route path="/aristo-compass/diagnostico-parcial/questoes/resultado" component={CompassQuestionResultsPage} />
          <Route exact path="/aristo-compass/diagnostico-parcial/flashcards" component={CompassFlashcardsPage} />
          <Route exact path="/" component={EnterPage} />
          <Route path="/registrar" component={EnterPage} />
          <Route path="/redefinir-senha" component={EnterPage} />
          <PrivateRoute path="/" component={PrivateRoutes} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </DiagnosisAlertContextProvider>
    </WholeAppStorage>
  );
};

export default Routes;
