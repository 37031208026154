import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import moment from 'moment';
import {
  faLockOpen, faSpinner, faLock, faCalendarCheck,
  faExclamationTriangle, faQuestionCircle, faCheckCircle, faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Flex, Text, Select, Grid, GridItem, HStack,
  Button, useDisclosure, Box,
  SimpleGrid, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Tooltip,
} from '@chakra-ui/react';
import { faCircle, faEdit } from '@fortawesome/free-regular-svg-icons';
import DifficultyIcon from '../_components/DifficultyIcon';
import { getThemeIncidenceLevel } from '../../utils/getThemeDifficulty';
import { useCustomRelevances } from '../../api/theme-list';
import FinishThemeBtn from './_components/FinishThemeBtn';
import CreateExtraBtn from './_components/CreateExtraBtn';
import ThemeListAssetCard from './_components/ThemeListAssetCard';
import useFirebaseStorage from '../../hooks/useFirebaseStorage';
import { PrivateContext } from '../../Private.context';
import { useCoursesManyInfo } from '../../api/courses';
import { useExtraTheoreticalStudy } from '../../api/extra-activities/extra-theoretical-study';
import { AgendaContext } from '../agenda/Agenda.context';
import useTableFilter from '../../hooks/useTableFilter';
import TableBoxComponent from './_components/TableBoxComponent';
import TableFilterComponent from './_components/TableFilterComponent';
import ConfirmModal from '../../lib/components/ConfirmModal';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { DefaultSearchBar } from '../_components/DefaultSearchBar.component';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const newDate = new Date();

const ThemeListPage: FC = () => {
  const queryRelevances = useCustomRelevances();
  const { phase } = useParams<{ phase: string | undefined }>();
  const storage = useFirebaseStorage();
  const { profile } = useContext(PrivateContext);
  const { onCreateTheoreticalExtraActivity } = useExtraTheoreticalStudy();
  const { refetchExtras } = useContext(AgendaContext);
  const history = useHistory();
  const [searchKey, setSearchKey] = useState('');
  const { colors } = useDefaultStyles();
  const [hidePreCourseThemes, setHidePreCourseThemes] = useLocalStorage('@Aristoclass:hidePreCourseThemes', true);

  const coursesInfoQuery = useCoursesManyInfo(profile.courses.map(c => c.course));

  const showHidePreCourseThemesButton = useMemo(() => {
    return !!queryRelevances.data?.customRelevances.filter(x => x.isPreCourse).length;
  }, [queryRelevances.data?.customRelevances]);

  const courseInfo = useMemo(() => {
    const { data } = coursesInfoQuery;
    if (!data) {
      return undefined;
    }
    return [...data.coursesManyInfo]
      .sort((a, b) => profile.courses.findIndex(
        ({ course }) => a._id === course,
      ) - profile.courses.findIndex(({ course }) => b._id === course))[0];
  }, [coursesInfoQuery, profile.courses]);

  const cr = useMemo(() => {
    const { data } = queryRelevances;
    if (data) {
      return data.customRelevances
        .filter(item => !hidePreCourseThemes || (hidePreCourseThemes && !item.isPreCourse))
        .map(item => {
          return ({
            classification: item.leaf,
            leaf: item.leaf.join(' - '),
            relevance: item.relevance,
            phase: item.phase,
            isPreCourse: item.isPreCourse,
            lock: new Date(item.enableAfter) <= newDate,
            lessonId: item.lessonId,
            enableAfter: new Date(item.enableAfter),
            theoreticalStudyFinishedAt: item.theoreticalStudyFinishedAt ? item.theoreticalStudyFinishedAt : '',
          });
        }).sort((a, b) => {
          if (a.enableAfter < b.enableAfter) {
            return -1;
          }
          return 1;
        });
    }

    return [];
  }, [hidePreCourseThemes, queryRelevances]);

  const { dataFiltered: crFiltered, setFilter } = useTableFilter(cr);

  const currentPhase = useMemo(() => {
    const currP = cr.reduce((currPhase, item) => {
      if (item.lock) {
        return String(Math.max(Number(item.phase), Number(currPhase)));
      }
      return currPhase;
    }, '0');
    return currP;
  }, [cr]);

  const planner = useMemo(() => {
    if (profile && profile.courses.length > 0 && profile.courses[0].course) {
      const courseId = profile.courses[0].course;
      return storage.getURL(`pdfs/planners/${courseId}.pdf`);
    }
    return '';
  }, [profile, storage]);

  const errata = useMemo(() => {
    if (profile && profile.courses.length > 0 && profile.courses[0].course) {
      const courseId = profile.courses[0].course;
      return storage.getURL(`pdfs/erratas/${courseId}${phase ? '_' : ''}${phase || ''}.pdf`);
    }
    return '';
  }, [phase, profile, storage]);

  const handleCreateTheoreticalExtra = useCallback(async (classification: string[]) => {
    try {
      const docId = await onCreateTheoreticalExtraActivity({
        classification,
      });
      await refetchExtras();
      history.push(`/atividades-extras/estudo-teorico/video?a=${docId}`);
    } catch (error) {
      console.log(error.message);
    }
  }, [history, onCreateTheoreticalExtraActivity, refetchExtras]);

  return (
    <Flex
      flexFlow="column"
      justify="center"
      align="center"
      w="100%"
      maxWidth={768}
      m="0 auto"
    >
      <Text
        color={colors.secondary.goALittleDarker}
        fontSize="xl"
        fontWeight="bold"
        marginY="1rem"
      >
        Lista de temas
      </Text>

      <Flex
        w="100%"
        flexFlow="column"
      >
        <HStack
          flex={1}
          justify="center"
          align="center"
          marginY=".5rem"
        >
          <Text
            color={colors.secondary.goALittleDarker}
            fontWeight="bold"
            width={180}
          >
            Selecionar fase
          </Text>
          <Select
            borderRadius={{ base: 100, md: 8 }}
            style={{ backgroundColor: 'transparent' }}
            borderColor={colors.secondary.goALittleDarker}
            value={phase}
            onChange={e => {
              if (e.target.value === '0') {
                history.push('/lista-de-temas');
              } else {
                history.push(`/lista-de-temas/${e.target.value}`);
              }
            }}
          >
            <option selected key="Geral" value="0">Todas</option>
            {courseInfo?.phases.map(({ phase: p }) => (
              <option key={p} value={p}>{`Fase 0${p}`}</option>
            ))}
          </Select>
        </HStack>

        <TableFilterComponent
          options={[
            {
              value: 'leaf', content: 'Tema',
            },
            {
              value: 'relevance', content: 'Incidência',
            },
            {
              value: 'enableAfter', content: 'Data de liberação',
            },
            {
              value: 'theoreticalStudyFinishedAt', content: 'Data de finalização',
            },
          ]}
          setFilter={setFilter}
        />
        {
          (
            !errata.includes('pdf-broken') || !planner.includes('pdf-broken'))
          && !queryRelevances.loading && !coursesInfoQuery.loading && (
            <Accordion allowToggle my={2} border="transparent" defaultIndex={[0]}>
              <AccordionItem>
                <AccordionButton px={0}>
                  <Text color={colors.secondary.goALittleDarker} fontWeight="bold" mr={2}>Documentos</Text>
                  <AccordionIcon color={colors.secondary.goALittleDarker} />
                </AccordionButton>
                <AccordionPanel px={0}>
                  <Flex justify="flex-start" width="100%">
                    <SimpleGrid width="100%" spacing={4} minChildWidth="200px">
                      {planner && !planner.includes('pdf-broken') && (
                        <ThemeListAssetCard
                          title="Planner"
                          icon={faCalendarCheck}
                          subtitle={[courseInfo?.title ?? '']}
                          url={planner}
                        />
                      )}

                      {errata && !errata.includes('pdf-broken') && (
                        <ThemeListAssetCard
                          title="Errata"
                          icon={faExclamationTriangle}
                          subtitle={[courseInfo?.title ?? '']}
                          url={errata}
                        />
                      )}
                    </SimpleGrid>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )
        }
        <Flex
          justify="right"
          marginY=".5rem"
        >
          <ViewLegend />
        </Flex>
        <HStack>
          <DefaultSearchBar
            searchKey={searchKey}
            setSearchKey={setSearchKey}
          />
          {
            showHidePreCourseThemesButton && (
              <Tooltip label={`${hidePreCourseThemes ? 'Mostrar' : 'Ocultar'} temas do pré-curso`}>
                <Button color={colors.secondary.keep} onClick={() => setHidePreCourseThemes(!hidePreCourseThemes)}>
                  <FontAwesomeIcon icon={hidePreCourseThemes ? faEye : faEyeSlash} />
                </Button>
              </Tooltip>
            )
          }
        </HStack>

        <Grid my={2}>
          <GridItem col={10} style={{ minHeight: 450 }}>
            {
              queryRelevances.loading && (
                <Flex justify="center">
                  <FontAwesomeIcon icon={faSpinner} spin size="4x" className="color-dark-gray mt-4" />
                </Flex>
              )
            }

            {
              crFiltered.length > 0 && (
                <>
                  {
                    crFiltered
                      .filter(item => item.phase === phase || !phase)
                      .filter(item => (item.leaf.toLowerCase()).includes(searchKey.toLowerCase()))
                      .map((item, i) => {
                        return (
                          <TableBoxComponent
                            key={item.lessonId}
                            lineTooltip={moment(item.enableAfter).add(3, 'h')
                              .format(`[Libera${!item.lock ? '' : 'do'} para agenda em] DD[/]MM[/]YYYY`)}
                            finished={Boolean(item.theoreticalStudyFinishedAt)}
                            boxData={[
                              {
                                key: 'enableAfter',
                                flexSpan: 1,
                                value: currentPhase >= item.phase ? (
                                  <Box
                                    color={item.theoreticalStudyFinishedAt
                                      ? colors.green.goDarker
                                      : colors.secondary.keep}
                                  >
                                    <FontAwesomeIcon
                                      icon={faLockOpen}
                                    />
                                  </Box>
                                ) : (
                                  <FontAwesomeIcon icon={faLock} color="darkgray" />
                                ),
                              },
                              {
                                key: 'leaf',
                                flexSpan: 7,
                                value: (
                                  <Flex
                                    flexFlow="column"
                                  >
                                    <Text
                                      title={item.leaf}
                                      noOfLines={2}
                                      color={item.theoreticalStudyFinishedAt ? colors.green.keep : ''}
                                    >
                                      {item.leaf}
                                    </Text>
                                    <Text
                                      fontSize="sm"
                                      color={colors.darkGray.goLighter}
                                    >
                                      {'Liberação: '}
                                      {
                                        moment(item.enableAfter).add(3, 'h')
                                          .format('DD[/]MM[/]YYYY')
                                      }
                                    </Text>
                                  </Flex>
                                ),
                                color: currentPhase >= item.phase ? 'black' : 'gray',
                                align: 'left',
                              },
                              {
                                key: 'relevance',
                                flexSpan: 1,
                                value: (
                                  <Box
                                    fontSize="lg"
                                    color={colors.secondary.keep}
                                    padding={0}
                                    onClick={() => window.open('/aristo-compass', '_blank')}
                                    as="button"
                                  >
                                    <DifficultyIcon difficulty={getThemeIncidenceLevel(item.relevance)} />
                                  </Box>
                                ),
                              },
                              {
                                key: 'ir_para',
                                flexSpan: 1,
                                value: <CreateExtraBtn
                                  handleCreateExtraFn={() => handleCreateTheoreticalExtra(item.classification)}
                                  active={currentPhase >= item.phase}
                                  confirmModalTitle={`Deseja adicionar esse
                                    assunto como atividade extra?`}
                                  confirmModalSubtitle={`O assunto ${item.leaf} será adicionado como
                                    atividade extra na sua agenda de hoje.`}
                                />,
                              },
                              {
                                key: 'theoreticalStudyFinishedAt',
                                flexSpan: 1,
                                cellTooltip: `Finalizado em ${moment(item.theoreticalStudyFinishedAt)
                                  .format('DD[/]MM[/]YYYY')}`,
                                value: <FinishThemeBtn
                                  onFinish={async () => {
                                    queryRelevances.refetch();
                                  }}
                                  asIcon
                                  theoreticalFinishedAt={item.theoreticalStudyFinishedAt}
                                  lock={currentPhase >= item.phase}
                                  lessonId={item.lessonId}
                                  lessonTitle={item.leaf}
                                />,
                              },
                            ]}
                          />
                        );
                      })
                  }
                </>
              )
            }
            {
              cr.length === 0 && !queryRelevances.loading && (
                <Text tag="h3" center className="mt-6">Oops, dados não encontrado!</Text>
              )
            }
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

function ViewLegend() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { colors } = useDefaultStyles();

  return (
    <>
      <Button
        color={colors.secondary.goALittleDarker}
        fontSize="sm"
        rightIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
        variant="link"
        onClick={onOpen}
      >
        Ver legenda
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        onDecline={onClose}
        title="Legenda"
        icon={<FontAwesomeIcon icon={faQuestionCircle} />}
        declineText="Fechar"
      >
        <Flex marginTop="1rem">
          <Box
            align="center"
            flex={1}
            color={colors.secondary.goALittleDarker}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Box>
          <Text
            flex={7}
          >
            Adicionar atividade extra
          </Text>
        </Flex>

        <Flex>
          <Box
            align="center"
            flex={1}
            color={colors.secondary.goALittleDarker}
          >
            <FontAwesomeIcon
              icon={faLock}
            />
          </Box>
          <Text
            flex={7}
          >
            Tema bloqueado
          </Text>
        </Flex>

        <Flex>
          <Box
            align="center"
            flex={1}
            color={colors.secondary.goALittleDarker}
          >
            <FontAwesomeIcon
              icon={faLockOpen}
            />
          </Box>
          <Text
            flex={7}
          >
            Tema liberado
          </Text>
        </Flex>

        <Flex>
          <Box
            align="center"
            flex={1}
          >
            <DifficultyIcon
              difficulty="easy"
            />
          </Box>
          <Text
            flex={7}
          >
            Incidência média
          </Text>
        </Flex>

        <Flex>
          <Box
            align="center"
            flex={1}
          >
            <DifficultyIcon
              difficulty="medium"
            />
          </Box>
          <Text
            flex={7}
          >
            Incidência alta
          </Text>
        </Flex>

        <Flex>
          <Box
            align="center"
            flex={1}
          >
            <DifficultyIcon
              difficulty="hard"
            />
          </Box>
          <Text
            flex={7}
          >
            Incidência muito alta
          </Text>
        </Flex>

        <Flex>
          <Box
            align="center"
            flex={1}
            color={colors.green.goLighter}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
            />
          </Box>
          <Text
            flex={7}
          >
            Tema concluído
          </Text>
        </Flex>

        <Flex>
          <Box
            align="center"
            flex={1}
            color={colors.secondary.goALittleDarker}
          >
            <FontAwesomeIcon
              icon={faCircle}
            />
          </Box>
          <Text
            flex={7}
          >
            Concluir tema
          </Text>
        </Flex>

      </ConfirmModal>
    </>
  );
}

export default ThemeListPage;
