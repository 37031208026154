import React, { FC, useMemo } from 'react';
import FavoritesEmpty from '../types/FavoritesEmpty';
import AgendaMindMaps from '../types/AgendaMindMaps';
import { Mindmap } from '../../../api/agenda/mindmaps';
import { useMindmapManyFavorite } from '../../../api/favorites';
import { SkeletonLoading } from '../../_components/SkeletonLoading';

const FavoriteMindmaps: FC = () => {
  const { data, loading } = useMindmapManyFavorite();

  const mindmaps = useMemo(() => {
    return data?.mindmapManyFavorite || [] as Mindmap[];
  }, [data?.mindmapManyFavorite]);

  if (loading) {
    return (
      <SkeletonLoading />
    );
  }

  if (mindmaps.length > 0) {
    return (
      <AgendaMindMaps data={mindmaps} />
    );
  }

  return (
    <FavoritesEmpty />
  );
};

export default FavoriteMindmaps;
