import { Flex } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InstitutionTarget } from '../../../api/profile';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useInstitutionsDiagnostic } from '../../../hooks/useInstitutionsDiagnostic';
import { InstitutionSelected } from '../../diagnosis/_components/InstitutionSelected';
import { MainBoxTemplate } from '../../diagnosis/_components/MainBoxTemplate';
import { InstitutionSelector } from '../../_components/InstitutionSelector';

type InstitutionSelectionPageType = {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const InstitutionSelectionPage: FC<InstitutionSelectionPageType> = ({
  setCurrentPage,
}) => {
  const [selectedInstitutions, setSelectedInstitutions] = useState<InstitutionTarget[]>([]);
  const { colors } = useDefaultStyles();
  const isRevalidaSelected = selectedInstitutions[0]?.institution.toLowerCase().includes('revalida');

  const {
    uf,
    handleUf,
    setExamVariant,
    ufs,
    institutions,
    loading,
  } = useInstitutionsDiagnostic();

  useEffect(() => {
    if (isRevalidaSelected) {
      setExamVariant('REVALIDA');
    } else if (selectedInstitutions.length >= 1) {
      setExamVariant('R1');
    } else {
      setExamVariant(undefined);
    }
  }, [isRevalidaSelected, selectedInstitutions.length, setExamVariant]);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('@plataformaJJMentoria:institutions') !== null) {
      const inst = localStorage.getItem('@plataformaJJMentoria:institutions') as string;
      setSelectedInstitutions(JSON.parse(inst));
    }
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem('@plataformaJJMentoria:id')
  //     && !JSON.parse(localStorage.getItem('@plataformaJJMentoria:finished') ?? 'false')) {
  //     history.push('/diagnostico/prova?compass=true');
  //   }
  // }, [history]);

  const removeInstitution = useCallback((name: string) => {
    setSelectedInstitutions(prev => {
      const newArray = prev.filter(inst => inst.institution !== name);
      localStorage.setItem('@plataformaJJMentoria:institutions', JSON.stringify(newArray));
      return newArray;
    });
  }, []);

  const handleAddInstitution = useCallback((value: InstitutionTarget) => {
    setSelectedInstitutions(prev => {
      if (value.institution
        && value.uf
        && !prev.map(x => x.institution).includes(value.institution)) {
        const newArray = [...prev, value];
        localStorage.setItem('@plataformaJJMentoria:institutions', JSON.stringify(newArray));
        return newArray;
      } return prev;
    });
  }, []);

  return (
    <MainBoxTemplate
      descriptionTitle=""
      mainTitle="Qual o seu objetivo?"
      details={['Conta pra gente! Em quais instituições você gostaria de passar?',
        'Selecione a instituição e depois clique em "Adicionar Instituição".',
        'Repita o processo para adicionar todas as suas instituições prioritárias.',
      ]}
      btn1Params={{ onClick: () => setCurrentPage(0), text: 'Voltar', color: colors.primary.keep }}
      btn2Params={{
        onClick: () => history.push('/aristo-compass/estatisticas'),
        text: 'Confirmar',
        color: colors.primary.keep,
        disabled: selectedInstitutions.length === 0,
      }}
      hasMargin
      color={colors.primary.keep}
      bgColor="white"
      my="5rem"
    >
      <InstitutionSelector
        variant="add-button"
        bgColor={colors.white.keep}
        ufs={ufs}
        uf={uf}
        institutions={institutions}
        onSelectUf={handleUf}
        onSelectInstitution={handleAddInstitution}
        loadingInstitutions={loading}
        borderColor={colors.primary.keep}
      />
      <Flex
        width="100%"
        flexFlow="column"
        justify="center"
        paddingY="2rem"
      >
        {selectedInstitutions.map((institutionName, index) => (
          <InstitutionSelected
            borderColor={colors.primary.keep}
            index={index}
            key={institutionName.institution}
            name={institutionName.institution}
            onDelete={() => removeInstitution(institutionName.institution)}
          />
        ))}
      </Flex>
    </MainBoxTemplate>
  );
};
