import { Button, HStack, Tooltip } from '@chakra-ui/react';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { PreChoiceInterface } from '../../../api/agenda/mock-exam';
import { Question } from '../../../api/agenda/questions';
import { useGetActivityDetailsForPrint } from '../../../api/extra-activities/activities-for-print';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { QuestionCardType } from '../../../hooks/useQuestionHooks';
import { Loading } from '../../../lib/components/Loading';
import { ExamFeedbackButton } from '../../print/_components/ExamFeedback.component';
import { PrintButton } from '../../_components/Question/PrintButton.component';
import { PrintProps } from './QuestionIndexNavigator';

type PrintMenuProps = {
  data: Question[];
  device?: 'mobile' | 'web' | 'both';
  clientPreChoices?: PreChoiceInterface[];
  setClientPreChoices?: Dispatch<SetStateAction<PreChoiceInterface[]>>;
  hideExamFeedbackButton?: boolean;
  hidePrintButton?: boolean;
  printProps?: PrintProps;
  toggleFullscreen?: () => void;
  model: QuestionCardType;
  isFullscreen: boolean;
  loadingMenu: boolean;
};

export const PrintMenu: FC<PrintMenuProps> = ({
  clientPreChoices,
  setClientPreChoices,
  data,
  hideExamFeedbackButton = false,
  hidePrintButton,
  printProps,
  device = 'both',
  toggleFullscreen,
  model,
  isFullscreen,
  loadingMenu,
}) => {
  const currentDevice = useDevice();
  const { search } = window.location;
  // const { isFullscreen } = useContext(WholeAppContext);
  const params = new URLSearchParams(search);
  const activityId = String(params.get('a') || '');
  const { colors } = useDefaultStyles();
  const query = useGetActivityDetailsForPrint({
    activityId,
  });

  const activityDetails = useMemo(() => {
    const { data: activityData, loading } = query;
    if (!activityData || loading) {
      return undefined;
    }
    return activityData.getActivityDetailsForPrint;
  }, [query]);

  const activityName = useMemo(() => {
    const location = window.location.href;
    if (location.indexOf('revisao-inteligente') !== -1) {
      return 'Revisão inteligente';
    }

    if (location.indexOf('prova') !== -1) {
      return 'Prova na íntegra';
    }

    if (location.indexOf('simulado-inteligente') !== -1) {
      return 'Simulado inteligente';
    }

    if (location.indexOf('revisao-teorica') !== -1) {
      return 'Revisão teórica';
    }

    if (location.indexOf('simulado') !== -1) {
      return 'Simulado Aristo';
    }

    if (location.indexOf('estudo-teorico') !== -1) {
      return 'Estudo teórico';
    }

    return 'Questões avulsas';
  }, []);

  if (device !== 'both' && device !== currentDevice) {
    return <></>;
  }

  if (loadingMenu) {
    return <Loading mx={3} />;
  }

  return (
    <HStack spacing={2}>
      {
        toggleFullscreen && (
          <Tooltip label={isFullscreen ? 'Sair do modo foco' : 'Modo foco'}>
            <Button
              variant="ghost"
              onClick={() => toggleFullscreen()}
              color={colors.secondary.keep}
            >
              <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
            </Button>
          </Tooltip>
        )
      }
      {clientPreChoices
        && setClientPreChoices
        && !isFullscreen
        && model !== 'diagnostic'
        && model !== 'favorites'
        && (
          <ExamFeedbackButton
            hide={hideExamFeedbackButton}
            data={data}
            clientPreChoices={clientPreChoices}
            setClientPreChoices={setClientPreChoices}
          />
        )}
      {
        !isFullscreen && !hidePrintButton && (
          <PrintButton
            questions={data}
            printProps={{
              ...(activityDetails && {
                institution: activityDetails?.institution,
                uf: activityDetails?.uf,
                title: activityDetails?.title,
                subtitle: activityDetails?.subtitle,
                year: Number(activityDetails?.year),
              }),
              name: activityName,
              ...printProps,
            }}
          />
        )
      }
    </HStack>

  );
};
